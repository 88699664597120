import React from 'react'
import Box from '@mui/material/Box'
import Fade from '@mui/material/Fade'
import CircularProgress from '@mui/material/CircularProgress'
import { useTranslation } from 'react-i18next'

interface PageLoadingProps {
  show?: boolean
}

function PageLoading({ show }: PageLoadingProps) {
  const { t } = useTranslation()
  return (
    <Fade in={show} unmountOnExit>
      <Box className="page-loading">
        <CircularProgress />
        <p>{t('common.loading')}</p>
      </Box>
    </Fade>
  )
}

PageLoading.defaultProps = {
  show: true,
}

export default PageLoading
