import { all, fork } from 'redux-saga/effects'
import productSaga from '@/redux/sagas/product'
import informationSaga from '@/redux/sagas/information'
import jobManagementSaga from '@/redux/sagas/jobManagement'
import authSaga from '@/redux/sagas/auth'
import contactSaga from '@/redux/sagas/contact'

export default function* rootSaga() {
  yield all([
    fork(productSaga),
    fork(authSaga),
    fork(informationSaga),
    fork(jobManagementSaga),
    fork(contactSaga),
  ])
}
