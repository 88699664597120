import axios from 'axios'
import { setCookie, getCookie } from '@/utils/cookies'
import {
  AUTH_REMEMBER_EXPIRES,
  AUTH_USER_TOKEN,
  DATE_STRING_FOR_SCHEDULE,
  FORMAT_PRICE,
  TAB_ID_TOKEN,
  TIME_EXPIRED,
} from '@/constants/common'
import { AppMessage, FormType } from '@/interfaces'
import i18n from 'i18next'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'

dayjs.extend(utc)

dayjs.extend(timezone)

export const setUserToken = (token: string, isRemember: boolean = false) => {
  const expires = isRemember
    ? dayjs().add(AUTH_REMEMBER_EXPIRES, 'day').toString()
    : ''
  setCookie(AUTH_USER_TOKEN, token, expires)
}

export const getUserToken = () => getCookie(AUTH_USER_TOKEN)

export const removeUserToken = () => {
  setCookie(AUTH_USER_TOKEN, '', dayjs().subtract(1).toString())
}

export const handleMessageError = (err: any) => {
  let errMessage = ''
  if (axios.isAxiosError(err)) {
    if (err.response?.data.errors && err.response?.data.errors.length > 0) {
      errMessage = err.response?.data.errors[0].message
    } else {
      errMessage = err.response?.data.message
        ? err.response?.data.message
        : i18n.t('error.http_error')
    }
  }

  if (errMessage.substring(0, 2) === 'E_') {
    return i18n.t(`error.${errMessage}`)
  }

  return errMessage
}

export const handleError = (error: any): AppMessage => {
  const errorType =
    axios.isAxiosError(error) && error.response?.status === 401 ? '' : 'error'

  return {
    type: errorType,
    content: handleMessageError(error),
    code: error.response?.status,
  }
}

export const handleBoxSettingError = (
  err: any
): {
  notice: AppMessage
  unAvailableTime: number[]
} => {
  const errType =
    axios.isAxiosError(err) && err.response?.status === 401 ? '' : 'error'
  let errMessage = ''
  if (axios.isAxiosError(err)) {
    errMessage = err.response?.data.message
      ? err.response?.data.message
      : i18n.t('error.http_error')
  }
  return {
    notice: {
      type: errType,
      content: errMessage,
    },
    unAvailableTime: err.response?.data.errors || [],
  }
}

export const getValueMasterData = (array: Array<any>) => {
  const initialValue = {}
  return array.reduce(
    (obj: any, item: any) => ({
      ...obj,
      [Number(item.value)]: item,
    }),
    initialValue
  )
}

export const generateKey = (prefix: string, index: number) =>
  `${prefix}_${index}`

export const renderLabelFormType = (
  label: string,
  formType: FormType
): string => `${label} ${formType !== 'view' ? '' : ''}`

export const renderLabel = (label: string): string => `${label}`

export const showTotalPaginationTable = (
  perPage: number,
  currentPage: number,
  arrayLength: number,
  total: number
): string =>
  `${perPage * (currentPage - 1) + 1}-${
    perPage * (currentPage - 1) + arrayLength
  } / ${total}`

export const getDateTimeForSchedule = (date: string, time: string) => {
  const dateString = dayjs(date).format(DATE_STRING_FOR_SCHEDULE)
  return `${dateString}T${time}`
}

export const formatPrice = (price: any) => price.replace(FORMAT_PRICE, ',')

export const getEnv = (key: string) => process.env[`REACT_APP_${key}`] ?? ''

export const convertSeconds = (seconds: number) => {
  const convert = (x: number) => (x < 10 ? `0${x}` : x)
  return `${convert(parseInt((seconds / (60 * 60)).toString(), 10))}:${convert(
    parseInt(((seconds / 60) % 60).toString(), 10)
  )}:${convert(seconds % 60)}`
}

export const setTabId = () => {
  const timeStamp = new Date().getTime()
  sessionStorage.setItem(TAB_ID_TOKEN, String(timeStamp))
}

export const getTabId = () => sessionStorage.getItem(TAB_ID_TOKEN)

export const isShowError = (time: number) => {
  const timeExpired = new Date().getTime() - time
  return timeExpired > TIME_EXPIRED
}

export const convertTimeToJapan = (dateTime: string | null) => {
  if (dateTime === null || dateTime === '') {
    return dayjs()
  }
  if (dateTime.charAt(dateTime.length - 1).toLowerCase() === 'z') {
    return dayjs(dateTime).tz('Asia/Tokyo')
  }
  if (dateTime.includes('+')) {
    return dayjs(dateTime).tz('Asia/Tokyo')
  }
  return dayjs(dateTime)
}

export const removeHTMLTag = (content: string) =>
  content.replace(/(<([^>]+)>)/gi, '')

export const getCurrentCameraIP = (camera: string) =>
  camera.substring(camera.indexOf('@') + 1, camera.lastIndexOf(':')) || ''
