import '@/assets/styles/layout/sidebar.scss'
import { useLocation, useNavigate } from 'react-router-dom'
import * as React from 'react'
import {
  List,
  Stack,
  Typography,
  ListItemText,
  ListItemIcon,
  ListItemButton,
  Collapse,
} from '@mui/material'
import {
  AccountBox,
  House,
  Settings,
  CalendarToday,
  Grading,
  ExpandLess,
  ExpandMore,
} from '@mui/icons-material'
import { useAppDispatch, useAppSelector } from '@/redux/hooks'
import { useTranslation } from 'react-i18next'
import { TOGGLE_SIDEBAR } from '@/redux/reducers/app.slice'
import { ROUTER_PATH } from '@/constants'

interface Props {
  width: number
}

function Sidebar(props: Props) {
  const { t } = useTranslation()
  const SIDEBAR_ITEMS = {
    FITNESS: [
      {
        id: 'm1',
        title: t('layout.sidebar.dashboard'),
        path: ROUTER_PATH.DASHBOARD,
        icon: <House />,
        subItems: [],
      },
      {
        id: 'm2',
        title: t('layout.sidebar.information_management'),
        path: ROUTER_PATH.INFORMATION,
        icon: <AccountBox />,
        subItems: [],
      },
      {
        id: 'm3',
        title: t('layout.sidebar.job_management'),
        path: ROUTER_PATH.JOB_MANAGEMENT_BOOKING_LIST,
        icon: <CalendarToday />,
        subItems: [
          {
            id: 'm3-1',
            parentId: 'm3',
            title: t('layout.sidebar.booking_list'),
            path: ROUTER_PATH.JOB_MANAGEMENT_BOOKING_LIST,
            icon: '',
          },
          {
            id: 'm3-2',
            parentId: 'm3',
            title: t('layout.sidebar.service_list'),
            path: ROUTER_PATH.JOB_MANAGEMENT_SERVICE_LIST,
            icon: '',
          },
          {
            id: 'm3-3',
            parentId: 'm3',
            title: t('layout.sidebar.friend_request_list'),
            path: ROUTER_PATH.JOB_MANAGEMENT_FRIEND_REQUEST_LIST,
            icon: '',
          },
          {
            id: 'm3-4',
            parentId: 'm3',
            title: t('layout.sidebar.service_box_create'),
            path: ROUTER_PATH.JOB_MANAGEMENT_BOX_SERVICE_CREATE,
            icon: '',
          },
        ],
      },
      {
        id: 'm4',
        title: t('layout.sidebar.contact_management'),
        path: ROUTER_PATH.CONTACT,
        icon: <Grading />,
        subItems: [],
      },
      {
        id: 'm6',
        title: t('layout.sidebar.setting'),
        path: ROUTER_PATH.INFORMATION_EDIT,
        icon: <Settings />,
        subItems: [],
      },
    ],
  }
  const { width } = props
  // const matchUpMd: boolean = useMediaQuery(theme.breakpoints.up('md'));
  // console.log('matchUpMd', matchUpMd)
  const location = useLocation()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const toggleSidebar = useAppSelector(state => state.app.toggleSidebar)
  const [open, setOpen] = React.useState(false)

  const handleMenuClick = (item: any) => {
    navigate(item.path)
    if (!toggleSidebar) dispatch(TOGGLE_SIDEBAR())
  }

  const parentMenuClick = () => {
    setOpen(!open)
  }

  const renderSidebarItems = React.useMemo(
    () =>
      SIDEBAR_ITEMS.FITNESS.map(item =>
        item.subItems.length > 0 ? (
          <>
            <ListItemButton
              className="list-item"
              selected={location.pathname.includes(item.path.split('/')[1])}
              key={item.id}
              onClick={parentMenuClick}
            >
              <ListItemIcon className="primary">{item.icon}</ListItemIcon>
              <ListItemText
                primary={
                  <Typography variant="body1" sx={{ marginLeft: -2 }}>
                    {item.title}
                  </Typography>
                }
              />
              {open ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {item.subItems.map(subItem => (
                  <ListItemButton
                    className="list-item-sub-menu"
                    selected={location.pathname.includes(
                      subItem.path.split('/')[2]
                    )}
                    key={subItem.id}
                    onClick={() => handleMenuClick(subItem)}
                  >
                    <ListItemIcon className="primary">
                      {subItem.icon}
                    </ListItemIcon>
                    <ListItemText
                      primary={
                        <Typography sx={{ marginLeft: -2 }}>
                          {subItem.title}
                        </Typography>
                      }
                    />
                  </ListItemButton>
                ))}
              </List>
            </Collapse>
          </>
        ) : (
          <ListItemButton
            className="list-item"
            selected={location.pathname.includes(item.path.split('/')[1])}
            key={item.id}
            onClick={() => handleMenuClick(item)}
          >
            <ListItemIcon className="primary">{item.icon}</ListItemIcon>
            <ListItemText
              primary={
                <Typography variant="body1" sx={{ marginLeft: -2 }}>
                  {item.title}
                </Typography>
              }
            />
          </ListItemButton>
        )
      ),
    [SIDEBAR_ITEMS, toggleSidebar, location]
  )

  return (
    <Stack direction="row" className="sidebar-container">
      <Stack
        direction="column"
        className="left-wrapper"
        sx={{
          display: toggleSidebar ? 'block' : 'none',
          width: toggleSidebar ? width : 0,
          boxShadow: 2,
        }}
      >
        <List
          component="nav"
          className="sidebar-list"
          sx={{
            bgcolor: 'background.paper',
          }}
          aria-labelledby="nested-list-subheader"
        >
          {renderSidebarItems}
        </List>
      </Stack>
    </Stack>
  )
}

export default React.memo(Sidebar)
