import React from 'react'
import LandingLayout from '@/layout/LandingLayout'
import { RouteObject } from 'react-router-dom'
import Landing from '@/pages/Landing'
import PublicRoute from '@/components/common/PublicRoute'

const PublicRouter: RouteObject = {
  path: '/',
  element: (
    <PublicRoute>
      <LandingLayout />
    </PublicRoute>
  ),
  children: [
    {
      index: true,
      element: <Landing />,
    },
    {
      path: 'reset-password',
      element: <Landing />,
    },
    {
      path: 'verify-email',
      element: <Landing />,
    },
  ],
}

export default PublicRouter
