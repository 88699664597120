import '@/assets/styles/pages/mentorDetails.scss'
import Schedule from '@/components/common/Schedule'
import { MENTOR_STATUS_COLOR, ROUTER_PATH } from '@/constants'
import { DEFAULT_LANGUAGE } from '@/constants/common'
import useMasterData from '@/hooks/useMasterData'
import { TabPanelProps } from '@/interfaces'
import { useAppDispatch, useAppSelector } from '@/redux/hooks'
import {
  CLEAR_LIST_SERVICE,
  FETCH_DATA,
} from '@/redux/reducers/information.slice'
import {
  convertTimeToJapan,
  getDateTimeForSchedule,
  getValueMasterData,
} from '@/utils'
import SelectTimeUnit from '@components/common/SelectTimeUnit'
import {
  Avatar,
  Box,
  Button,
  Card,
  Grid,
  Paper,
  Stack,
  Tab,
  Tabs,
  TextField,
  Typography,
} from '@mui/material'
import React, { useState, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import useWindowDimensions from '../../hooks/useWindowDimensions'

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </Box>
  )
}

const valueTab = 0

function Index() {
  const { t } = useTranslation()
  const [listDateView, setListDateView] = useState([
    {
      title: 'information.filter_day',
      value: 'day',
      selected: true,
    },
    {
      title: 'information.filter_week',
      value: 'week',
      selected: false,
    },
    {
      title: 'information.filter_month',
      value: 'month',
      selected: false,
    },
  ])

  const dispatch = useAppDispatch()

  const { listService, mentor } = useAppSelector(state => state.information)

  const { languageMaster } = useAppSelector(state => state.app)

  const [typeTime, setTypeTime] = React.useState<string>('day')

  const [languageCalendar, setLanguageCalendar] =
    React.useState<string>('ja-JP')

  const [listCalendars, setListCalendars] = React.useState([])

  const navigate = useNavigate()

  // change listDateView
  React.useEffect(() => {
    setListDateView(prev => {
      prev.map(item => {
        if (item.value === typeTime) {
          item.selected = true
        }
        return item
      })
      return [...prev]
    })
  }, [typeTime])

  const handleChange = (newValue: string) => {
    setTypeTime(newValue)
  }

  const typeProfile: Array<any> = useMasterData('freelance_profile_type')

  const accountStatus: Array<any> = useMasterData('freelance_account_status')

  React.useEffect(() => {
    dispatch(FETCH_DATA())
    // CR #228186
    return () => {
      dispatch(CLEAR_LIST_SERVICE())
    }
  }, [])

  React.useEffect(() => {
    if (listService.length > 0)
      setListCalendars(
        listService.flatMap((service: any) =>
          service.service_box.map((calendar: any) => ({
            id: calendar.id,
            startDate: getDateTimeForSchedule(
              calendar.date_time,
              calendar.start_time
            ),
            endDate: getDateTimeForSchedule(
              calendar.date_time,
              calendar.end_time
            ),
            title: `${service.name} ( ${t('information.participants')}: ${
              calendar.total_reservations
            } ${t('information.people')} ) - ${calendar.admin.nickname}`,
            count: calendar.total_reservations
          }))
        )
      )
  }, [listService])

  React.useEffect(() => {
    if (languageMaster === DEFAULT_LANGUAGE) setLanguageCalendar('ja-JP')
    else setLanguageCalendar('en-US')
  }, [languageMaster])

  const checkStatusMentor = (status: number): string => {
    const statusCheck = getValueMasterData(accountStatus)
    if (languageMaster === DEFAULT_LANGUAGE)
      return statusCheck[status]?.title_jp
    return statusCheck[status]?.title_en
  }

  const checkColorStatus = (status: number) => {
    const statusCheck = getValueMasterData(accountStatus)
    const statusMentor = Number(statusCheck[status]?.value)
    switch (statusMentor) {
      case 0:
        return { style: { color: MENTOR_STATUS_COLOR.pending } }
      case 1:
        return { style: { color: MENTOR_STATUS_COLOR.active } }
      case 2:
        return { style: { color: MENTOR_STATUS_COLOR.reject } }
      default:
        return {}
    }
  }

  const checkTypeMentor = () => {
    const typeText = getValueMasterData(typeProfile)
    if (languageMaster === DEFAULT_LANGUAGE)
      return typeText[mentor.type]?.title_jp
    return typeText[mentor.type]?.title_en
  }

  function calendarHeight() {
    const { width, height } = useWindowDimensions()

    if (width < 1200) {
      return ''
    }
    if (height === 969) {
      return 770
    }
    return 820
  }

  const ref = useRef<any>(null)
  const getWidth = () => {
    const { width } = useWindowDimensions()
    let infoW = 500
    if (width > 1200) {
      if (ref.current) {
        infoW = ref.current.offsetWidth
      }
      return `${width - 360 - infoW}px !important`
    }
    return `${width - 270}px !important`
  }

  return (
    <Box className="mentor-detail-container" sx={{ overflowY: 'hidden' }}>
      <Grid container direction="column" spacing={5}>
        <Grid lg={2} item sx={{ marginLeft: 2 }}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Stack direction="row" alignItems="center" spacing={2}>
              <Avatar
                sx={{ width: 80, height: 80 }}
                alt="mentor-image"
                src={mentor.avatar ? mentor.avatar : ''}
              />
              <Stack direction="column">
                <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                  {mentor.username}
                </Typography>
                <Typography sx={{ fontWeight: 'bold' }} fontSize={12}>
                  {checkTypeMentor()}
                </Typography>
              </Stack>
            </Stack>
          </Stack>
        </Grid>
        <Grid
          lg={10}
          sx={{
            maxHeight: calendarHeight(),
          }}
          item
        >
          <Grid
            container
            direction={{ lg: 'row', md: 'column', sm: 'column', xs: 'column' }}
            spacing={3}
            sx={{ pl: 1 }}
          >
            <Grid item xs={3.5}>
              <Paper className="mentors-info" sx={{ padding: 2 }} ref={ref}>
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                    {t('information.title')}
                  </Typography>
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    onClick={() => navigate(ROUTER_PATH.INFORMATION_EDIT)}
                  >
                    {t('information.edit')}
                  </Button>
                </Stack>
                <Box mt={2}>
                  {mentor.id && (
                    <Stack direction="column" spacing={3} padding={1.5}>
                      <TextField
                        label={t('information.field.username')}
                        value={mentor.username ? mentor.username : ''}
                        disabled
                        InputLabelProps={{ shrink: true }}
                      />
                      <TextField
                        label={t('information.field.mentor_id')}
                        value={mentor.id ? mentor.id : ''}
                        disabled
                        InputLabelProps={{ shrink: true }}
                      />
                      <TextField
                        label={t('information.field.birthday')}
                        value={
                          mentor.birthday
                            ? convertTimeToJapan(mentor.birthday).format(
                                'YYYY/MM/DD'
                              )
                            : ''
                        }
                        disabled
                        InputLabelProps={{ shrink: true }}
                      />
                      <TextField
                        label={t('information.field.join_date')}
                        value={
                          mentor.created_at
                            ? convertTimeToJapan(mentor.created_at).format(
                                'YYYY/MM/DD'
                              )
                            : ''
                        }
                        disabled
                        InputLabelProps={{ shrink: true }}
                      />
                      <TextField
                        label={t('information.field.email_address')}
                        value={mentor.email ? mentor.email : ''}
                        disabled
                        InputLabelProps={{ shrink: true }}
                      />
                      <TextField
                        label={t('information.field.phone_number')}
                        value={mentor.phone ? mentor.phone : ''}
                        disabled
                        InputLabelProps={{ shrink: true }}
                      />
                      <TextField
                        label={t('information.field.current_status')}
                        value={checkStatusMentor(mentor.status) || ''}
                        disabled={
                          Number(
                            getValueMasterData(accountStatus)[mentor.status]
                              ?.value
                          ) === 3
                        }
                        InputLabelProps={{ shrink: true }}
                        inputProps={checkColorStatus(mentor.status)}
                        InputProps={{
                          readOnly: true,
                        }}
                      />
                    </Stack>
                  )}
                </Box>
              </Paper>
            </Grid>
            <Grid item xs={8.5}>
              <Card className="mentors-calendar" sx={{ width: getWidth() }}>
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  spacing={2}
                  sx={{ height: 50 }}
                >
                  <Tabs value={valueTab} aria-label="basic tabs example">
                    <Tab label={t('information.personal_calendar')} />
                  </Tabs>
                  <SelectTimeUnit
                    onChange={(newValue: string) => handleChange(newValue)}
                    items={listDateView}
                  />
                </Stack>
                <Box className="mentors-list">
                  <TabPanel value={valueTab} index={0}>
                    <Schedule
                      dataProps={listCalendars}
                      languageCalendar={languageCalendar}
                      changeTypeTime={setTypeTime}
                      typeTime={typeTime}
                    />
                  </TabPanel>
                </Box>
              </Card>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  )
}

export default React.memo(Index)
