import client from '@/api/axiosClient'
import { HMSTokenParams } from '../interfaces'

export const apiGetAppToken = (data: HMSTokenParams) =>
  client.post(`hms/token`, {
    room_id: data.room_id,
    type: 1,
    passcode: data.passcode,
    mirror_code: data.mirror_code,
  })
