import * as React from 'react'
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Box,
  Stack,
} from '@mui/material'
import { Close } from '@mui/icons-material'
import { useTranslation } from 'react-i18next'

interface Props {
  onSubmit: () => void
  onCancel: () => void
  open: boolean
  content: string
  avatar: string
}

function DialogFriendRequest({
  open,
  content,
  onSubmit,
  onCancel,
  avatar,
}: Props) {
  const { t } = useTranslation()

  return (
    <Dialog
      onClose={onCancel}
      aria-labelledby="customized-dialog-title"
      open={open}
      className="dialog-friend-request"
    >
      <DialogTitle sx={{ mb: 2, p: 2 }}>
        <IconButton
          aria-label="close"
          onClick={onCancel}
          className="btn-close"
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
          }}
        >
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Stack
          direction="column"
          justifyContent="center"
          alignItems="center"
          spacing={4}
        >
          <Box
            component="img"
            alt="avatar"
            src={avatar}
            sx={{ width: 80, height: 80, borderRadius: '50%' }}
          />
          <Box sx={{ m: 4 }}>{content}</Box>
        </Stack>
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'center', pb: 5 }}>
        <Stack direction="row" spacing={8}>
          <Button className="btn-cancel" size="small" onClick={onCancel}>
            {t('job_management.table_friend_request.button.cancel')}
          </Button>
          <Button
            className="btn-ok"
            color="primary"
            size="small"
            onClick={onSubmit}
          >
            {t('job_management.table_friend_request.button.ok')}
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  )
}
export default React.memo(DialogFriendRequest)
