import '@/assets/styles/pages/edit-profile.scss'
import React, { useState, useEffect } from 'react'
import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm, Controller, useFieldArray } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import {
  Box,
  Grid,
  Stack,
  TextField,
  Button,
  Typography,
  Divider,
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { DatePicker } from '@mui/lab'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import UploadImage from '@/components/common/UploadImage'
import { useAppSelector, useAppDispatch } from '@/redux/hooks'
import { FETCH_MENTOR_PROFILE } from '@/redux/reducers/information.slice'
import ConfirmDialog from '@components/common/ConfirmDialog'
import dayjs from 'dayjs'
import ComponentLoading from '@components/common/ComponentLoading'
import SelectCommon from '@components/common/SelectCommon'
import { handleError, renderLabel } from '@/utils'
import {
  RESET_MESSAGE,
  SET_LOADING,
  SET_MESSAGE,
} from '@/redux/reducers/app.slice'
import {
  apiDeleteSkill,
  apiProfileEdit,
  apiDeleteCareer,
} from '@/api/information'
import { AUTH_GET_INFO } from '@/redux/reducers/auth.slice'
import {
  DATE_PICKER_INPUT_YM,
  DEFAULT_LANGUAGE,
  YEAR_MONTH_FORMAT,
  YEAR_MONTH_FORMAT_DAYJS,
} from '@/constants'
import { enUS, ja } from 'date-fns/locale'
import ConfirmDeleteModal from '../common/ConfirmDeleteModal'

function Profile() {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const [confirmDate, setConfirmDate] = useState<
    {
      from_ym: Date | null
      to_ym: Date | null
    }[]
  >([
    {
      from_ym: null,
      to_ym: null,
    },
  ])
  const [showAdvise, setShowAdvise] = useState<boolean>(true)
  const [openDialog, setOpenDialog] = useState<boolean>(false)
  const [toggleDeleteSkill, setToggleDeleteSkill] = useState<boolean>(false)
  const [toggleDeleteCareer, setToggleDeleteCareer] = useState<boolean>(false)
  const [confirmDeleteSkill, setConfirmDeleteSkill] = useState<any>({})
  const [confirmDeleteCareer, setConfirmDeleteCareer] = useState<any>({})
  const [confirmSubmit, setConfirmSubmit] = useState({})
  const { currentMentor, isLoading } = useAppSelector(
    state => state.information
  )
  const { languageMaster } = useAppSelector(state => state.app)
  const { profile } = currentMentor
  const onSubmit = (data: any) => {
    const customData = {
      username: data.username,
      type: parseInt(data.type, 10),
      memo: data.memo,
      sns_twitter: data.sns_twitter,
      sns_facebook: data.sns_facebook,
      sns_instagram: data.sns_instagram,
      sns_line: data.sns_line,
      avatar_upload_id: parseInt(data.avatar_upload_id, 10),
      fre_skills: data.fre_skills,
      fre_careers: data.fre_careers,
    }
    setConfirmSubmit(customData)
    setOpenDialog(!openDialog)
  }

  // Save data
  const handleConfirmSubmit = async () => {
    try {
      dispatch(SET_LOADING(true))
      dispatch(RESET_MESSAGE())
      await apiProfileEdit({ ...confirmSubmit })
      setOpenDialog(!openDialog)
      dispatch(FETCH_MENTOR_PROFILE())
      dispatch(AUTH_GET_INFO())
    } catch (error) {
      dispatch(SET_MESSAGE(handleError(error)))
    } finally {
      dispatch(SET_LOADING(false))
    }
  }

  // Check validation field
  const Schema = Yup.object().shape({
    username: Yup.string()
      .trim()
      .max(25, t('validation.length'))
      .required(t('information_edit.profile_panel.validate.required')),
    avatar: Yup.mixed(),
    describe: Yup.string(),
    twitter: Yup.string(),
    facebook: Yup.string(),
    instagram: Yup.string(),
    line: Yup.string(),
    skill_name: Yup.string().trim().max(50, t('validation.length')),
  })

  const { handleSubmit, control, register, reset } = useForm({
    criteriaMode: 'firstError',
    reValidateMode: 'onChange',
    shouldFocusError: true,
    resolver: yupResolver(Schema),
    defaultValues: profile,
    mode: 'onChange',
  })

  // Add skill to array (Add, remove)
  const {
    fields: skillList,
    append: appendSkill,
    remove: removeSkill,
  } = useFieldArray({
    control,
    name: 'fre_skills',
  })

  // Add career to array(Add, remove)
  const {
    fields: careerList,
    append: appendCareer,
    remove: removeCareer,
  } = useFieldArray({
    control,
    name: 'fre_careers',
  })

  // Handle skill
  const handleDeleteSkill = async ({ skill, idx }: any) => {
    try {
      if (skill.skill_id) {
        dispatch(SET_LOADING(true))
        dispatch(RESET_MESSAGE())
        await apiDeleteSkill(skill.skill_id)
      }
      removeSkill(idx)
      setToggleDeleteSkill(false)
    } catch (error) {
      dispatch(SET_MESSAGE(handleError(error)))
    } finally {
      dispatch(SET_LOADING(false))
    }
  }

  // Handle career
  const handleDeleteCareer = async ({ career, idx }: any) => {
    try {
      if (career.career_id) {
        dispatch(SET_LOADING(true))
        dispatch(RESET_MESSAGE())
        await apiDeleteCareer(career.career_id)
      }
      removeCareer(idx)
      setToggleDeleteCareer(false)
    } catch (error) {
      dispatch(SET_MESSAGE(handleError(error)))
    } finally {
      dispatch(SET_LOADING(false))
    }
  }

  useEffect(() => {
    dispatch(FETCH_MENTOR_PROFILE())
  }, [])

  useEffect(() => {
    reset(profile)
  }, [profile])

  useEffect(() => {
    if (profile.fre_careers) {
      const confirmDateClone = new Array(profile.fre_careers.length).fill({
        from_ym: null,
        to_ym: null,
      })
      setConfirmDate(confirmDateClone)
    }
  }, [profile])

  return !isLoading.profile ? (
    <Box
      component="form"
      onSubmit={handleSubmit(onSubmit)}
      className="edit-profile-container"
    >
      <Grid container direction="column" spacing={3}>
        <Grid item>
          <Typography variant="h4" fontWeight="bold" color="primary">
            {t('information_edit.profile_panel.title')}
          </Typography>
          <Typography variant="h5" fontWeight="bold" mt={2}>
            {t('information_edit.profile_panel.basic_information')}
          </Typography>
          <Divider />
        </Grid>
        <Grid item>
          <Stack spacing={3}>
            <Controller
              name="username"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  label={renderLabel(
                    t('information_edit.profile_panel.full_name')
                  )}
                  className="text-field-require"
                  error={!!error}
                  helperText={error?.message}
                  inputProps={{ maxLength: 25 }}
                />
              )}
            />
            <Typography>
              {t('information_edit.profile_panel.advise')}
            </Typography>
            <Controller
              name="id"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  disabled
                  id="id"
                  label={t('information_edit.profile_panel.mentor_id')}
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            />
            <Controller
              name="avatar_upload_id"
              control={control}
              render={({ field }) => (
                <Stack direction="row" spacing={4} alignItems="center">
                  <Typography variant="body2">
                    {t('information_edit.profile_panel.icon')}
                  </Typography>
                  {showAdvise && (
                    <Typography sx={{ opacity: 0.5 }}>
                      {t('information_edit.profile_panel.avatar_advise')}
                    </Typography>
                  )}
                  <Box>
                    <UploadImage
                      idUpload="avatar_upload_id"
                      type="MENTOR_AVATAR"
                      formType="edit"
                      onChange={field.onChange}
                      size={{ height: '60px', width: '60px' }}
                      imageURL={profile.avatar || ''}
                      isDelete
                      maxSize={5}
                      errMessage={t(
                        'information_edit.profile_panel.avatar_invalid'
                      )}
                      setShowAdvise={setShowAdvise}
                    />
                  </Box>
                </Stack>
              )}
            />
            <Controller
              name="email"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  disabled
                  id="email"
                  label={t('information_edit.profile_panel.email_address')}
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            />
            <Controller
              name="memo"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  value={field.value || ''}
                  id="memo"
                  multiline
                  minRows={5}
                  maxRows={12}
                  label={t('information_edit.profile_panel.describe')}
                  placeholder={t(
                    'information_edit.profile_panel.placeholder_describe'
                  )}
                  error={!!error}
                  helperText={error?.message}
                  inputProps={{ maxLength: 1000 }}
                />
              )}
            />
          </Stack>
        </Grid>
        <Grid item>
          <Typography variant="h5" fontWeight="bold" sx={{ marginTop: 2 }}>
            {t('information_edit.profile_panel.skill')}
          </Typography>
          <Divider />
          <Stack spacing={3} pt={3}>
            {skillList.map((skill, idx) => (
              <Stack direction="column" spacing={3} key={skill.id}>
                <Controller
                  name={`fre_skills.${idx}.skill_name`}
                  control={control}
                  render={({ field }) => (
                    <TextField
                      id="skill_name"
                      value={field.value || ''}
                      inputProps={{ maxLength: 50 }}
                      label={t('information_edit.profile_panel.skill_name')}
                      placeholder={t(
                        'information_edit.profile_panel.placeholder_skill_name'
                      )}
                      {...register(`fre_skills.${idx}.skill_name`)}
                    />
                  )}
                />
                <Controller
                  name={`fre_skills.${idx}.skill_desc`}
                  control={control}
                  render={({ field }) => (
                    <TextField
                      id="skill_desc"
                      inputProps={{ maxLength: 500 }}
                      value={field.value || ''}
                      multiline
                      minRows={3}
                      label={t(
                        'information_edit.profile_panel.skill_description'
                      )}
                      placeholder={t(
                        'information_edit.profile_panel.placeholder_skill_description'
                      )}
                      {...register(`fre_skills.${idx}.skill_desc`)}
                    />
                  )}
                />
                <Stack direction="row" spacing={3}>
                  <Controller
                    name={`fre_skills.${idx}.level`}
                    control={control}
                    render={({ field }) => (
                      <SelectCommon
                        defaultValueMaster={field.value?.toString()}
                        api="freelance_skill_level"
                        handleChange={(e: string) => field.onChange(Number(e))}
                        label={t('information_edit.profile_panel.level')}
                        placeHolder={t(
                          'information_edit.profile_panel.select_placeholder'
                        )}
                      />
                    )}
                  />
                  <Controller
                    name={`fre_skills.${idx}.years_exper`}
                    control={control}
                    render={({ field }) => (
                      <SelectCommon
                        defaultValueMaster={field.value?.toString()}
                        api="freelance_skill_exp"
                        handleChange={(e: string) => field.onChange(Number(e))}
                        label={t(
                          'information_edit.profile_panel.year_experience'
                        )}
                        placeHolder={t(
                          'information_edit.profile_panel.select_placeholder'
                        )}
                      />
                    )}
                  />
                </Stack>
                <Stack
                  direction="row-reverse"
                  justifyContent="flex-end"
                  alignItems="flex-start"
                  spacing={2}
                >
                  <Button
                    onClick={() => {
                      setConfirmDeleteSkill({ skill, idx })
                      setToggleDeleteSkill(true)
                    }}
                    startIcon={<CloseIcon color="error" />}
                  >
                    {t('information_edit.profile_panel.delete_skills')}
                  </Button>
                </Stack>
                <Divider />
              </Stack>
            ))}
            <Stack justifyContent="center" alignItems="center">
              <Button
                variant="outlined"
                onClick={() =>
                  appendSkill({
                    skill_name: '',
                    skill_description: '',
                    level: 0,
                    years_exper: 0,
                  })
                }
              >
                {t('information_edit.profile_panel.add_skills')}
              </Button>
            </Stack>
          </Stack>
        </Grid>
        <Grid item>
          <Typography variant="h5" fontWeight="bold">
            {t('information_edit.profile_panel.career_achievement')}
          </Typography>
          <Divider />
          <Stack spacing={3} pt={3}>
            {careerList.map((career, idx) => (
              <Stack direction="column" spacing={3} key={career.id}>
                <Controller
                  name={`fre_careers.${idx}.desc`}
                  control={control}
                  render={({ field }) => (
                    <TextField
                      id="desc"
                      value={field.value || ''}
                      label={t(
                        'information_edit.profile_panel.career_achievement'
                      )}
                      {...register(`fre_careers.${idx}.desc`)}
                      multiline
                      minRows={3}
                      inputProps={{ maxLength: 500 }}
                      placeholder={t(
                        'information_edit.profile_panel.placeholder_skill_description'
                      )}
                      {...register(`fre_careers.${idx}.desc`)}
                    />
                  )}
                />

                <LocalizationProvider
                  dateAdapter={AdapterDateFns}
                  locale={DEFAULT_LANGUAGE === languageMaster ? ja : enUS}
                >
                  <Typography>
                    {t('information_edit.profile_panel.period')}
                  </Typography>
                  <Stack
                    direction="row"
                    alignItems="center"
                    spacing={3}
                    maxWidth="50%"
                  >
                    <Controller
                      name={`fre_careers.${idx}.from_ym`}
                      control={control}
                      render={({ field }) => (
                        <DatePicker
                          {...field}
                          views={['year', 'month']}
                          label={t('information_edit.profile_panel.from')}
                          value={field.value}
                          onChange={newValue => {
                            field.onChange(dayjs(newValue).format('YYYY/MM'))
                            const confirmDateClone = [...confirmDate]
                            confirmDateClone[idx].from_ym = newValue as Date
                            setConfirmDate(confirmDateClone)
                          }}
                          allowSameDateSelection
                          maxDate={
                            confirmDate[idx]?.to_ym !== null
                              ? confirmDate[idx]?.to_ym
                              : new Date()
                          }
                          renderInput={params => (
                            <TextField
                              {...params}
                              onKeyDown={(e: any) => e.preventDefault()}
                            />
                          )}
                          inputFormat={YEAR_MONTH_FORMAT}
                          mask={DATE_PICKER_INPUT_YM}
                        />
                      )}
                    />
                    <Controller
                      name={`fre_careers.${idx}.to_ym`}
                      control={control}
                      render={({ field }) => (
                        <DatePicker
                          {...field}
                          views={['year', 'month']}
                          label={t('information_edit.profile_panel.to')}
                          value={field.value}
                          onChange={newValue => {
                            field.onChange(dayjs(newValue).format('YYYY/MM'))
                            const confirmDateClone = [...confirmDate]
                            confirmDateClone[idx].to_ym = newValue as Date
                            setConfirmDate(confirmDateClone)
                          }}
                          allowSameDateSelection
                          maxDate={new Date()}
                          minDate={confirmDate[idx]?.from_ym}
                          renderInput={params => (
                            <TextField
                              {...params}
                              onKeyDown={(e: any) => e.preventDefault()}
                            />
                          )}
                          inputFormat={YEAR_MONTH_FORMAT}
                          mask={DATE_PICKER_INPUT_YM}
                        />
                      )}
                    />
                  </Stack>
                </LocalizationProvider>
                <Stack
                  direction="row-reverse"
                  justifyContent="flex-end"
                  alignItems="flex-start"
                  spacing={2}
                >
                  <Button
                    onClick={() => {
                      setConfirmDeleteCareer({ career, idx })
                      setToggleDeleteCareer(true)
                    }}
                    startIcon={<CloseIcon color="error" />}
                  >
                    {t('information_edit.profile_panel.delete_careers')}
                  </Button>
                </Stack>
                <Divider />
              </Stack>
            ))}
            <Stack justifyContent="center" alignItems="center">
              <Button
                variant="outlined"
                onClick={() => {
                  appendCareer({
                    desc: '',
                    from_ym: dayjs().format(YEAR_MONTH_FORMAT_DAYJS),
                    to_ym: dayjs().format(YEAR_MONTH_FORMAT_DAYJS),
                  })
                  const confirmDateClone = [...confirmDate]
                  confirmDateClone.push({
                    from_ym: null,
                    to_ym: null,
                  })
                  setConfirmDate(confirmDateClone)
                }}
              >
                {t('information_edit.profile_panel.add_career_achievement')}
              </Button>
            </Stack>
          </Stack>
        </Grid>
        <Grid item>
          <Typography variant="h5" fontWeight="bold">
            {t('information_edit.profile_panel.sns_account')}
          </Typography>
          <Divider />
          <Stack spacing={3} marginTop={2} marginBottom={2}>
            <Stack
              direction="row"
              alignItems="center"
              maxWidth="80%"
              spacing={1}
            >
              <Typography fontWeight="bold" noWrap width={120}>
                Twitter:
              </Typography>
              <TextField
                sx={{ maxWidth: 250 }}
                variant="outlined"
                disabled
                value="https://twitter.com/"
              />
              <Controller
                name="sns_twitter"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    placeholder={t('information_edit.profile_panel.username')}
                    sx={{ maxWidth: 200 }}
                    {...field}
                    value={field.value || ''}
                    id="sns_twitter"
                    error={!!error}
                    helperText={error?.message}
                  />
                )}
              />
            </Stack>
            <Stack
              direction="row"
              alignItems="center"
              maxWidth="80%"
              spacing={1}
            >
              <Typography fontWeight="bold" noWrap width={120}>
                Facebook:
              </Typography>
              <TextField
                sx={{ maxWidth: 250 }}
                variant="outlined"
                disabled
                value="https://www.facebook.com/"
              />
              <Controller
                name="sns_facebook"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    placeholder={t('information_edit.profile_panel.username')}
                    sx={{ maxWidth: 200 }}
                    {...field}
                    value={field.value || ''}
                    id="sns_facebook"
                    error={!!error}
                    helperText={error?.message}
                  />
                )}
              />
            </Stack>
            <Stack
              direction="row"
              alignItems="center"
              maxWidth="80%"
              spacing={1}
            >
              <Typography fontWeight="bold" noWrap width={120}>
                Instagram:
              </Typography>
              <TextField
                sx={{ maxWidth: 250 }}
                variant="outlined"
                disabled
                value="https://www.instagram.com/"
              />
              <Controller
                name="sns_instagram"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    placeholder={t('information_edit.profile_panel.username')}
                    sx={{ maxWidth: 200 }}
                    {...field}
                    value={field.value || ''}
                    id="sns_instagram"
                    error={!!error}
                    helperText={error?.message}
                  />
                )}
              />
            </Stack>
            <Stack
              direction="row"
              alignItems="center"
              maxWidth="80%"
              spacing={1}
            >
              <Typography fontWeight="bold" noWrap width={120}>
                LINE:
              </Typography>
              <TextField
                sx={{ maxWidth: 250 }}
                variant="outlined"
                disabled
                value="https://line.me/R/ti/p/"
              />
              <Controller
                name="sns_line"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    placeholder={t('information_edit.profile_panel.user_id')}
                    sx={{ maxWidth: 200 }}
                    {...field}
                    value={field.value || ''}
                    id="sns_line"
                    error={!!error}
                    helperText={error?.message}
                  />
                )}
              />
            </Stack>
          </Stack>
        </Grid>
      </Grid>
      <Stack mt={4}>
        <Button variant="contained" type="submit">
          {t('information_edit.submit_button')}
        </Button>
      </Stack>
      <ConfirmDialog
        openDialog={openDialog}
        setOpenDialog={setOpenDialog}
        dialogContent={t('information_edit.confirm')}
        onConfirmDialog={handleConfirmSubmit}
      />
      <ConfirmDeleteModal
        openDialog={toggleDeleteSkill}
        setOpenDialog={setToggleDeleteSkill}
        onAsyncConfirm={() => handleDeleteSkill(confirmDeleteSkill)}
      />
      <ConfirmDeleteModal
        openDialog={toggleDeleteCareer}
        setOpenDialog={setToggleDeleteCareer}
        onAsyncConfirm={() => handleDeleteCareer(confirmDeleteCareer)}
      />
    </Box>
  ) : (
    <ComponentLoading height="90vh" />
  )
}

export default React.memo(Profile)
