import React, { useEffect, useRef } from 'react'
import {
  Box,
  Paper,
  Grid,
  Stack,
  Tabs,
  Tab,
  TextField,
  IconButton,
} from '@mui/material'
import '@/assets/styles/pages/jobManagement.scss'
import { SearchOutlined } from '@mui/icons-material'
import { useTranslation } from 'react-i18next'
import { JobManagementParams } from '@/interfaces/jobManagement'
import { useAppDispatch } from '@/redux/hooks'
import { TabPanelProps } from '@/interfaces'
// TODO import dayjs from 'dayjs'
import {
  FETCH_LIST_FRIEND_REQUEST,
} from '@/redux/reducers/jobManagement.slice'
import TableFriendRequest from '@/components/JobManagement/TableFriendRequest'

function TabPanel({ index, value, children }: TabPanelProps) {
  return <div>{value === index && <Box>{children}</Box>}</div>
}

function FriendRequestList() {
  const [queryParamsListFriendRequest, setQueryParamsListFriendRequest] =
    React.useState<JobManagementParams>({
      page: 1,
      service_name: '',
      status: '',
      sort: '',
      search: '',
    })

  const searchRef = useRef<HTMLInputElement>(null)

  const { t } = useTranslation()

  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(FETCH_LIST_FRIEND_REQUEST(queryParamsListFriendRequest))
  }, [queryParamsListFriendRequest])

  const searchValue = (event: any) => {
    if (event.key === 'Enter') {
      setQueryParamsListFriendRequest({
        ...queryParamsListFriendRequest,
        search: event.target.value.trim(),
        page: 1,
      })
    }
  }

  return (
    <Box sx={{ flexGrow: 1 }} className="job-container">
      <Grid container spacing={2}>
        <Grid item xl={12}>
          <Paper elevation={3}>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              spacing={2}
              sx={{ marginRight: 2 }}
            >
              <Tabs
                value={0}
                aria-label="basic tabs example"
                className="reservation-container"
              >
                <Tab
                  label={t('job_management.tab_title.friend_request_list')}
                />
              </Tabs>
              <Box minWidth={310}>
                <TextField
                  className="search-input"
                  size="small"
                  fullWidth
                  onKeyDown={searchValue}
                  variant="outlined"
                  placeholder={t(
                    'job_management.table_friend_request.search_placeholder'
                  )}
                  InputProps={{
                    startAdornment: (
                      <IconButton>
                        <SearchOutlined />
                      </IconButton>
                    ),
                  }}
                  inputProps={{ ref: searchRef, maxLength: 500 }}
                />
              </Box>
            </Stack>
            <TabPanel value={0} index={0}>
              <TableFriendRequest
                queryParamsListFriendRequest={queryParamsListFriendRequest}
                setQueryParamsListFriendRequest={
                  setQueryParamsListFriendRequest
                }
              />
            </TabPanel>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  )
}
export default React.memo(FriendRequestList)
