import '@/assets/styles/layout/fitness/main.scss'
import React, { useEffect } from 'react'
import Header from '@/layout/LandingLayout/Header'
import { Outlet, useNavigate } from 'react-router-dom'
import CssBaseline from '@mui/material/CssBaseline'
import { Box } from '@mui/material'
import PageLoading from '@components/common/PageLoading'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import { useAppSelector } from '@/redux/hooks'
import { useSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'
import { ROUTER_PATH, TYPE_MESSAGE } from '@/constants'
import { getTabId, setTabId } from '@/utils'

const theme = createTheme()

export default function LandingLayout() {
  const { isLoading, message } = useAppSelector(state => state.app)
  const { enqueueSnackbar } = useSnackbar()
  const { t } = useTranslation()
  const authChannel = new BroadcastChannel('auth')

  useEffect(() => {
    if (message.type) {
      if (message.content !== '')
        enqueueSnackbar(message.content, {
          variant: message.type,
          anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
        })
      else if (message.type === TYPE_MESSAGE.SUCCESS)
        enqueueSnackbar(t('message.default_success'), {
          variant: message.type,
          anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
        })
      else
        enqueueSnackbar('message.default_error', {
          variant: message.type,
          anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
        })
    }
  }, [message])

  const { currentUser } = useAppSelector(state => state.auth)

  const navigate = useNavigate()
  useEffect(() => {
    if (currentUser?.id) {
      authChannel.postMessage({
        action: 'login',
        user: currentUser,
        tabId: getTabId(),
      })
      navigate(ROUTER_PATH.DASHBOARD)
    }
  }, [currentUser])

  useEffect(() => {
    setTabId()
    authChannel.addEventListener('message', e => {
      if (e.data.action === 'login' && e.data.tabId !== getTabId()) {
        window.location.reload()
      }
    })
  }, [])

  return (
    <ThemeProvider theme={theme}>
      <Box className="main-fitness" id="top">
        <Header />
        <CssBaseline />
        <Outlet />
      </Box>
      <PageLoading show={isLoading} />
    </ThemeProvider>
  )
}
