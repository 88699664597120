import React, { useState } from 'react'
import * as Yup from 'yup'
import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import {
  Grid,
  Button,
  Box,
  TextField,
  Typography,
  Modal,
  InputAdornment,
  IconButton,
  Checkbox,
  InputLabel,
} from '@mui/material'
import { Close, Visibility, VisibilityOff } from '@mui/icons-material'
import {
  RESET_MESSAGE,
  SET_LOADING,
  SET_MESSAGE,
} from '@/redux/reducers/app.slice'
import { handleError } from '@/utils'
import { apiUserRegister } from '@/api/auth'

import { useAppDispatch } from '@/redux/hooks'
import { useTranslation } from 'react-i18next'
import { EMAIL_REGEX, PASSWORD_REGEX } from '@/constants'
import SignUpSuccess from './SignUpSuccess'
import Guidelines from './GuideLineModal'
import Term from './TermModal'

interface ModalProps {
  isOpen: any
  setIsOpen(open: any): void
}

function SignUpModal({ isOpen, setIsOpen }: ModalProps) {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const [openModal, setOpenModal] = React.useState({
    guideLine: false,
    termService: false,
  })

  // Show password
  const [showPassword, setShowPassword] = useState(false)
  const [showPasswordConfirm, setShowPasswordConfirm] = useState(false)
  const handleClickShowPassword = () => setShowPassword(!showPassword)
  const handleClickShowPasswordConfirm = () =>
    setShowPasswordConfirm(!showPasswordConfirm)

  // Check validate form
  const defaultValues = {
    username: '',
    email: '',
    phoneNumber: '',
    password: '',
    confirmPassword: '',
    confirmCode: '',
    checkBoxGuidelines: false,
    checkBoxTerm: false,
  }
  const SignUpSchema = Yup.object().shape({
    username: Yup.string()
      .required(t('validation.username_required'))
      .max(25, t('validation.username_valid')),
    password: Yup.string()
      .required(t('validation.password_valid'))
      .min(8, t('validation.password_valid'))
      .max(32, t('validation.password_valid'))
      .matches(PASSWORD_REGEX, t('validation.password_valid')),
    confirmPassword: Yup.string()
      .required(t('validation.password_valid'))
      .oneOf(
        [Yup.ref('password'), null],
        t('validation.confirm_password_validation')
      ),
    email: Yup.string()
      .trim()
      .required(t('validation.email_required'))
      .matches(EMAIL_REGEX, t('validation.email_invalid'))
      .max(100, t('validation.length')),

    checkBoxGuidelines: Yup.boolean().oneOf([true]),
    checkBoxTerm: Yup.boolean().oneOf([true]),
  })

  const {
    handleSubmit,
    control,
    formState: { isValid },
    reset,
  } = useForm({
    resolver: yupResolver(SignUpSchema),
    defaultValues,
    mode: 'onChange',
  })

  // Submit data sign up
  const handleSignUpForm = async (data: any) => {
    try {
      dispatch(SET_LOADING(true))
      dispatch(RESET_MESSAGE())
      const { username, password, confirmPassword, email } = data
      await apiUserRegister({
        username,
        password,
        password_confirmation: confirmPassword,
        email,
      })
      setIsOpen({ ...isOpen, signUp: false, sendEmail: true })
    } catch (error) {
      dispatch(SET_MESSAGE(handleError(error)))
    } finally {
      dispatch(SET_LOADING(false))
    }
  }

  const onSubmit = (data: any) => {
    handleSignUpForm(data)
    reset()
  }

  const handleToggleModal = () => {
    setIsOpen({ ...isOpen, signUp: false })
    reset()
  }

  const handleGuideLine = () => {
    setOpenModal({ ...openModal, guideLine: true })
  }

  const handleTerm = () => {
    setOpenModal({ ...openModal, termService: true })
  }

  return (
    <>
      <Modal open={isOpen.signUp} onClose={handleToggleModal}>
        <Box className="modal-content" sx={{ minWidth: '500px' }}>
          <Grid container textAlign="center">
            <Grid item xs>
              <Typography className="title" textAlign="center">
                {t('auth.sign_up.title')}
              </Typography>
            </Grid>
            <Grid item>
              <IconButton onClick={handleToggleModal} className="close-button">
                <Close />
              </IconButton>
            </Grid>
          </Grid>
          <Box
            component="form"
            onSubmit={handleSubmit(onSubmit)}
            noValidate
            sx={{ mt: 1 }}
          >
            <Controller
              name="email"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  margin="normal"
                  fullWidth
                  id="email"
                  label={t('auth.sign_up.email_address')}
                  error={!!error}
                  helperText={error?.message}
                  inputProps={{ maxLength: 100 }}
                />
              )}
            />
            <Controller
              name="username"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  margin="normal"
                  fullWidth
                  id="username"
                  label={t('auth.sign_up.username')}
                  error={!!error}
                  helperText={error?.message}
                  inputProps={{ maxLength: 25 }}
                />
              )}
            />
            <Controller
              name="password"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  type={showPassword ? 'text' : 'password'}
                  margin="normal"
                  fullWidth
                  id="password"
                  label={t('auth.sign_up.password')}
                  error={!!error}
                  autoComplete="off"
                  helperText={error?.message}
                  inputProps={{ maxLength: 32 }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={handleClickShowPassword}>
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />
            <Controller
              name="confirmPassword"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  type={showPasswordConfirm ? 'text' : 'password'}
                  margin="normal"
                  fullWidth
                  id="confirmPassword"
                  label={t('auth.sign_up.confirm_password')}
                  error={!!error}
                  autoComplete="off"
                  helperText={error?.message}
                  inputProps={{ maxLength: 32 }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={handleClickShowPasswordConfirm}>
                          {showPasswordConfirm ? (
                            <Visibility />
                          ) : (
                            <VisibilityOff />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />
            <Controller
              name="checkBoxGuidelines"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <Box display="flex">
                  <Checkbox
                    onChange={e => {
                      field.onChange(e.target.checked)
                    }}
                    checked={field.value}
                  />
                  <InputLabel
                    shrink
                    sx={{ mt: 2, display: 'flex' }}
                    error={!!error}
                  >
                    <Typography
                      className="checkbox-primary"
                      onClick={handleGuideLine}
                    >
                      {t('auth.sign_up.fitness_guidelines')}
                    </Typography>
                    <Typography sx={{ fontWeight: 'bold' }}>
                      {t('auth.sign_up.accept_guide_policy')}
                    </Typography>
                  </InputLabel>
                </Box>
              )}
            />
            <Controller
              name="checkBoxTerm"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <Box display="flex">
                  <Checkbox
                    onChange={e => {
                      field.onChange(e.target.checked)
                    }}
                    checked={field.value}
                  />
                  <InputLabel
                    shrink
                    sx={{ mt: 2, display: 'flex' }}
                    error={!!error}
                  >
                    <Typography
                      className="checkbox-primary"
                      onClick={handleTerm}
                    >
                      {t('auth.sign_up.terms_of_service')}
                    </Typography>
                    <Typography sx={{ fontWeight: 'bold' }}>
                      {t('auth.sign_up.and')}
                    </Typography>
                    <Typography
                      className="checkbox-primary"
                      onClick={handleTerm}
                    >
                      {t('auth.sign_up.private_policy')}
                    </Typography>
                    <Typography sx={{ fontWeight: 'bold' }}>
                      {t('auth.sign_up.accept_guide_policy')}
                    </Typography>
                  </InputLabel>
                </Box>
              )}
            />
            <Button
              disabled={!isValid}
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              {t('auth.sign_up.button_sign_up')}
            </Button>
          </Box>
        </Box>
      </Modal>
      <SignUpSuccess isOpen={isOpen} setIsOpen={setIsOpen} />
      <Guidelines isOpen={openModal.guideLine} toggleModal={setOpenModal} />
      <Term isOpen={openModal.termService} toggleModal={setOpenModal} />
    </>
  )
}

export default React.memo(SignUpModal)
