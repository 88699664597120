import { DEFAULT_LANGUAGE, ROUTER_PATH } from '@/constants'
import useMasterData from '@/hooks/useMasterData'
import { JobManagementServiceListParams, Service } from '@/interfaces'
import { useAppSelector, useAppDispatch } from '@/redux/hooks'
import { getValueMasterData, handleError } from '@/utils'
import { Launch, DeleteOutline } from '@mui/icons-material'
import { Button, IconButton, Stack, Typography } from '@mui/material'
import Table, { ColumnsType } from 'antd/lib/table'
import Tooltip from 'antd/lib/tooltip'
import React, { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { generatePath, useNavigate } from 'react-router-dom'
import {
  RESET_MESSAGE,
  SET_LOADING,
  SET_MESSAGE,
} from '@/redux/reducers/app.slice'
import { apiDeleteService } from '@/api/jobManagement'
import { FETCH_LIST_SERVICE } from '@/redux/reducers/jobManagement.slice'
import ConfirmDeleteModal from '../common/ConfirmDeleteModal'

type ITableServiceProps = {
  queryParamsListService: JobManagementServiceListParams
  setQueryParamsListService: (
    queryParams: JobManagementServiceListParams
  ) => void
}

function TableService({
  queryParamsListService,
  setQueryParamsListService,
}: ITableServiceProps) {
  const { t } = useTranslation()

  const navigate = useNavigate()

  const dispatch = useAppDispatch()

  const tableRef = useRef<HTMLDivElement>(null)

  const getTableScrollY = () => {
    const extraHeight = 88 // pagination 64 + padding 24
    const tableHeader = tableRef.current?.querySelector('.ant-table-header')
    const tableHeaderBottom = tableHeader?.getBoundingClientRect().bottom
    return `calc(100vh - ${tableHeaderBottom}px - ${extraHeight}px)`
  }

  const { listService, metaListService } = useAppSelector(
    state => state.jobManagement
  )

  const { currentUser } = useAppSelector(state => state.auth)

  const { languageMaster } = useAppSelector(state => state.app)

  const [isShowDeleteServiceModal, setIsShowDeleteServiceModal] =
    React.useState<boolean>(false)
  const [deleteServiceId, setDeleteServiceId] = React.useState<number>(0)

  const handleDeleteService = async (serviceId: number) => {
    try {
      if (serviceId) {
        dispatch(SET_LOADING(true))
        dispatch(RESET_MESSAGE())
        await apiDeleteService(serviceId)
      }
      dispatch(FETCH_LIST_SERVICE({ page: 1 }))
    } catch (error) {
      dispatch(SET_MESSAGE(handleError(error)))
    } finally {
      setIsShowDeleteServiceModal(false)
      dispatch(SET_LOADING(false))
    }
  }

  const typeServiceList: Array<any> = useMasterData('freelance_service_type')

  const checkTypeService = (status: number): string => {
    const typeService = getValueMasterData(typeServiceList)
    if (languageMaster === DEFAULT_LANGUAGE)
      return typeService[status]?.title_jp
    return typeService[status]?.title_en
  }

  const columns: ColumnsType<Service> = [
    {
      title: t('job_management.table_service.id'),
      dataIndex: 'id',
      key: 'id',
      align: 'center',
      sorter: true,
      width: '4%',
    },
    {
      title: t('job_management.table_service.name'),
      dataIndex: 'name',
      key: 'name',
      align: 'left',
      sorter: true,
      width: '25%',
      render: (_, record) => (
        <Tooltip zIndex={9999} placement="topLeft" title={record.name}>
          <Typography className="ellipsis-text">{record.name}</Typography>
        </Tooltip>
      ),
    },
    {
      title: t('job_management.table_service.price'),
      dataIndex: 'price',
      key: 'price',
      align: 'right',
      sorter: true,
      width: '4%',
      render: (_, record) => (
        <Typography>
          {record.price.toLocaleString('ja-JP', {
            style: 'currency',
            currency: 'JPY',
          })}
        </Typography>
      ),
    },
    {
      title: t('job_management.table_service.time'),
      dataIndex: 'time',
      key: 'time',
      align: 'center',
      sorter: true,
      width: '3%',
      render: (_, record) => (
        <Typography>
          {record.time}
          {t('job_management.service.title.minutes')}
        </Typography>
      ),
    },
    {
      title: t('job_management.table_service.type'),
      dataIndex: 'type',
      key: 'type',
      align: 'center',
      render: (_, record) => checkTypeService(Number(record.type)),
      filters: typeServiceList.map(item => ({
        text:
          languageMaster === DEFAULT_LANGUAGE ? item.title_jp : item.title_en,
        value: Number(item.value),
      })),
      filterMultiple: false,
      width: '5%',
      ellipsis: true,
    },
    {
      title: t('job_management.table_service.total_use'),
      dataIndex: 'totalUse',
      key: 'totalUse',
      align: 'center',
      sorter: true,
      width: '5%',
    },
    {
      title: t('job_management.table_service.action'),
      key: 'action',
      render: (_, record) => (
        <Stack
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          spacing={1}
        >
          <IconButton
            sx={{ paddingRight: 0 }}
            onClick={() =>
              navigate(
                generatePath(ROUTER_PATH.JOB_MANAGEMENT_SERVICE_EDIT, {
                  id: `${record.id}`,
                  state: 'edit',
                })
              )
            }
          >
            <Launch />
          </IconButton>
          {currentUser.status !== 1 ? (
            <Typography>&nbsp;</Typography>
          ) : (
            <><Button
                variant="outlined"
                color="primary"
                size="small"
                className="btn-set-to-box"
                onClick={() => navigate(
                  generatePath(ROUTER_PATH.JOB_MANAGEMENT_BOX_SETTING, {
                    id: `${record.id}`,
                  })
                )}
              >
                {t('job_management.button.set_to_box')}
              </Button>
              {record.totalUseFromNow > 0 ? (<Button
                variant="outlined"
                color="primary"
                size="small"
                className="btn-set-to-box"
                onClick={() => navigate(
                  generatePath(ROUTER_PATH.JOB_MANAGEMENT_BOX_SERVICE_DELETE, {
                    id: `${record.id}`,
                  })
                )}
              >
                {t('job_management.button.delete_from_box')}
              </Button>) : (null)}</>
          )}
          {record.totalUse ? (
            <Typography>&nbsp;</Typography>
          ) : (
            <Button
              variant="outlined"
              color="error"
              size="small"
              onClick={() => {
                setDeleteServiceId(record.id ?? 0)
                setIsShowDeleteServiceModal(true)
              }}
            >
              <DeleteOutline />
            </Button>
          )}
        </Stack>
      ),
      width: '10%',
    },
  ]

  const filterDataService = (pagination: any, filters: any, sorted: any) => {
    let queryParamsClone = { ...queryParamsListService }
    queryParamsClone = { ...queryParamsClone, page: pagination.current }
    if (Array.isArray(filters.type))
      queryParamsClone = {
        ...queryParamsClone,
        type: filters.type[0],
        page:
          queryParamsListService.page === pagination.current
            ? 1
            : pagination.current,
      }
    else
      queryParamsClone = {
        ...queryParamsClone,
        type: '',
        page:
          queryParamsListService.page === pagination.current
            ? 1
            : pagination.current,
      }

    if (sorted.column) {
      if (sorted.order === 'ascend')
        queryParamsClone = {
          ...queryParamsClone,
          sort: `${sorted.column.key}:ASC`,
          page:
            queryParamsListService.page === pagination.current
              ? 1
              : pagination.current,
        }
      else
        queryParamsClone = {
          ...queryParamsClone,
          sort: `${sorted.column.key}:DESC`,
          page:
            queryParamsListService.page === pagination.current
              ? 1
              : pagination.current,
        }
    } else
      queryParamsClone = {
        ...queryParamsClone,
        sort: '',
        page:
          queryParamsListService.page === pagination.current
            ? 1
            : pagination.current,
      }
    setQueryParamsListService(queryParamsClone)
  }

  return (
    <>
      <Table
        columns={columns}
        dataSource={listService}
        onChange={filterDataService}
        ref={tableRef}
        scroll={{ y: getTableScrollY() }}
        locale={{
          emptyText: t('common.no_data'),
          filterReset: t('common.reset'),
          filterConfirm: t('common.apply'),
          triggerAsc: t('common.sort_asc'),
          triggerDesc: t('common.sort_desc'),
          cancelSort: t('common.cancel_sort'),
        }}
        pagination={{
          total: metaListService.total,
          current: metaListService.current_page,
          showSizeChanger: false,
          showTotal: () =>
            `${
              metaListService.per_page * (metaListService.current_page - 1) + 1
            }-${
              metaListService.per_page * (metaListService.current_page - 1) +
              listService.length
            } / ${metaListService.total}`,
        }}
      />
      <ConfirmDeleteModal
        openDialog={isShowDeleteServiceModal}
        setOpenDialog={setIsShowDeleteServiceModal}
        onAsyncConfirm={() => handleDeleteService(deleteServiceId)}
      />
    </>
  )
}

export default React.memo(TableService)
