import '@/assets/styles/layout/fitness/header.scss'
import * as React from 'react'
import { AppBar, Box, Toolbar, Container, Button } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { ROUTER_PATH } from '@/constants'
import { useLocation } from 'react-router-dom'
import logo from '@/assets/images/logo-fitness.svg'
import SignInModal from '@/components/landing/SignInModal'
import SignUpModal from '@/components/landing/SignUpModal'
import ResetPasswordModal from '@/components/landing/ResetPasswordModal'
import ActiveEmailModal from '@/components/landing/ActiveEmailModal'
import SendEmailModal from '@/components/landing/SendEmail'

export interface HeaderState {
  signIn: boolean
  signUp: boolean
  sendEmail: boolean
  signUpSuccess: boolean
  forgotPassword: boolean
  resetPassword: boolean
  activeEmail: boolean
}

function Header() {
  const { t } = useTranslation()
  const [isOpen, setIsOpen] = React.useState<HeaderState>({
    signIn: false,
    signUp: false,
    sendEmail: false,
    signUpSuccess: false,
    forgotPassword: false,
    resetPassword: false,
    activeEmail: false,
  })
  const { pathname } = useLocation()

  const handleSignIn = () => {
    setIsOpen({
      ...isOpen,
      signIn: true,
      signUp: false,
      resetPassword: false,
      forgotPassword: false,
    })
  }

  const handleSignUp = () => {
    setIsOpen({ ...isOpen, signUp: true, signIn: false })
  }

  React.useEffect(() => {
    if (pathname === ROUTER_PATH.AUTH.RESET_PASSWORD) {
      setIsOpen({
        ...isOpen,
        signIn: false,
        signUp: false,
        resetPassword: true,
      })
    }
    if (pathname === ROUTER_PATH.AUTH.ACTIVE_EMAIL) {
      setIsOpen({
        ...isOpen,
        activeEmail: true,
      })
    }
  }, [pathname])

  return (
    <AppBar position="static" className="header-fitness">
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Box className="logo-wrapper">
            <Box
              className="logo-image"
              component="img"
              src={logo}
              alt="header-logo"
              onClick={() => {
                window.location.reload()
              }}
            />
          </Box>
          <Box sx={{ flexGrow: 2, display: 'flex' }}>
            <Button>{t('landing.header.gin_beauty')}</Button>
            <Button href="#information">
              {t('landing.header.company_information')}
            </Button>
            <Button href="#inquiries">{t('landing.header.inquiries')}</Button>
          </Box>
          <Box sx={{ flexGrow: 0 }}>
            <Button variant="outlined" onClick={handleSignIn} sx={{ mr: 2 }}>
              {t('landing.header.sign_in')}
            </Button>
            <Button variant="contained" onClick={handleSignUp}>
              {t('landing.header.sign_up')}
            </Button>
          </Box>
        </Toolbar>
      </Container>
      <SignInModal isOpen={isOpen} setIsOpen={setIsOpen} />
      <SignUpModal isOpen={isOpen} setIsOpen={setIsOpen} />
      <ResetPasswordModal
        isOpen={isOpen}
        toggleModal={setIsOpen}
        handleSignIn={handleSignIn}
      />
      <ActiveEmailModal toggleModal={isOpen} setToggleModal={setIsOpen} />
      <SendEmailModal isOpen={isOpen} setIsOpen={setIsOpen} />
    </AppBar>
  )
}
export default React.memo(Header)
