import React from 'react'
import { RouteObject } from 'react-router-dom'
import PageNotFound from '@/layout/ErrorLayout/PageNotFound'

const NotFoundRouter: RouteObject = {
  path: '*',
  element: <PageNotFound />,
}

export default NotFoundRouter
