import React, { useState, useRef, useEffect } from 'react'
import {
  Button,
  Box,
  Typography,
  Popover,
  Avatar,
  Stack,
  Dialog,
  DialogTitle,
} from '@mui/material'
import ExpandMoreTwoToneIcon from '@mui/icons-material/ExpandMoreTwoTone'
import { useNavigate } from 'react-router-dom'
import { AUTH_LOGOUT } from '@/redux/reducers/auth.slice'
import { useAppDispatch, useAppSelector } from '@/redux/hooks'
import { useTranslation } from 'react-i18next'
import { ROUTER_PATH } from '@/constants'

function AccountProfile() {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const ref = useRef(null)
  const [open, setOpen] = useState(false)
  const [openDialog, setOpenDialog] = useState(false)
  const [fullWidth] = useState(true)
  const { currentUser } = useAppSelector(state => state.auth)
  const { isLoading } = useAppSelector(state => state.app)

  const handleOpen = () => {
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
  }
  const handleSignOut = () => {
    setOpenDialog(true)
  }

  const handleSignOutDialog = () => {
    dispatch(AUTH_LOGOUT())
    setOpen(false)
  }

  const handleProfile = () => {
    navigate(ROUTER_PATH.INFORMATION_EDIT)
    setOpen(false)
  }

  useEffect(() => {
    if (!isLoading && !currentUser.id) navigate(ROUTER_PATH.LANDING)
  }, [isLoading])

  return (
    <>
      <Button ref={ref} onClick={handleOpen}>
        <Box sx={{ pl: 1, textAlign: 'right' }}>
          <Typography variant="body1" sx={{ fontWeight: 700 }}>
            {currentUser.username}
          </Typography>
        </Box>
        <ExpandMoreTwoToneIcon sx={{ ml: 1 }} />
      </Button>
      <Popover
        open={open}
        onClose={handleClose}
        sx={{ width: 300 }}
        anchorEl={ref.current}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            mr: 2,
            overflow: 'hidden',
            width: '100%',
            borderRadius: '10px',
            padding: 2.5,
          },
        }}
      >
        <Stack direction="column" spacing={2} alignItems="center">
          <Typography textAlign="center">{t('header.description')}</Typography>
          <Avatar
            alt="avatar"
            src={currentUser.avatar}
            sx={{ width: 100, height: 100 }}
          />
          <Typography textAlign="center" fontWeight="bold" fontSize={18}>
            {currentUser.email}
          </Typography>
          <Button
            fullWidth
            variant="outlined"
            color="primary"
            sx={{
              fontWeight: 600,
              fontSize: 14,
              height: 36,
              borderRadius: '30px',
            }}
            onClick={handleProfile}
          >
            {t('header.button.edit_profile')}
          </Button>
          <Button
            fullWidth
            variant="outlined"
            sx={{
              fontWeight: 600,
              fontSize: 14,
              height: 36,
              borderRadius: '30px',
            }}
            onClick={handleSignOut}
          >
            {t('header.button.logout')}
          </Button>
        </Stack>
      </Popover>
      <Dialog
        onClose={() => setOpenDialog(false)}
        open={openDialog}
        maxWidth="sm"
        fullWidth={fullWidth}
        sx={{
          padding: 3,
        }}
      >
        <Stack direction="column" spacing={8} alignItems="center" p={3}>
          <DialogTitle sx={{ padding: 0, fontWeight: 'bold', fontSize: 45 }}>
            {t('header.button.logout')}
          </DialogTitle>
          <Typography textAlign="center" fontSize={20}>
            {t('header.confirm_logout')}
          </Typography>
          <Stack direction="row" spacing={4} width="50%">
            <Button
              fullWidth
              variant="contained"
              color="error"
              sx={{
                fontWeight: 600,
                fontSize: 14,
                height: 36,
              }}
              onClick={() => setOpenDialog(false)}
            >
              {t('header.button.cancel')}
            </Button>
            <Button
              fullWidth
              variant="outlined"
              sx={{
                fontWeight: 600,
                fontSize: 14,
                height: 36,
              }}
              onClick={handleSignOutDialog}
            >
              {t('header.button.ok')}
            </Button>
          </Stack>
        </Stack>
      </Dialog>
    </>
  )
}

export default React.memo(AccountProfile)
