import {
  Checkbox,
  FormControlLabel,
  Stack,
  FormControl,
  FormLabel,
  FormHelperText,
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useAppSelector } from '@/redux/hooks'
import { IGeneralCommon } from '@/interfaces'
import { DEFAULT_LANGUAGE } from '@/constants'
import useMasterData from '@/hooks/useMasterData'
import { generateKey } from '@/utils'

function CheckboxCommon({
  api, // Type category
  label, // Label of Input,
  handleChange, // Function Callback
  defaultValueMaster, // Default Value (Optional)
  isVertical = false, // Show List by row or column (Optional)
  isDisable, // Is Disable (Optional)
  isRequire, // Validate (Optional)
  errMessage, // Err message if have validate (Optional)
  isError, // If input have error will notice that error
}: IGeneralCommon) {
  const [listCheckbox, setListCheckbox] = useState<Array<any>>([])

  const [listChecked, setListChecked] = useState<Array<string | number>>([])

  const [language, setLanguage] = useState<string | null>('')

  const { languageMaster } = useAppSelector(state => state.app)

  const listData: Array<any> = useMasterData(api)

  useEffect(() => {
    setListCheckbox(listData)
  }, [listData])

  useEffect(() => {
    if (Array.isArray(defaultValueMaster)) {
      setListChecked(defaultValueMaster)
    }
  }, [defaultValueMaster])

  useEffect(() => {
    setLanguage(languageMaster)
  }, [languageMaster])

  // Handle get item checked
  const handleChangeCheckbox = (
    e: React.ChangeEvent<HTMLInputElement>,
    item: string | number
  ) => {
    const { checked } = e.target as HTMLInputElement
    let listCheckedClone = [...listChecked]
    if (checked) {
      listCheckedClone.push(item)
    } else {
      listCheckedClone = listCheckedClone.filter(
        checkedItem => checkedItem !== item
      )
    }
    setListChecked(listCheckedClone)
    handleChange(listCheckedClone)
  }

  return (
    <FormControl
      error={isRequire && listChecked.length === 0 && isError}
      className={isRequire ? 'text-field-require-checkbox' : ''}
    >
      <FormLabel className="label-main">{label}</FormLabel>
      <Stack
        direction={isVertical ? 'column' : 'row'}
        alignItems={isVertical ? 'flex-start' : 'center'}
        justifyContent={isVertical ? 'center' : 'flex-start'}
        className={isVertical ? 'checkbox-service' : ''}
      >
        {listCheckbox.map((item, index) => (
          <FormControlLabel
            key={generateKey('listCheckbox', index)}
            label={
              language === DEFAULT_LANGUAGE ? item.title_jp : item.title_en
            }
            sx={{ m: 0 }}
            control={
              <Checkbox
                disabled={isDisable}
                checked={listChecked.includes(item.value)}
                onChange={e => handleChangeCheckbox(e, item.value)}
              />
            }
          />
        ))}
      </Stack>
      {isRequire && isError && listChecked.length === 0 && (
        <FormHelperText>{errMessage}</FormHelperText>
      )}
    </FormControl>
  )
}

export default React.memo(CheckboxCommon)
