import { getUserToken } from '@/utils'
import axios, { AxiosRequestConfig, AxiosInstance } from 'axios'

const token = getUserToken()

export const axiosTest: AxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    'Content-Type': 'multipart/form-data; boundary',
    Authorization: `Bearer ${token}`,
  },
})
interface RequesterConfig {
  get<T = any>(
    url: string,
    params?: any,
    config?: AxiosRequestConfig
  ): Promise<T>
  post<T = any>(
    url: string,
    data?: any,
    config?: AxiosRequestConfig
  ): Promise<T>
  put<T = any>(url: string, data?: any, config?: AxiosRequestConfig): Promise<T>
  delete<T = any>(url: string, config?: AxiosRequestConfig): Promise<T>
}
// const responseBody = (res: AxiosResponse) => res.data
export const requester: RequesterConfig = {
  get: (url, params, config = {}) =>
    axiosTest.get(url, {
      params,
      ...config,
    }),
  // .then(responseBody),
  post: (url, data, config = {}) => axiosTest.post(url, data, config),
  put: (url, data, config = {}) => axiosTest.put(url, data, config),
  delete: (url, data) => axiosTest.delete(url, { data }),
}
