import React, { useEffect, useMemo, useState } from 'react'
import {
  Modal,
  Box,
  Stack,
  Typography,
  Button,
  TextField,
  MenuItem,
  Divider,
  IconButton,
} from '@mui/material'
import { Close, AddTask, Remove } from '@mui/icons-material'
import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm, useFieldArray, Controller } from 'react-hook-form'
import { DatePicker } from '@mui/lab'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import { useTranslation } from 'react-i18next'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import { useAppSelector, useAppDispatch } from '@/redux/hooks'
import dayjs from 'dayjs'
import {
  DATE_PICKER_INPUT_YMD,
  DATE_STRING,
  DATE_STRING_FOR_SCHEDULE,
  DEFAULT_LANGUAGE,
  HOUR_SECOND,
  INVALID_DATE,
  LOOP_TYPE,
} from '@/constants'
import { useParams } from 'react-router-dom'
import { enUS, ja } from 'date-fns/locale'
import { generateKey, handleBoxSettingError } from '@/utils'
import {
  RESET_MESSAGE,
  SET_LOADING,
  SET_MESSAGE,
} from '@/redux/reducers/app.slice'
import { apiSetBox, getListPackItem } from '@/api/jobManagement'
import {
  EDIT_SERVICE_TIME,
  ADD_SERVICE_TIME,
  HANDLE_SERVICE_TIME,
} from '@/redux/reducers/jobManagement.slice'
import CloseIcon from '@mui/icons-material/Close'

interface ModalProps {
  isOpen: boolean
  toggleModal(open: any): void
  date: Date | null
  boxId: string | number
  createTimeArray: any
  step: number
  queryParams: any
}

function BoxSettingModal({
  isOpen,
  toggleModal,
  date,
  boxId,
  createTimeArray,
  step,
  queryParams,
}: ModalProps) {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  // Check validation form field
  const Schema = Yup.object().shape({
    loop_type: Yup.string(),
    loop_start_date: Yup.string().when('loop_type', (loopType, schema) => {
      if (loopType)
        return schema.required(
          t('job_management.box_setting.box_modal.validate_require_time')
        )
      return schema
    }),
    loop_end_date: Yup.string().when('loop_type', (loopType, schema) => {
      if (loopType)
        return schema.required(
          t('job_management.box_setting.box_modal.validate_require_time')
        )
      return schema
    }),
  })
  // #245551 loop by day or week or month
  const loopTypeArray = [
    {
      value: LOOP_TYPE.NONE,
      label: '',
    },
    {
      value: LOOP_TYPE.DAILY,
      label: t('job_management.box_setting.box_modal.every_day'),
    },
    {
      value: LOOP_TYPE.WEEKLY,
      label: t('job_management.box_setting.box_modal.every_week'),
    },
    {
      value: LOOP_TYPE.MONTHLY,
      label: t('job_management.box_setting.box_modal.every_month'),
    },
  ]

  const params = useParams()

  const { boxSettingEdit } = useAppSelector(state => state.jobManagement)
  const { languageMaster } = useAppSelector(state => state.app)

  const [endServiceTime, setEndServiceTime] = useState<string[]>([])
  const [unavailable, setUnavailable] = useState<number[]>([])
  const [packId, setPackId] = useState('')
  const [findPackId, setFindPackId] = useState<any>('')
  const [packItem, setPackItem] = useState('')
  const [listPackItems, setListPackItems] = useState<any>([])
  const [subscriptionFlag, setSubscriptionFlag] = useState(true)

  const [checkDate, setCheckDate] = useState<{
    startDate: any
    endDate: any
  }>({
    startDate: '',
    endDate: '',
  })

  const [loopType, setLoopType] = useState<string>('')
  const { control, register, handleSubmit, reset, setValue, clearErrors } =
    useForm({
      resolver: yupResolver(Schema),
      defaultValues: {
        box_id: '',
        date: '',
        time: boxSettingEdit.serviceBoxes.map(item => ({
          id: 0,
          date_time: dayjs(item.date_time).format(HOUR_SECOND),
          reservations: item.reservations ?? 0,
          time_id: 0,
          isError: false,
        })),
        loop_type: '',
        loop_start_date: '',
        loop_end_date: '',
        pack_id: '',
        pack_item_id: '',
      },
      mode: 'onChange',
    })
  const { fields: serviceList, append: appendService } = useFieldArray({
    control,
    name: 'time',
  })

  useEffect(() => {
    if (
      boxSettingEdit?.serviceBoxes?.length === 0 ||
      boxSettingEdit?.pack_items?.length === 0 ||
      !isOpen
    ) {
      setFindPackId('')
      setPackId('')
      setPackItem('')
    }
  }, [isOpen])

  // Show/hide button delete time
  const isShowButtonDelete = (idx: number): boolean => {
    if (boxSettingEdit.serviceBoxes[idx]) {
      return boxSettingEdit.serviceBoxes[idx].reservations === 0
    }
    return true
  }

  // Remove time service
  const handleRemoveService = (idx: number) => {
    if (boxSettingEdit.serviceBoxes[idx]) {
      dispatch(
        EDIT_SERVICE_TIME(
          boxSettingEdit.serviceBoxes.filter((_, index) => index !== idx)
        )
      )
    }
  }

  // Submit data to edit/add time of service
  const onSubmit = async (data: any) => {
    data = {
      ...data,
      time: data.time
        .map((item: any) => item.date_time)
        .filter((item: any) => item !== INVALID_DATE),
      box_id: boxId,
      date: dayjs(date).format(DATE_STRING),
      loop_start_date: data.loop_type
        ? dayjs(data.loop_start_date).format(DATE_STRING)
        : '',
      loop_end_date: data.loop_type
        ? dayjs(data.loop_end_date).format(DATE_STRING)
        : '',
      service_id: params.id,
      pack_id: packId,
      pack_item_id: packItem,
    }
    try {
      dispatch(SET_LOADING(true))
      dispatch(RESET_MESSAGE())
      await apiSetBox(data)
      queryParams()
      toggleModal(false)
    } catch (error) {
      const { notice, unAvailableTime } = handleBoxSettingError(error)
      dispatch(SET_MESSAGE(notice))
      setUnavailable(unAvailableTime)
    } finally {
      dispatch(SET_LOADING(false))
    }
  }

  const isDisabled = (item: string) => {
    const dateStart = dayjs(date).format(DATE_STRING)
    return dayjs(`${dateStart}T${item}`).isBefore(dayjs())
  }

  const renderServiceList = useMemo(
    () =>
      serviceList.map((service: any, idx: any) => (
        <Box key={generateKey('Service-id', idx)}>
          <Stack
            direction="row"
            spacing={3}
            alignItems="center"
            key={service.id}
            mt={1}
          >
            <Controller
              name={`time.${idx}.date_time`}
              control={control}
              render={({ field }) => (
                <TextField
                  label={`${t('job_management.box_setting.box_modal.time')} ${
                    idx + 1
                  }`}
                  select
                  fullWidth
                  {...register(`time.${idx}.date_time`)}
                  value={field.value}
                  disabled={dayjs(date).isBefore(dayjs().subtract(1, 'd'))}
                  onChange={(e: any) => {
                    const endServiceTimeClone = [...endServiceTime]
                    endServiceTimeClone[idx] = e.target.value
                    setEndServiceTime(endServiceTimeClone)
                    field.onChange(e.target.value)
                    dispatch(
                      HANDLE_SERVICE_TIME({
                        id: service.time_id,
                        date_time: `${dayjs(date).format(
                          DATE_STRING_FOR_SCHEDULE
                        )} ${e.target.value}`,
                      })
                    )
                  }}
                  sx={{ height: '200' }}
                  error={serviceList[idx].isError}
                >
                  {Array.isArray(createTimeArray) &&
                    createTimeArray.map((item: any) => (
                      <MenuItem
                        key={item}
                        value={item}
                        disabled={isDisabled(item)}
                      >
                        {item}
                      </MenuItem>
                    ))}
                </TextField>
              )}
            />
            <Controller
              name={`time.${idx}.isError`}
              control={control}
              render={() => (
                <TextField
                  value={
                    dayjs(`2022-09-02T${endServiceTime[idx]}`).isValid()
                      ? dayjs(`2022-09-02T${endServiceTime[idx]}`)
                          .add(step, 'minute')
                          .format(HOUR_SECOND)
                      : ''
                  }
                  inputProps={{ readOnly: true }}
                  error={serviceList[idx].isError}
                />
              )}
            />
            {isShowButtonDelete(idx) ? (
              <Box>
                {!dayjs(date).isBefore(dayjs().subtract(1, 'd')) && (
                  <IconButton onClick={() => handleRemoveService(idx)}>
                    <Remove color="error" />
                  </IconButton>
                )}
              </Box>
            ) : (
              <Box>
                <IconButton disabled>
                  <Remove sx={{ color: '#fff' }} />
                </IconButton>
              </Box>
            )}
          </Stack>
          {/* TODO: {!isShowButtonDelete(idx) && (
        <Typography>
          {t(
            'job_management.box_setting.box_modal.cannot_delete'
          )}
        </Typography>
      )} */}
        </Box>
      )),
    [serviceList, endServiceTime, boxSettingEdit.serviceBoxes]
  )

  useEffect(() => {
    unavailable.forEach(item => {
      serviceList[item].isError = true
    })
  }, [unavailable])

  const getDifference = (arrayFirst: any, arraySecond: any) =>
    arrayFirst.filter((objectFirst: any) =>
      arraySecond.some(
        (objectSecond: any) => objectFirst.id === objectSecond.pack_id
      )
    )

  const getDifferencePackItem = (arrayFirst: any, arraySecond: any) =>
    arrayFirst.filter((objectFirst: any) =>
      arraySecond.some(
        (objectSecond: any) => objectFirst.id === objectSecond.pack_item_id
      )
    )

  useEffect(() => {
    if (
      boxSettingEdit.packs.length > 0 &&
      boxSettingEdit?.serviceBoxes.length > 0
    ) {
      const result = getDifference(
        boxSettingEdit.packs,
        boxSettingEdit.serviceBoxes
      )
      setFindPackId(result[0]?.id ?? '')
      setPackId(result[0]?.id ?? '')
    }

    if (
      boxSettingEdit.pack_items.length > 0 &&
      boxSettingEdit?.serviceBoxes.length > 0
    ) {
      const resultPack = getDifferencePackItem(
        boxSettingEdit.pack_items,
        boxSettingEdit.serviceBoxes
      )
      setPackItem(resultPack[0]?.id ?? '')
    }
  }, [boxSettingEdit?.packs, boxSettingEdit?.pack_items])

  useEffect(() => {
    if (!packId) {
      setPackItem('')
      setListPackItems([])
      setSubscriptionFlag(true)
    }
  }, [packId])

  useEffect(() => {
    reset({
      box_id: '',
      date: '',
      loop_type: '',
      loop_start_date: '',
      loop_end_date: '',
      time: [],
    })
    setLoopType(LOOP_TYPE.NONE)
    setCheckDate({ startDate: '', endDate: '' })
  }, [isOpen])

  useEffect(() => {
    if (serviceList) {
      const endServiceTimeClone = serviceList.map(item => item.date_time)
      setEndServiceTime(endServiceTimeClone)
    }
  }, [serviceList])

  useEffect(() => {
    reset({
      box_id: '',
      date: '',
      loop_type: '',
      loop_start_date: '',
      loop_end_date: '',
      time: boxSettingEdit.serviceBoxes.map(item => ({
        id: item.id,
        date_time: dayjs(item.date_time).format(HOUR_SECOND),
        reservations: item.reservations ?? 0,
        time_id: item.id,
      })),
    })
  }, [boxSettingEdit])

  const getListPack = async (data: any) => {
    const { status, data: response } = await getListPackItem(
      data,
      params.id ?? ''
    )
    if (status === 200) {
      setListPackItems(response)
      setSubscriptionFlag(false)
    } else {
      setSubscriptionFlag(true)
    }
  }

  useEffect(() => {
    if (packId) {
      const getDataListPackItem = async () => {
        const { status, data: response } = await getListPackItem(
          packId,
          params.id ?? ''
        )
        if (status === 200) {
          setListPackItems(response)
          setSubscriptionFlag(false)
        } else {
          setSubscriptionFlag(true)
        }
      }
      getDataListPackItem()
    }
  }, [packId])

  return (
    <Modal open={isOpen} onClose={() => toggleModal(!isOpen)}>
      <Box
        className="modal-content"
        component="form"
        p={1}
        width={600}
        onSubmit={handleSubmit(onSubmit)}
      >
        <Stack direction="column" spacing={3} p={3}>
          <Stack direction="row" justifyContent="space-between">
            <Typography variant="h6">
              {t('job_management.box_setting.box_modal.title')}
            </Typography>
            <IconButton onClick={() => toggleModal(!isOpen)}>
              <Close />
            </IconButton>
          </Stack>
          <Divider />
          <Stack direction="column" spacing={2}>
            <Typography variant="body2" fontWeight="bold">
              {t('job_management.box_setting.box_modal.box_information')}
            </Typography>
            <Divider />
            <Stack direction="row" justifyContent="space-between">
              <TextField
                label={t('job_management.box_setting.group_1')}
                disabled
                value={boxSettingEdit.group_box_name_1}
              />
              <TextField
                label={t('job_management.box_setting.group_2')}
                disabled
                value={boxSettingEdit.group_box_name_2}
              />
            </Stack>
            <Stack direction="row" justifyContent="space-between">
              <TextField
                label={t('job_management.box_setting.box_name_edit')}
                disabled
                value={boxSettingEdit.nick_name}
              />
              <TextField
                label={t('job_management.box_setting.box_modal.date')}
                value={dayjs(date).format(DATE_STRING)}
                disabled
              />
            </Stack>
          </Stack>
          {/* #258457 add dropdown menu */}
          {boxSettingEdit.is_dropdown ? (
            <Stack direction="column" spacing={2}>
              <Typography variant="body2" fontWeight="bold">
                {t('job_management.box_setting.box_modal.menu')}
              </Typography>
              <Divider />
              <Stack direction="row" spacing={3} alignItems="center">
                <Stack width="600px" direction="row" alignItems="center">
                  <Controller
                    name="pack_id"
                    control={control}
                    render={({ field }) => (
                      <div
                        style={{
                          width: '100%',
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'space-between',
                        }}
                      >
                        <TextField
                          label={t('job_management.box_setting.box_modal.menu')}
                          select
                          fullWidth
                          {...register(`pack_id`)}
                          value={findPackId || ''}
                          defaultValue={findPackId || ''}
                          onChange={(e: any) => {
                            field.onChange(e.target.value)
                            setPackId(e.target.value)
                            setFindPackId(e.target.value)
                            getListPack(e.target.value)
                          }}
                          style={{
                            width: '240px',
                          }}
                          sx={{ height: '100' }}
                          InputProps={{
                            endAdornment: (
                              <Box
                                tabIndex={0}
                                role="button"
                                style={{
                                  marginRight: '20px',
                                  cursor: 'pointer',
                                }}
                                onClick={() => {
                                  setFindPackId('')
                                  setPackId('')
                                  setPackItem('')
                                  setSubscriptionFlag(true)
                                }}
                                onKeyDown={() => {
                                  setFindPackId('')
                                  setPackId('')
                                  setPackItem('')
                                  setSubscriptionFlag(true)
                                }}
                              >
                                <CloseIcon />
                              </Box>
                            ),
                          }}
                        >
                          {Array.isArray(boxSettingEdit?.packs) &&
                            boxSettingEdit?.packs.map((item: any) => (
                              <MenuItem
                                sx={{ height: 36 }}
                                key={item.id}
                                value={item.id}
                              >
                                {item.title}
                              </MenuItem>
                            ))}
                        </TextField>
                        <TextField
                          label={t(
                            'job_management.box_setting.box_modal.sub_menu'
                          )}
                          select
                          fullWidth
                          {...register(`pack_item_id`)}
                          value={packItem}
                          disabled={!packId && subscriptionFlag}
                          style={{ width: '240px' }}
                          onChange={(e: any) => {
                            field.onChange(e.target.value)
                            setPackItem(e.target.value)
                          }}
                          sx={{ height: '100' }}
                          InputProps={{
                            endAdornment: (
                              <Box
                                tabIndex={0}
                                role="button"
                                style={{
                                  marginRight: '20px',
                                  cursor: 'pointer',
                                }}
                                onClick={() => {
                                  setPackItem('')
                                  setSubscriptionFlag(true)
                                }}
                                onKeyDown={() => {
                                  setPackItem('')
                                  setSubscriptionFlag(true)
                                }}
                              >
                                <CloseIcon />
                              </Box>
                            ),
                          }}
                        >
                          {Array.isArray(listPackItems) &&
                            listPackItems.map((item: any) => (
                              <MenuItem
                                sx={{ height: 36 }}
                                key={item.id}
                                value={item.id}
                              >
                                {item.title}
                              </MenuItem>
                            ))}
                        </TextField>
                      </div>
                    )}
                  />
                </Stack>
              </Stack>
            </Stack>
          ) : (
            // eslint-disable-next-line react/jsx-no-useless-fragment
            <></>
          )}
          <Stack
            direction="column"
            spacing={2}
            sx={{
              maxHeight: boxSettingEdit.is_dropdown ? '210px' : '400px',
              position: 'relative',
            }}
            mt={2}
          >
            <Typography variant="body2" fontWeight="bold">
              {t('job_management.box_setting.box_modal.schedule')}
            </Typography>
            <Divider />
            <Stack
              direction="row"
              justifyContent="space-around"
              sx={{ overflowY: 'scroll', minHeight: '30px' }}
            >
              <Stack direction="column" spacing={2}>
                {renderServiceList}
              </Stack>
              <Box sx={{ position: 'absolute', bottom: 0, right: 20 }}>
                {!dayjs(date).isBefore(dayjs().subtract(1, 'd')) && (
                  <IconButton
                    onClick={() => {
                      const idGenerate = Math.floor(Math.random() * 1001)
                      appendService({
                        id: idGenerate,
                        time_id: idGenerate,
                        date_time: '',
                        reservations: 0,
                      })
                      dispatch(
                        ADD_SERVICE_TIME({
                          id: idGenerate,
                          time_id: idGenerate,
                          date_time: '',
                          reservations: 0,
                          pack_id: packId,
                          pack_item_id: packItem,
                        })
                      )
                    }}
                  >
                    <AddTask color="primary" />
                  </IconButton>
                )}
              </Box>
            </Stack>
          </Stack>
          <Stack direction="column" spacing={2}>
            <Typography variant="body2" fontWeight="bold">
              {t('job_management.box_setting.box_modal.transfer')}
            </Typography>
            <Divider />
            <Stack direction="row" spacing={3} alignItems="center">
              <Stack width="250px" direction="row" alignItems="center">
                <Controller
                  name="loop_type"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      label={`${t(
                        'job_management.box_setting.box_modal.loop_type_label'
                      )}`}
                      select
                      fullWidth
                      {...register(`loop_type`)}
                      value={field.value}
                      disabled={dayjs(date).isBefore(dayjs().subtract(1, 'd'))}
                      onChange={(e: any) => {
                        setLoopType(e.target.value)
                        field.onChange(e.target.value)
                        if (
                          e.target.value === LOOP_TYPE.WEEKLY ||
                          e.target.value === LOOP_TYPE.MONTHLY
                        ) {
                          setValue(
                            'loop_start_date',
                            dayjs(date).format(DATE_STRING)
                          )
                          setCheckDate({
                            ...checkDate,
                            startDate: date,
                          })
                        } else if (e.target.value === LOOP_TYPE.NONE) {
                          clearErrors(['loop_start_date', 'loop_end_date'])
                        }
                      }}
                      sx={{ height: '100' }}
                    >
                      {Array.isArray(loopTypeArray) &&
                        loopTypeArray.map((item: any) => (
                          <MenuItem
                            sx={{ height: 36 }}
                            key={item.value}
                            value={item.value}
                          >
                            {item.label}
                          </MenuItem>
                        ))}
                    </TextField>
                  )}
                />
              </Stack>
              <LocalizationProvider
                dateAdapter={AdapterDateFns}
                locale={DEFAULT_LANGUAGE === languageMaster ? ja : enUS}
              >
                <Controller
                  name="loop_start_date"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <DatePicker
                      {...field}
                      disabled={
                        loopType !== LOOP_TYPE.DAILY ||
                        dayjs(date).isBefore(dayjs().subtract(1, 'd'))
                      }
                      views={['year', 'month', 'day']}
                      label={t('job_management.box_setting.box_modal.from')}
                      minDate={date}
                      maxDate={
                        checkDate.endDate !== '' ? checkDate.endDate : null
                      }
                      value={field.value}
                      onChange={newValue => {
                        field.onChange(
                          dayjs(newValue).isValid() ? newValue : field.value
                        )
                        setCheckDate({
                          ...checkDate,
                          startDate: newValue,
                        })
                      }}
                      allowSameDateSelection
                      renderInput={param => (
                        <TextField
                          {...param}
                          error={!!error}
                          helperText={error?.message}
                          onKeyDown={(e: any) => e.preventDefault()}
                        />
                      )}
                      mask={DATE_PICKER_INPUT_YMD}
                    />
                  )}
                />
                <Controller
                  name="loop_end_date"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <DatePicker
                      {...field}
                      views={['year', 'month', 'day']}
                      disabled={
                        !loopType ||
                        dayjs(date).isBefore(dayjs().subtract(1, 'd'))
                      }
                      label={t('job_management.box_setting.box_modal.to')}
                      value={field.value}
                      minDate={checkDate.startDate || date}
                      onChange={newValue => {
                        field.onChange(
                          dayjs(newValue).isValid() ? newValue : ''
                        )
                        setCheckDate({
                          ...checkDate,
                          endDate: newValue,
                        })
                      }}
                      allowSameDateSelection
                      renderInput={param => (
                        <TextField
                          {...param}
                          error={!!error}
                          helperText={error?.message}
                          onKeyDown={(e: any) => e.preventDefault()}
                        />
                      )}
                      mask={DATE_PICKER_INPUT_YMD}
                    />
                  )}
                />
              </LocalizationProvider>
            </Stack>
          </Stack>
          {!dayjs(date).isBefore(dayjs().subtract(1, 'd')) && (
            <Stack>
              <Button variant="contained" type="submit">
                {t('job_management.box_setting.box_modal.application')}
              </Button>
            </Stack>
          )}
        </Stack>
      </Box>
    </Modal>
  )
}

export default React.memo(BoxSettingModal)
