import React from 'react'
import { Stack, Dialog, DialogTitle, Button } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { ConfirmDeleteDialogProps } from '@/interfaces'

function ConfirmDialog({
  openDialog,
  setOpenDialog,
  onConfirmDialog,
  onAsyncConfirm,
}: ConfirmDeleteDialogProps) {
  const { t } = useTranslation()

  return (
    <Dialog
      className="dialog-container"
      onClose={() => setOpenDialog(!openDialog)}
      open={openDialog}
      maxWidth="sm"
      fullWidth
      sx={{
        padding: 3,
      }}
    >
      <Stack direction="column" spacing={5} alignItems="center" p={3}>
        <Stack direction="row" alignItems="center" spacing={2}>
          <DialogTitle sx={{ padding: 0, fontWeight: 'bold', fontSize: 24 }}>
            {t('common.delete_content')}
          </DialogTitle>
        </Stack>
        <Stack direction="row" spacing={4} width="60%">
          <Button
            fullWidth
            variant="contained"
            color="error"
            sx={{
              fontWeight: 600,
              fontSize: 16,
              height: 36,
              whiteSpace: 'nowrap',
            }}
            onClick={() => setOpenDialog(!openDialog)}
          >
            {t('common.cancel')}
          </Button>
          <Button
            variant="outlined"
            sx={{
              fontWeight: 600,
              fontSize: 16,
              height: 36,
              minWidth: 120,
            }}
            onClick={() => {
              if (onConfirmDialog) {
                onConfirmDialog()
              }
              if (onAsyncConfirm) {
                onAsyncConfirm()
              }
            }}
          >
            {t('common.ok')}
          </Button>
        </Stack>
      </Stack>
    </Dialog>
  )
}

export default React.memo(ConfirmDialog)
