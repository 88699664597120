import '@/assets/styles/Scheduler.scss'
import { DATE } from '@/constants'
import styled from '@emotion/styled'
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import { CalendarPicker, LocalizationProvider } from '@mui/lab'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import { Box, Button, IconButton, Popover } from '@mui/material'
import moment from 'moment'
import 'moment/locale/ja'
import * as React from 'react'
import { Navigate as navigate } from 'react-big-calendar'
import 'react-big-calendar/lib/css/react-big-calendar.css'

const IconNext = styled(ArrowForwardIosIcon)`
  color: #1976d2;
  font-size: 17px;
`
const IconPrev = styled(ArrowBackIosNewIcon)`
  color: #1976d2;
  font-size: 17px;
`

const ButtonIcon = styled(IconButton)`
  padding: 15px;
`

function CalendarToolbar({ onNavigate, date, view }: any) {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }
  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined
  const locale = moment.locale()
  let start: string
  let end: string
  const getTime = (): string => {
    switch (view) {
      case 'month':
        return new Date(date).toLocaleDateString(locale, {
          year: 'numeric',
          month: 'long',
        })
      case 'week':
        start =
          locale === 'en'
            ? moment(date).startOf('week').format('DD')
            : moment(date).startOf('week').format('DD日')
        end =
          locale === 'en'
            ? moment(date).endOf('week').format('DD')
            : moment(date).endOf('week').format('DD日')
        return `${start}- ${end} ${new Date(date).toLocaleDateString(locale, {
          year: 'numeric',
          month: 'long',
        })}`
      default:
        return new Date(date).toLocaleDateString(locale, {
          year: 'numeric',
          month: 'long',
          day: 'numeric',
        })
    }
  }

  return (
    <Box pb={1}>
      <ButtonIcon type="button" onClick={() => onNavigate(navigate.PREVIOUS)}>
        <IconPrev />
      </ButtonIcon>
      <ButtonIcon type="button" onClick={() => onNavigate(navigate.NEXT)}>
        <IconNext />
      </ButtonIcon>
      <Button aria-describedby={id} onClick={handleClick}>
        {getTime()}
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <CalendarPicker
            date={date}
            onChange={(newDate: any) => {
              onNavigate(DATE, newDate)
              if (date !== newDate) {
                handleClose()
              }
            }}
            allowSameDateSelection
          />
        </LocalizationProvider>
      </Popover>
    </Box>
  )
}
export default CalendarToolbar
