import React from 'react'
import { Stack, Dialog, DialogTitle, Button, DialogContent, Box } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { Table } from 'antd'
import { ColumnsType } from 'antd/lib/table'

interface ModalProps {
  isOpen: boolean
  toggleModal(open: any): void
  dataSource: any[]
}

function DeleteServiceResultModal({
  isOpen,
  toggleModal,
  dataSource
}: ModalProps) {
  const { t } = useTranslation()

  const columns: ColumnsType<any> = [
    {
      title: t('job_management.service.service_delete.col_box_id'),
      width: '20%',
      dataIndex: 'box_id',
      key: 'box_id',
    },
    {
      title: t('job_management.service.service_delete.col_box_name'),
      width: '60%',
      dataIndex: 'nickname',
      key: 'nickname',
    },
    {
      title: t('job_management.service.service_delete.col_box_time'),
      width: '20%',
      dataIndex: 'date_time',
      key: 'date_time',
    }
  ]

  return (
    <Dialog
      className="dialog-container"
      onClose={() => toggleModal(!isOpen)}
      open={isOpen}
      maxWidth="sm"
      fullWidth
      sx={{
        padding: 3,
        '.MuiPaper-root.MuiDialog-paper': {
          maxWidth: '700px'
        }
      }}
    >
      <Stack direction="column" spacing={5} alignItems="center" p={3}>
        <Stack direction="row" alignItems="center" spacing={2}>
          <DialogTitle sx={{ padding: 0, fontWeight: 'bold', fontSize: 24 }}>
            {t('job_management.service.service_delete.result_title')}
          </DialogTitle>
        </Stack>
        <Stack direction="row" spacing={2} sx={{ width: '100%', height: '400px' }}>
          <DialogContent sx={{ overflowX: 'hidden' }}>
            <Box width="100%">
              <Table
                columns={columns}
                dataSource={dataSource}
                pagination={false}
              />
            </Box>
          </DialogContent>
        </Stack>
        <Stack direction="row" spacing={4}>
          <Button
            variant='contained'
            color='info'
            size='small'
            sx={{
              fontWeight: 600,
              fontSize: 16,
              height: 36,
              whiteSpace: 'nowrap',
            }}
            onClick={() => toggleModal(!isOpen)}
          >
            OK
          </Button>
        </Stack>
      </Stack>
    </Dialog>
  )
}

export default React.memo(DeleteServiceResultModal)
