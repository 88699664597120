import { all, takeLatest, put, call, ForkEffect } from 'redux-saga/effects'
import {
  apiUserSignIn,
  apiUserRegister,
  apiForgotPassword,
  apiResetPassword,
  apiSignOut,
} from '@/api/auth'
import { apiGetMe } from '@/api/me'
import { AxiosResponse } from 'axios'
import { PayloadAction } from '@reduxjs/toolkit'
import {
  AuthRegister,
  AuthSignIn,
  AuthForgotPassword,
  AuthResetPassword,
} from '@/interfaces'
import {
  setUserToken,
  removeUserToken,
  handleError,
} from '@/utils'
import {
  AUTH_USER_LOGIN,
  AUTH_SET_CURRENT_USER,
  AUTH_USER_REGISTER,
  AUTH_USER_FORGOT_PASSWORD,
  AUTH_USER_RESET_PASSWORD,
  AUTH_GET_INFO,
  AUTH_LOADING,
  AUTH_LOGOUT,
} from '@/redux/reducers/auth.slice'
import {
  SET_LOADING,
  SET_MESSAGE,
  RESET_MESSAGE,
} from '@/redux/reducers/app.slice'

function* signIn(action: PayloadAction<AuthSignIn>) {
  try {
    yield put(SET_LOADING(true))
    yield put(RESET_MESSAGE())
    const { email, password, isRemember } = action.payload
    const response: AxiosResponse = yield apiUserSignIn({ email, password })
    const { token } = response.data
    setUserToken(token, isRemember)
    const userResponse: AxiosResponse = yield apiGetMe()
    yield put(AUTH_SET_CURRENT_USER(userResponse.data))
  } catch (error) {
    yield put(SET_MESSAGE(handleError(error)))
  } finally {
    yield put(SET_LOADING(false))
  }
}

function* signUp(action: PayloadAction<AuthRegister>) {
  try {
    yield put(SET_LOADING(true))
    const { payload } = action
    yield apiUserRegister(payload)
    yield put(
      SET_MESSAGE({
        type: 'success',
        content: '',
      })
    )
  } catch (error) {
    yield put(SET_MESSAGE(handleError(error)))
  } finally {
    yield put(SET_LOADING(false))
  }
}

function* forgotPassword(action: PayloadAction<AuthForgotPassword>) {
  try {
    yield put(SET_LOADING(true))
    const { payload } = action
    yield apiForgotPassword(payload)
    yield put(
      SET_MESSAGE({
        type: 'success',
        content: '',
      })
    )
  } catch (error) {
    yield put(SET_MESSAGE(handleError(error)))
  } finally {
    yield put(SET_LOADING(false))
  }
}

function* resetPassword(action: PayloadAction<AuthResetPassword>) {
  try {
    yield put(SET_LOADING(true))
    const { payload } = action
    yield apiResetPassword(payload)
    yield put(
      SET_MESSAGE({
        type: 'success',
        content: '',
      })
    )
  } catch (error) {
    yield put(SET_MESSAGE(handleError(error)))
  } finally {
    yield put(SET_LOADING(false))
  }
}

function* getInfo() {
  yield put(AUTH_LOADING(true))
  try {
    const response: AxiosResponse = yield call(apiGetMe)
    yield put(AUTH_SET_CURRENT_USER(response.data))
  } catch (error) {
    // AxiosError
    yield put(AUTH_SET_CURRENT_USER({}))
    yield put(SET_MESSAGE(handleError(error)))
    removeUserToken()
  } finally {
    yield put(AUTH_LOADING(false))
  }
}

function* logout() {
  try {
    yield put(SET_LOADING(true))
    yield put(RESET_MESSAGE())
    yield call(apiSignOut)
    removeUserToken()
  } catch (error) {
    // AxiosError
    // TODO: update error message from api
    yield put(SET_MESSAGE(handleError(error)))
  } finally {
    yield put(SET_LOADING(false))
  }
}

export default function* authSaga() {
  const filteredSagas: ForkEffect[] = [
    takeLatest(AUTH_USER_LOGIN, signIn),
    takeLatest(AUTH_USER_REGISTER, signUp),
    takeLatest(AUTH_USER_FORGOT_PASSWORD, forgotPassword),
    takeLatest(AUTH_USER_RESET_PASSWORD, resetPassword),
    takeLatest(AUTH_GET_INFO, getInfo),
    takeLatest(AUTH_LOGOUT, logout),
  ]

  yield all(filteredSagas)
}
