import '@/assets/styles/Scheduler.scss'
import { CALENDAR_EXTRA_HEIGHT, DAY } from '@/constants'
import useWindowDimensions from '@/hooks/useWindowDimensions'
import { ScheduleProps } from '@/interfaces'
import { AppointmentModel } from '@devexpress/dx-react-scheduler'
import styled from '@emotion/styled'
import AccessTimeIcon from '@mui/icons-material/AccessTime'
import Brightness1Icon from '@mui/icons-material/Brightness1'
import DeleteIcon from '@mui/icons-material/Delete'
import { Box, IconButton, Popover, Stack, Typography } from '@mui/material'
import moment from 'moment'
import 'moment/locale/ja'
import * as React from 'react'
import { Calendar, momentLocalizer } from 'react-big-calendar'
import 'react-big-calendar/lib/css/react-big-calendar.css'
import CalendarToolbar from './CalendarToolbar'
import ConfirmDeleteServiceModal from './ConfirmDeleteServiceModal'

const PopoverItem = styled(Popover)`
  .MuiPaper-root {
    padding: 5px 20px 20px;
    width: 400px;
  }
`
const ButtonIcon = styled(IconButton)`
  padding: 15px;
`

function Schedule({
  dataProps,
  typeTime,
  changeTypeTime,
  languageCalendar,
}: ScheduleProps) {
  const dataAppointment: Array<AppointmentModel> = dataProps
  const [appointmentData, setAppointmentData] = React.useState<
    Array<AppointmentModel>
  >([])
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)
  const [isOpen, setIsOpen] = React.useState<boolean>(false)
  const [eventData, setEventData] = React.useState<any>(null)
  const [isClickSlot, setIsClickSlot] = React.useState<boolean>(false)
  const [date, setDate] = React.useState<any>(new Date())
  const [language, setLanguage] = React.useState<string>('')
  const open = Boolean(anchorEl)
  const [serviceId, setServiceId] = React.useState('')
  const [visible, setVisible] = React.useState<boolean>(false)

  const handleClose = () => {
    setAnchorEl(null)
  }

  const calendarHeight = () => {
    const { height } = useWindowDimensions()
    if (height === 969) {
      return height - CALENDAR_EXTRA_HEIGHT
    }
    return 680
  }

  moment.locale(languageCalendar)
  const localizer = momentLocalizer(moment)
  React.useEffect(() => {
    if (languageCalendar === 'ja-JP') {
      setLanguage('もっと見る')
    } else {
      setLanguage('more')
    }
  }, [languageCalendar])

  React.useEffect(() => {
    const data: AppointmentModel[] = dataAppointment.map(item => ({
      ...item,
      startDate: new Date(item.startDate),
      endDate: new Date(item.endDate as any),
    }))
    setAppointmentData(data)
  }, [dataAppointment])

  React.useEffect(() => {
    if (isClickSlot) {
      setIsClickSlot(!isClickSlot)
    } else {
      setDate(new Date())
    }
  }, [typeTime])

  const deleteStatus = (data: any) => {
    if (data) {
      setVisible(!visible)
    }
  }

  const handleDeleteClick = (id: React.SetStateAction<string>) => {
    setServiceId(id)
    setIsOpen(!isOpen)
    setAnchorEl(null)
  }

  const handleDrillDown = React.useCallback((drillDate: any) => {
    setDate(new Date(drillDate))
    changeTypeTime(DAY)
    setIsClickSlot(true)
  }, [])

  const handleSelectSlot = React.useCallback((slotInfo: any) => {
    setDate(slotInfo.start)
    changeTypeTime(DAY)
    setIsClickSlot(true)
  }, [])

  const Event = React.useCallback(
    ({ title, event }: any) => (
      <>
        {typeTime === 'month' && moment(event.startDate).format('LT')}: {title}
      </>
    ),
    [typeTime]
  )

  function checkPeople(even: any) {
    const substringEN = ' Participants: 0 people'
    const substringJA = '参加者: 0 人'
    if (even.title.includes(substringEN) || even.title.includes(substringJA)) {
      return true
    }
    return false
  }

  const eventStyleGetter = (event: any) => ({ style: { backgroundColor: event?.count > 0 ? `#FF8D85` : `#64b5f6` }})

  return (
    <Box
      sx={{
        padding: '0rem 2rem',
        height: calendarHeight(),
        width: 'auto',
      }}
    >
      <Calendar
        popup
        date={date}
        localizer={localizer}
        className="big-calendar"
        startAccessor="startDate"
        style={{ display: 'flex', justifyContent: 'center' }}
        endAccessor="endDate"
        messages={{
          showMore: function showMore(total) {
            return `+${total} ${language}`
          },
        }}
        defaultDate={new Date()}
        events={appointmentData}
        view={typeTime}
        onRangeChange={(range: any) => {
          setDate(range[0])
        }}
        step={15}
        onSelectEvent={(event, e: any) => {
          setEventData(event)
          setAnchorEl(e.target)
        }}
        onDrillDown={handleDrillDown}
        onSelectSlot={handleSelectSlot}
        selectable
        // Custom components
        components={{
          toolbar: CalendarToolbar,
          event: Event,
        }}
        eventPropGetter={eventStyleGetter}
      />
      {eventData && (
        <PopoverItem
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
        >
          <Stack alignItems="flex-end">
            {checkPeople(eventData) ? (
              <ButtonIcon onClick={() => handleDeleteClick(eventData.id)}>
                <DeleteIcon />
              </ButtonIcon>
            ) : (
              <Stack sx={{ mt: 6 }} />
            )}
          </Stack>
          <Stack
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
            gap={1}
          >
            <Brightness1Icon
              sx={eventData.count > 0 ? {
                fontSize: '40px',
                width: '50px',
                color: '#FF8D85',
              } : {
                fontSize: '40px',
                width: '50px',
                color: '#64b5f6',
              }}
            />
            <Stack>
              <Typography fontSize={18} color="#666666" fontWeight="bold">
                {eventData.title}
              </Typography>
              <Typography fontSize={14} pb={2}>
                {moment(eventData.startDate).format('YYYY年MMMMDodddd')}
              </Typography>
            </Stack>
          </Stack>
          <Stack direction="row" alignItems="center" gap={1}>
            <AccessTimeIcon sx={{ width: '50px' }} />
            <Typography fontSize={12}>
              {moment(eventData.startDate).format('LT')}
              {' - '}
              {moment(eventData.endDate).format('LT')}
            </Typography>
          </Stack>
        </PopoverItem>
      )}
      {/* Confirm Delete Service Modal */}
      <ConfirmDeleteServiceModal
        isOpen={isOpen}
        toggleModal={setIsOpen}
        serviceId={serviceId}
        deleteStatus={deleteStatus}
        setTooltipVisible={() => null}
      />
    </Box>
  )
}

export default React.memo(Schedule)
