import React, { useState } from 'react'
import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm, Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import {
  Box,
  Stack,
  TextField,
  Button,
  InputAdornment,
  IconButton,
} from '@mui/material'
import { Visibility, VisibilityOff } from '@mui/icons-material'
import { useAppDispatch } from '@/redux/hooks'
import { IChangePassword } from '@/interfaces'
import { PASSWORD_REGEX } from '@/constants'
import {
  RESET_MESSAGE,
  SET_LOADING,
  SET_MESSAGE,
} from '@/redux/reducers/app.slice'
import { handleError } from '@/utils'
import { apiChangePassword } from '@/api/information'
import { AUTH_LOGOUT } from '@/redux/reducers/auth.slice'
import ConfirmDialog from '../common/ConfirmDialog'

function ChangePassword() {
  const [toggleDialog, setToggleDialog] = useState<boolean>(false)
  const [confirmData, setConfirmData] = useState<any>({})
  const [showPassword, setShowPassword] = useState({
    currentPassword: false,
    newPassword: false,
    confirmNewPassword: false,
  })
  const handleShowCurrentPassword = () =>
    setShowPassword({
      ...showPassword,
      currentPassword: !showPassword.currentPassword,
    })
  const handleShowNewPassword = () =>
    setShowPassword({
      ...showPassword,
      newPassword: !showPassword.newPassword,
    })
  const handleShowConfirmNewPassword = () =>
    setShowPassword({
      ...showPassword,
      confirmNewPassword: !showPassword.confirmNewPassword,
    })
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  // Submit data change password
  const handleChangePassword = async (data: any) => {
    try {
      dispatch(SET_LOADING(true))
      dispatch(RESET_MESSAGE())
      await apiChangePassword(data)
      dispatch(
        SET_MESSAGE({
          type: 'success',
          content: t(
            'information_edit.change_password_panel.change_password_success'
          ),
        })
      )
      dispatch(AUTH_LOGOUT())
      setToggleDialog(false)
    } catch (error) {
      dispatch(SET_MESSAGE(handleError(error)))
    } finally {
      dispatch(SET_LOADING(false))
    }
  }

  const onSubmit = (data: IChangePassword) => {
    setConfirmData(data)
    setToggleDialog(true)
  }

  // Check validation field
  const Schema = Yup.object().shape({
    current_password: Yup.string()
      .trim()
      .required(t('information_edit.validation.password_require'))
      .matches(
        PASSWORD_REGEX,
        t('information_edit.validation.new_password_invalid')
      ),
    new_password: Yup.string()
      .trim()
      .required(t('information_edit.validation.new_password_require'))
      .matches(
        PASSWORD_REGEX,
        t('information_edit.validation.new_password_invalid')
      ),
    confirm_new_password: Yup.string()
      .trim()
      .oneOf(
        [Yup.ref('new_password'), null],
        t('information_edit.validation.confirm_password_invalid')
      )
      .required(t('information_edit.validation.confirm_password_require')),
  })
  const defaultValues = {
    current_password: '',
    new_password: '',
    confirm_new_password: '',
  }
  const { handleSubmit, control } = useForm({
    resolver: yupResolver(Schema),
    defaultValues,
    mode: 'onTouched',
  })

  return (
    <Box component="form" onSubmit={handleSubmit(onSubmit)}>
      <Stack direction="column" spacing={3}>
        <Stack maxWidth="50%">
          <Controller
            name="current_password"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                type={showPassword.currentPassword ? 'text' : 'password'}
                id="currentPassword"
                label={t('information_edit.change_password_panel.old_password')}
                placeholder={t(
                  'information_edit.validation.password_placeholder'
                )}
                error={!!error}
                helperText={error?.message}
                autoComplete="off"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={handleShowCurrentPassword}>
                        {showPassword.currentPassword ? (
                          <Visibility />
                        ) : (
                          <VisibilityOff />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            )}
          />
        </Stack>
        <Stack maxWidth="50%">
          <Controller
            name="new_password"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                type={showPassword.newPassword ? 'text' : 'password'}
                id="new_password"
                label={t('information_edit.change_password_panel.new_password')}
                placeholder={t(
                  'information_edit.validation.new_password_placeholder'
                )}
                error={!!error}
                helperText={error?.message}
                autoComplete="off"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={handleShowNewPassword}>
                        {showPassword.newPassword ? (
                          <Visibility />
                        ) : (
                          <VisibilityOff />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            )}
          />
        </Stack>
        <Stack maxWidth="50%">
          <Controller
            name="confirm_new_password"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                type={showPassword.confirmNewPassword ? 'text' : 'password'}
                id="confirm_new_password"
                label={t(
                  'information_edit.change_password_panel.confirm_password'
                )}
                placeholder={t(
                  'information_edit.validation.confirm_password_placeholder'
                )}
                error={!!error}
                helperText={error?.message}
                autoComplete="off"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={handleShowConfirmNewPassword}>
                        {showPassword.confirmNewPassword ? (
                          <Visibility />
                        ) : (
                          <VisibilityOff />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            )}
          />
        </Stack>
        <Button type="submit" variant="contained">
          {t('information_edit.submit_change_button')}
        </Button>
        <ConfirmDialog
          openDialog={toggleDialog}
          setOpenDialog={setToggleDialog}
          onConfirmDialog={() => {}}
          onAsyncConfirm={() => handleChangePassword(confirmData)}
          dialogContent={t(
            'information_edit.change_password_panel.dialog_content'
          )}
          okText={t('information_edit.change_password_panel.ok')}
          cancelText={t('information_edit.change_password_panel.cancel')}
        />
      </Stack>
    </Box>
  )
}

export default React.memo(ChangePassword)
