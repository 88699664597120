import { PagingProduct, ProductResponse, ProductState } from '@/interfaces'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

const initialState: ProductState = {
  productList: [],
  isLoading: false,
  meta: {},
}

export const productSlice = createSlice({
  name: 'product',
  initialState,
  reducers: {
    FETCH_PRODUCTS: (state, action: PayloadAction<PagingProduct>) => {
      state.isLoading = true
    },
    SET_PRODUCT: (state, action: PayloadAction<ProductResponse>) => {
      const { items, meta } = action.payload
      state.meta.total = meta.total
      state.meta.page = meta.page
      state.isLoading = false
      state.productList = items
    },
    SET_ERROR_MESSAGE: state => {
      state.isLoading = false
    },
    EDIT_PRODUCT: (
      state,
      action: PayloadAction<{
        name: string
        price: number
        callback(): void
      }>
    ) => {},
  },
})

// Actions
export const { FETCH_PRODUCTS, SET_PRODUCT, SET_ERROR_MESSAGE } =
  productSlice.actions

// Selectors
export const productList = (state: ProductState) => state.productList

// Reducer
const productReducer = productSlice.reducer

export default productReducer
