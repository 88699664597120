import '@/assets/styles/layout/header.scss'
import React from 'react'
import logo from '@/assets/images/logo.png'
import { Box, Stack, AppBar, Toolbar, Divider } from '@mui/material'
import Notifications from '@components/common/Notifications'
import AccountProfile from '@components/common/AccountProfile'
import Language from '@/components/common/Language'

function Header() {
  return (
    <AppBar className="header-container" color="inherit" elevation={0}>
      <Toolbar>
        <Box className="logo-wrapper">
          <Box
            className="logo-image"
            component="img"
            src={logo}
            alt="header-logo"
          />
        </Box>
        <Box sx={{ flexGrow: 1 }} />
        <Stack
          className="user-wrapper"
          direction="row"
          alignItems="center"
          spacing={{ xs: 0.5, sm: 1.5 }}
        >
          <Language />
          <Divider orientation="vertical" flexItem />
          <Notifications />
          <Divider orientation="vertical" flexItem />
          <AccountProfile />
        </Stack>
      </Toolbar>
    </AppBar>
  )
}

export default React.memo(Header)
