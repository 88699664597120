import React from 'react'
import {
  Grid,
  Button,
  Box,
  Stack,
  Typography,
  Modal,
  IconButton,
  Divider,
} from '@mui/material'
import { Close } from '@mui/icons-material'
import { useTranslation } from 'react-i18next'
import user from '@/assets/images/user.png'

interface ModalProps {
  isOpen: any
  setIsOpen(open: any): void
}

// Show model when sign up success
function SignUpSuccess({ isOpen, setIsOpen }: ModalProps) {
  const { t } = useTranslation()
  const handleToggleModal = () => setIsOpen({ ...isOpen, signUpSuccess: false })

  return (
    <Modal open={isOpen.signUpSuccess} onClose={handleToggleModal}>
      <Box className="modal-content" sx={{ minWidth: '400px' }}>
        <Grid container textAlign="center">
          <Grid item xs>
            <Typography className="title" textAlign="center">
              {t('auth.sign_up.sign_up_success')}
            </Typography>
          </Grid>
          <Grid item>
            <IconButton onClick={handleToggleModal} className="close-button">
              <Close />
            </IconButton>
          </Grid>
        </Grid>
        <Divider />
        <Stack
          direction="column"
          justifyContent="center"
          alignItems="center"
          spacing={2}
        >
          <Box className="logo-image" component="img" src={user} alt="" />
          <Typography variant="h6">
            {t('auth.sign_up.description_success')}
          </Typography>
          <Button
            variant="contained"
            onClick={() =>
              setIsOpen({ ...isOpen, signUpSuccess: false, signIn: true })
            }
          >
            {t('auth.sign_up.to_login')}
          </Button>
        </Stack>
      </Box>
    </Modal>
  )
}

export default React.memo(SignUpSuccess)
