import { INotice, INoticeParams, INoticeState } from '@/interfaces'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

const initialState: INoticeState = {
  notice: [],
  noticeList: {
    meta: {},
    data: [],
  },
}

export const noticeSlice = createSlice({
  name: 'notices',
  initialState,
  reducers: {
    FETCH_NOTICE: () => {},
    SET_NOTICE: (state, action: PayloadAction<INotice[]>) => {
      state.notice = action.payload
    },
    FETCH_NOTICE_LIST: (state, action: PayloadAction<INoticeParams>) => {},
    SET_NOTICE_LIST: (
      state,
      action: PayloadAction<{ meta: any; data: INotice[] }>
    ) => {
      state.noticeList = action.payload
    },
    SET_IS_READ: state => {
      state.notice.forEach(item => {
        item.seen = true
      })
    },
  },
})

// Actions
export const {
  FETCH_NOTICE,
  SET_NOTICE,
  FETCH_NOTICE_LIST,
  SET_NOTICE_LIST,
  SET_IS_READ,
} = noticeSlice.actions

// Reducer
const noticeReducer = noticeSlice.reducer

export default noticeReducer
