import '@/assets/styles/app.scss'
import React, { useEffect, useRef } from 'react'
import {
  Box,
  Divider,
  Stack,
  Paper,
  Button,
  Typography,
  TextField,
  Breadcrumbs,
} from '@mui/material'
import { IParticipant } from '@/interfaces'
import Table from 'antd/es/table'
import { useParams, Link } from 'react-router-dom'
import { Videocam, VideocamOff } from '@mui/icons-material'
import { useTranslation } from 'react-i18next'
import { useAppDispatch, useAppSelector } from '@/redux/hooks'
import {
  FETCH_BOOKING_DETAIL,
  FETCH_PARTICIPANT_LIST,
} from '@/redux/reducers/jobManagement.slice'
import { ColumnsType } from 'antd/lib/table/interface'
import { ROUTER_PATH, DEFAULT_LANGUAGE, URL_WEBSITE } from '@/constants'
import useMasterData from '@/hooks/useMasterData'
import { convertTimeToJapan, getValueMasterData } from '@/utils'
import Tooltip from 'antd/lib/tooltip'

function BookingDetail() {
  const category = getValueMasterData(useMasterData('freelance_training_type'))
  const service = getValueMasterData(useMasterData('freelance_service_type'))
  const duration = getValueMasterData(useMasterData('freelance_service_time'))
  const sex = getValueMasterData(useMasterData('sex_type'))
  const payType = getValueMasterData(useMasterData('pay_type'))
  const { currentUser } = useAppSelector(state => state.auth)
  const staffColumns: any[] = ['item_name', 'pack_price', 'trade_money', 'trade_shot', 'trade_pay_type']

  const tableRef = useRef<HTMLDivElement>(null)

  const getTableScrollY = () => {
    const extraHeight = 120 // pagination + padding
    const tableHeader = tableRef.current?.querySelector('.ant-table-header')
    const tableHeaderBottom = tableHeader?.getBoundingClientRect().bottom

    return `calc(100vh - ${tableHeaderBottom}px - ${extraHeight}px)`
  }

  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const { bookingDetail, participantList } = useAppSelector(
    state => state.jobManagement
  )
  const { isLoading, languageMaster } = useAppSelector(state => state.app)

  const getCurrencyFormat = (money: any) => money ? money.toLocaleString('ja-JP', {
      style: 'currency',
      currency: 'JPY',
    }) : money

  const renderTooltipTitle = (label1: any, value1: any, label2: any, value2: any) => (
    <Box>
      {label1}: {value1}
      <Divider/>
      {label2}: {value2}
    </Box>
  )

  const params = useParams()
  const participantColumns: ColumnsType<IParticipant> = [
    {
      title: t('job_management.booking.participant_name'),
      dataIndex: 'name',
      key: 'name',
      ellipsis: true,
      width: '12%',
    },
    {
      title: t('job_management.booking.sex'),
      dataIndex: 'sex',
      key: 'sex',
      render: (record: number) => (
        <span>
          {languageMaster === DEFAULT_LANGUAGE
            ? sex[record]?.title_jp
            : sex[record]?.title_en}
        </span>
      ),
      ellipsis: true,
      width: '6%',
    },
    {
      title: t('job_management.booking.birthday'),
      dataIndex: 'birthday',
      key: 'birthday',
      render: record => (
        <span>{convertTimeToJapan(record).format('YYYY/MM/DD')}</span>
      ),
      ellipsis: true,
      width: '11%',
    },
    {
      title: t('job_management.booking.phone_number'),
      dataIndex: 'account',
      key: 'account',
      ellipsis: true,
      width: '11%',
    },
    {
      title: t('job_management.booking.item_name'),
      dataIndex: 'item_name',
      key: 'item_name',
      ellipsis: true,
      width: '25%',
      render: (_, record) => (
        <Tooltip
          placement="topLeft"
          title={renderTooltipTitle(
            t('job_management.booking.item_name'),
            record.item_name,
            t('job_management.booking.price'),
            getCurrencyFormat(record.pack_price)
          )}
        >
        {t('job_management.booking.item_name')}: {record.item_name}
        <Divider sx={{borderColor: 'transparent'}}/>
        {t('job_management.booking.price')}: {getCurrencyFormat(record.pack_price)}
        </Tooltip>
      )
    },
    {
      title: t('job_management.booking.payment'),
      dataIndex: 'trade_shot',
      key: 'trade_shot',
      ellipsis: true,
      width: '17%',
      render: (_, record) => (
        <Tooltip
          placement="topLeft"
          title={renderTooltipTitle(
            t('job_management.booking.shot'),
            getCurrencyFormat(record.trade_shot),
            t('job_management.booking.money'),
            getCurrencyFormat(record.pay_money && record.trade_shot ? record.pay_money - record.trade_shot : 0)
          )}
        >
        {t('job_management.booking.shot')}: {getCurrencyFormat(record.trade_shot)}
        <Divider sx={{borderColor: 'transparent'}}/>
        {t('job_management.booking.money')}: {getCurrencyFormat(record.pay_money && record.trade_shot ? record.pay_money - record.trade_shot : 0)}
        </Tooltip>
      )
    },
    {
      title: t('job_management.booking.pay_type'),
      dataIndex: 'trade_pay_type',
      key: 'trade_pay_type',
      ellipsis: true,
      width: '10%',
      render: (record: number) => (
        <span>
          {languageMaster === DEFAULT_LANGUAGE
            ? payType[record]?.title_jp
            : payType[record]?.title_en}
        </span>
      ),
    },
    {
      title: t('job_management.booking.box_name'),
      dataIndex: 'box_name',
      key: 'box_name',
      ellipsis: true,
      width: '25%',
      render: (_, record) => (
        <Tooltip placement="topLeft" title={record.box_name}>
          {record.admin_id}, {record.box_name}
        </Tooltip>
      ),
    },
    {
      title: t('job_management.booking.prefecture'),
      dataIndex: 'area',
      key: 'area',
      ellipsis: true,
      width: '8%',
    },
    {
      title: t('job_management.booking.municipality'),
      dataIndex: 'address',
      key: 'address',
      ellipsis: true,
      width: '15%',
    },
  ]
  const dataSource = participantList.map((item:any) => ({
    ...item,
    key: item.reservation_id,
  }))

  const getCamera = (status: number) => {
    switch (status) {
      case 1:
        return (
          <Button variant="outlined" disabled>
            {t('job_management.booking.box_start')}
            <VideocamOff />
          </Button>
        )
      case 3:
        return (
          <Button
            variant="outlined"
            color="success"
            onClick={() =>
              window.open(
                URL_WEBSITE +
                  ROUTER_PATH.JOB_MANAGEMENT_TRAINING_NEW_TAB +
                  params.id,
                '_blank'
              )
            }
          >
            {t('job_management.booking.box_start')}
            <Videocam />
          </Button>
        )
      default:
        return ''
    }
  }

  useEffect(() => {
    if (params.id) {
      dispatch(FETCH_BOOKING_DETAIL(params.id))
      dispatch(FETCH_PARTICIPANT_LIST(params.id))
    }
  }, [params.id])

  return (
    <>
      <Breadcrumbs>
        <Link
          color="inherit"
          to={ROUTER_PATH.JOB_MANAGEMENT_BOOKING_LIST}
          className="breadcrumb-text breadcrumb-text-list"
        >
          {t('job_management.box_setting.link_1')}
        </Link>
        <Box>{t('job_management.booking.title')}</Box>
      </Breadcrumbs>
      <Box component={Paper}>
        <Typography variant="h6" p={2.5}>
          {t('job_management.booking.title')}
        </Typography>
        <Divider />
        <Stack direction="column" p={2.5} spacing={2}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="h6">
              {t('job_management.booking.service_information')}
            </Typography>
            {getCamera(bookingDetail.use_status)}
          </Stack>
          <Divider />
          <Stack direction={{ lg: 'row', md: 'column' }} spacing={2}>
            <Stack direction="row" width="50%">
              <TextField
                label={t('job_management.booking.service_name')}
                value={bookingDetail.name || ''}
                disabled
                InputLabelProps={{ shrink: true }}
              />
            </Stack>
            <Stack direction="row" spacing={1}>
              <TextField
                label={t('job_management.booking.category')}
                value={bookingDetail.category
                  .toString()
                  .split(',')
                  .map(item => {
                    if (languageMaster === DEFAULT_LANGUAGE) {
                      return category[item]?.title_jp
                    }
                    return category[item]?.title_en
                  })
                  .join(', ')}
                disabled
                InputLabelProps={{ shrink: true }}
              />
              <TextField
                label={t('job_management.booking.form')}
                value={
                  languageMaster === DEFAULT_LANGUAGE
                    ? service[bookingDetail.type]?.title_jp
                    : service[bookingDetail.type]?.title_en
                }
                disabled
                InputLabelProps={{ shrink: true }}
              />
              <TextField
                label={t('job_management.booking.service_time')}
                value={
                  languageMaster === DEFAULT_LANGUAGE
                    ? duration[bookingDetail.duration]?.title_jp
                    : duration[bookingDetail.duration]?.title_en
                }
                disabled
                InputLabelProps={{ shrink: true }}
              />
              <TextField
                label={t('job_management.booking.date_time_start')}
                value={convertTimeToJapan(
                  bookingDetail.start_time
                    ? bookingDetail.start_time.toString()
                    : ''
                ).format('YYYY/MM/DD HH:mm')}
                disabled
                InputLabelProps={{ shrink: true }}
              />
              <TextField
                label={t('job_management.booking.group_limit')}
                value={`${bookingDetail.group_limit} ${t(
                  'job_management.booking.people'
                )}`}
                disabled
                InputLabelProps={{ shrink: true }}
              />
            </Stack>
          </Stack>
          <Typography variant="h6">
            {t('job_management.booking.participant_information')}
          </Typography>
          <Divider />
          <Table
            ref={tableRef}
            scroll={currentUser.type === 1 ? { x: 1600, y: getTableScrollY() } : { y: getTableScrollY() }}
            loading={isLoading}
            columns={currentUser.type === 1 ? participantColumns : participantColumns.filter(column => !staffColumns.includes(column.key))}
            dataSource={dataSource}
            locale={{
              emptyText: t('common.no_data'),
              filterReset: t('common.reset'),
              filterConfirm: t('common.apply'),
              triggerAsc: t('common.sort_asc'),
              triggerDesc: t('common.sort_desc'),
              cancelSort: t('common.cancel_sort'),
            }}
          />
        </Stack>
      </Box>
    </>
  )
}

export default React.memo(BookingDetail)
