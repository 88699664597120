import React, { useState, useEffect } from 'react'
import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm, Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Box, Stack, TextField, Button, Typography } from '@mui/material'
import { DocumentScanner } from '@mui/icons-material'
import { DatePicker } from '@mui/lab'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import UploadImage from '@components/common/UploadImage'
import ConfirmDialog from '@components/common/ConfirmDialog'
import { useAppSelector, useAppDispatch } from '@/redux/hooks'
import { FETCH_MENTOR_VERIFICATION } from '@/redux/reducers/information.slice'
import dayjs from 'dayjs'
import SelectCommon from '@components/common/SelectCommon'
import {
  DATE_INPUT_FORMAT,
  DATE_PICKER_INPUT_YMD,
  DAYJS_FORMAT,
  DEFAULT_LANGUAGE,
  FORMAT_PHONE,
  FORMAT_POST_CODE,
} from '@/constants'
import ComponentLoading from '@components/common/ComponentLoading'
import {
  RESET_MESSAGE,
  SET_LOADING,
  SET_MESSAGE,
} from '@/redux/reducers/app.slice'
import { apiGetVerification, apiVerificationEdit } from '@/api/information'
import { handleError, renderLabel } from '@/utils'
import { enUS, ja } from 'date-fns/locale'

function Identification() {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const [openDialog, setOpenDialog] = useState<boolean>(false)
  const [confirmSubmit, setConfirmSubmit] = useState({})
  const { currentMentor, isLoading } = useAppSelector(
    state => state.information
  )
  const { languageMaster } = useAppSelector(state => state.app)
  const { verification } = currentMentor
  const onSubmit = (data: any) => {
    const customData = {
      username: data.username,
      username_kana: data.username_kana,
      birthday: dayjs(data.birthday).format(DAYJS_FORMAT),
      sex: data.sex,
      phone: data.phone,
      post_code: data.post_code,
      address: data.address,
      address_kana: data.address_kana,
      inden_pic_before_id: data.inden_pic_before_id,
      inden_pic_after_id: data.inden_pic_after_id,
    }
    setConfirmSubmit(customData)
    setOpenDialog(!openDialog)
  }

  // Submit data
  const handleConfirmSubmit = async () => {
    try {
      dispatch(SET_LOADING(true))
      dispatch(RESET_MESSAGE())
      await apiVerificationEdit(confirmSubmit)
      await apiGetVerification()
      setOpenDialog(!openDialog)
    } catch (error) {
      dispatch(SET_MESSAGE(handleError(error)))
    } finally {
      dispatch(SET_LOADING(false))
    }
  }

  // Check validation form
  const Schema = Yup.object().shape({
    username: Yup.string()
      .trim()
      .nullable(true)
      .required(t('information_edit.validation.username_require')),
    username_kana: Yup.string()
      .trim()
      .nullable(true)
      .required(t('information_edit.validation.username_kana_require')),
    birthday: Yup.string()
      .trim()
      .nullable(true)
      .required(t('information_edit.validation.birthday_required')),
    sex: Yup.number()
      .moreThan(0, t('information_edit.validation.sex_require'))
      .required(t('information_edit.validation.sex_require'))
      .nullable(true),
    address: Yup.string()
      .nullable(true)
      .required(t('information_edit.validation.address_require')),
    address_kana: Yup.string()
      .nullable(true)
      .required(t('information_edit.validation.address_kana_require')),
    post_code: Yup.string()
      .required(t('information_edit.validation.post_code_require'))
      .nullable(true)
      .matches(
        FORMAT_POST_CODE,
        t('information_edit.validation.post_code_format')
      ),
    phone: Yup.string()
      .max(13, t('validation.length'))
      .nullable(true)
      .required(t('information_edit.validation.phone_number_required'))
      .matches(
        FORMAT_PHONE,
        t('information_edit.validation.phone_number_format')
      ),
    inden_pic_before_id: Yup.number()
      .nullable(true)
      .moreThan(
        0,
        t('information_edit.verification_panel.front_document_required')
      )
      .required(
        t('information_edit.verification_panel.front_document_required')
      ),
    inden_pic_after_id: Yup.number()
      .nullable(true)
      .moreThan(
        0,
        t('information_edit.verification_panel.back_document_required')
      )
      .required(
        t('information_edit.verification_panel.back_document_required')
      ),
  })

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(Schema),
    defaultValues: verification,
    mode: 'all',
  })
  useEffect(() => {
    dispatch(FETCH_MENTOR_VERIFICATION())
  }, [])
  useEffect(() => {
    reset({ ...verification })
  }, [verification])

  return !isLoading.verification ? (
    <Box component="form" onSubmit={handleSubmit(onSubmit)}>
      <Stack direction="column" spacing={3}>
        <Typography variant="h6" fontWeight="bold">
          {t('information_edit.verification_panel.title_1')}
        </Typography>
        <Typography variant="h6" fontWeight="bold" mt={2}>
          {t('information_edit.verification_panel.title_2')}
        </Typography>
        <Controller
          name="username"
          control={control}
          render={({ field, fieldState: { error } }) => (
            <TextField
              {...field}
              id="username"
              label={renderLabel(
                t('information_edit.verification_panel.full_name')
              )}
              placeholder={t(
                'information_edit.validation.username_placeholder'
              )}
              error={!!error}
              helperText={error?.message}
              inputProps={{ maxLength: 25 }}
              className="text-field-require"
            />
          )}
        />
        <Controller
          name="username_kana"
          control={control}
          render={({ field, fieldState: { error } }) => (
            <TextField
              {...field}
              id="username_kana"
              label={renderLabel(
                t('information_edit.verification_panel.kana_name')
              )}
              placeholder={t(
                'information_edit.validation.username_kana_placeholder'
              )}
              error={!!error}
              helperText={error?.message}
              inputProps={{ maxLength: 25 }}
              className="text-field-require"
            />
          )}
        />
        <Stack direction="row" spacing={3}>
          <LocalizationProvider
            dateAdapter={AdapterDateFns}
            locale={DEFAULT_LANGUAGE === languageMaster ? ja : enUS}
          >
            <Controller
              name="birthday"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <DatePicker
                  views={['year', 'month', 'day']}
                  label={renderLabel(
                    t('information_edit.verification_panel.birthday')
                  )}
                  maxDate={new Date()}
                  value={field.value || ''}
                  onChange={newValue => {
                    if (newValue) field.onChange(newValue)
                    else field.onChange(field.value)
                  }}
                  allowSameDateSelection
                  inputFormat={DATE_INPUT_FORMAT}
                  openTo="year"
                  renderInput={params => (
                    <TextField
                      {...params}
                      className="text-field-require"
                      error={!!error}
                      helperText={error?.message}
                      onKeyDown={(e: any) => e.preventDefault()}
                    />
                  )}
                  mask={DATE_PICKER_INPUT_YMD}
                />
              )}
            />
          </LocalizationProvider>
          <Controller
            name="sex"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <SelectCommon
                defaultValueMaster={field.value?.toString()}
                api="sex_type"
                isRequire
                handleChange={field.onChange}
                label={renderLabel(
                  t('information_edit.verification_panel.gender')
                )}
                isError={!!error}
                errMessage={t('information_edit.validation.sex_require')}
                placeHolder={t(
                  'information_edit.profile_panel.select_placeholder'
                )}
              />
            )}
          />
        </Stack>
        <Stack direction="row" spacing={3}>
          <Controller
            name="phone"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                id="phone"
                label={renderLabel(
                  t('information_edit.verification_panel.phone_number')
                )}
                error={!!error}
                helperText={error?.message}
                className="text-field-require"
                placeholder={t(
                  'information_edit.validation.phone_number_require_place'
                )}
              />
            )}
          />
          <Controller
            name="post_code"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                id="post_code"
                label={renderLabel(
                  t('information_edit.verification_panel.post_code')
                )}
                error={!!error}
                helperText={error?.message}
                className="text-field-require"
                placeholder={t(
                  'information_edit.validation.post_code_placeholder'
                )}
              />
            )}
          />
        </Stack>
        <Controller
          name="address"
          control={control}
          render={({ field, fieldState: { error } }) => (
            <TextField
              {...field}
              id="address"
              label={renderLabel(
                t('information_edit.verification_panel.address')
              )}
              error={!!error}
              helperText={error?.message}
              className="text-field-require"
              inputProps={{ maxLength: 255 }}
              placeholder={t('information_edit.validation.address_placeholder')}
            />
          )}
        />
        <Controller
          name="address_kana"
          control={control}
          render={({ field, fieldState: { error } }) => (
            <TextField
              {...field}
              id="address_kana"
              label={renderLabel(
                t('information_edit.verification_panel.kana_address')
              )}
              error={!!error}
              helperText={error?.message}
              className="text-field-require"
              inputProps={{ maxLength: 255 }}
              placeholder={t('information_edit.validation.address_placeholder')}
            />
          )}
        />
        <Stack direction="row" spacing={4} maxWidth="60%" alignItems="center">
          <Typography
            variant="body2"
            color={errors.inden_pic_before_id ? 'error' : 'default'}
          >
            {renderLabel(
              t('information_edit.verification_panel.front_document')
            )}
          </Typography>
          <Controller
            name="inden_pic_before_id"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <UploadImage
                idUpload="inden_pic_before"
                type="MENTOR_INDEN_PIC_BEFORE"
                formType="edit"
                onChange={field.onChange}
                size={{ height: '60px', width: '60px' }}
                imageURL={verification.inden_pic_before || ''}
                isDelete
                maxSize={20}
                isRequired
                requiredMessage={error?.message}
              >
                <DocumentScanner />
              </UploadImage>
            )}
          />
        </Stack>
        <Stack direction="row" spacing={4} maxWidth="60%" alignItems="center">
          <Typography
            variant="body2"
            color={errors.inden_pic_after_id ? 'error' : 'default'}
          >
            {renderLabel(
              t('information_edit.verification_panel.back_document')
            )}
          </Typography>
          <Controller
            name="inden_pic_after_id"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <UploadImage
                idUpload="inden_pic_after"
                type="MENTOR_INDEN_PIC_AFTER"
                formType="edit"
                onChange={field.onChange}
                size={{ height: '60px', width: '60px' }}
                imageURL={verification.inden_pic_after || ''}
                isDelete
                maxSize={20}
                isRequired
                requiredMessage={error?.message}
              >
                <DocumentScanner />
              </UploadImage>
            )}
          />
        </Stack>
        <Button type="submit" variant="contained">
          {t('information_edit.submit_button')}
        </Button>
      </Stack>
      <ConfirmDialog
        openDialog={openDialog}
        setOpenDialog={setOpenDialog}
        dialogContent={t('information_edit.confirm')}
        onConfirmDialog={handleConfirmSubmit}
      />
    </Box>
  ) : (
    <ComponentLoading height="90vh" />
  )
}

export default React.memo(Identification)
