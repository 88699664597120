import client from '@/api/axiosClient'

export const apiGetServiceAndSchedule = () =>
  client.get('/fitness/mentors/personal-calendar')

export const apiGetProfile = () => client.get('/fitness/mentors/profile')

export const apiProfileEdit = (data: any) =>
  client.put('/fitness/mentors/profile', data)

export const apiDeleteSkill = (id: number) =>
  client.delete(`/fitness/skills/${id}`)

export const apiDeleteCareer = (id: number) =>
  client.delete(`/fitness/careers/${id}`)

export const apiGetVerification = () =>
  client.get('/fitness/mentors/verification-info')

export const apiVerificationEdit = (data: any) =>
  client.put('/fitness/mentors/verification-info', data)

export const apiGetBankAccount = () => client.get('/fitness/mentors/bank-info')

export const apiBankAccountEdit = (data: any) =>
  client.put('/fitness/mentors/bank-info', data)

export const apiChangePassword = (data: any) =>
  client.put('/fitness/mentors/change-password', data)
