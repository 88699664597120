import '@/assets/styles/pages/mentor.scss'
import React from 'react'
import {
  Tabs,
  Tab,
  Typography,
  Box,
  Table,
  TableBody,
  TableHead,
  TableCell,
  TableContainer,
  TableRow,
  Grid,
  TextField,
  Paper,
  IconButton,
} from '@mui/material'
import Pagination from '@components/common/Pagination'
import { SearchOutlined } from '@mui/icons-material'
import { useTranslation } from 'react-i18next'
import { TabPanelProps } from '@/interfaces'

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}

function Index() {
  const [value, setValue] = React.useState(0)
  const { t } = useTranslation()
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue)
  }

  return (
    <>
      <Typography variant="h4" mb={3} mt={1}>
        {t('admin.mentor.title')}
      </Typography>
      <Paper className="mentor-container">
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Grid container>
            <Grid item lg={9}>
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="caption table"
              >
                <Tab label={t('admin.mentor.tab.active')} {...a11yProps(0)} />
                <Tab label={t('admin.mentor.tab.apply')} {...a11yProps(1)} />
              </Tabs>
            </Grid>
            <Grid item lg={3}>
              <TextField
                className="search-input"
                size="small"
                fullWidth
                variant="outlined"
                placeholder={t('header.search_placeholder')}
                InputProps={{
                  startAdornment: (
                    <IconButton>
                      <SearchOutlined />
                    </IconButton>
                  ),
                }}
              />
            </Grid>
          </Grid>
        </Box>
        {/* Item one */}
        <TabPanel value={value} index={0}>
          <TableContainer component={Paper} className="table-container">
            <Table aria-label="simple table">
              <TableHead component="thead">
                <TableRow>
                  <TableCell component="th" scope="row">
                    {t('admin.mentor.mentor_management.number')}
                  </TableCell>
                  <TableCell>
                    {t('admin.mentor.mentor_management.name')}
                  </TableCell>
                  <TableCell>
                    {t('admin.mentor.mentor_management.number')}
                  </TableCell>
                  <TableCell style={{ width: 160 }}>
                    {t('admin.mentor.mentor_management.name')}
                  </TableCell>
                  <TableCell>
                    {t('admin.mentor.mentor_management.number')}
                  </TableCell>
                  <TableCell style={{ width: 160 }}>
                    {t('admin.mentor.mentor_management.name')}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Test1
                  </TableCell>
                  <TableCell>Test2</TableCell>
                  <TableCell>Test3</TableCell>
                  <TableCell>Test4</TableCell>
                  <TableCell>Test5</TableCell>
                  <TableCell>Test6</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Test1
                  </TableCell>
                  <TableCell>Test2</TableCell>
                  <TableCell>Test3</TableCell>
                  <TableCell>Test4</TableCell>
                  <TableCell>Test5</TableCell>
                  <TableCell>Test6</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          <Grid display="flex" justifyContent="flex-end" mt={5}>
            <Pagination />
          </Grid>
        </TabPanel>

        {/* Item two */}
        <TabPanel value={value} index={1}>
          <TableContainer component={Paper} className="table-container">
            <Table aria-label="simple table">
              <TableHead component="thead">
                <TableRow>
                  <TableCell component="th" scope="row">
                    {t('admin.mentor.mentor_management.number')}
                  </TableCell>
                  <TableCell>
                    {t('admin.mentor.mentor_management.name')}
                  </TableCell>
                  <TableCell>
                    {t('admin.mentor.mentor_management.number')}
                  </TableCell>
                  <TableCell style={{ width: 160 }}>
                    {t('admin.mentor.mentor_management.name')}
                  </TableCell>
                  <TableCell>
                    {t('admin.mentor.mentor_management.number')}
                  </TableCell>
                  <TableCell style={{ width: 160 }}>
                    {t('admin.mentor.mentor_management.name')}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Test1
                  </TableCell>
                  <TableCell>Test2</TableCell>
                  <TableCell>Test3</TableCell>
                  <TableCell>Test4</TableCell>
                  <TableCell>Test5</TableCell>
                  <TableCell>Test6</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Test1
                  </TableCell>
                  <TableCell>Test2</TableCell>
                  <TableCell>Test3</TableCell>
                  <TableCell>Test4</TableCell>
                  <TableCell>Test5</TableCell>
                  <TableCell>Test6</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </TabPanel>
      </Paper>
    </>
  )
}

export default React.memo(Index)
