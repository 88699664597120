import { INoticeParams } from '@/interfaces'
import client from './axiosClient'

export const apiGetNotice = () => client.get('/notifications/newest')

export const apiGetNoticeList = (params: INoticeParams) =>
  client.get('/notifications', {
    params,
  })

export const apiUpdateRead = (data: number[]) =>
  client.post('/notifications', {
    notification_ids: data,
  })
