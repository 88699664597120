import React, { useRef, useEffect, useState } from 'react'
import Table from 'antd/es/table'
import Tooltip from 'antd/es/tooltip'
import { ColumnsType } from 'antd/lib/table/interface'
import { Reservation, JobManagementParams } from '@/interfaces/jobManagement'
import { useTranslation } from 'react-i18next'
import { Stack, IconButton, Typography } from '@mui/material'
import { useAppSelector, useAppDispatch } from '@/redux/hooks'
import { generatePath, useNavigate } from 'react-router-dom'
import { DEFAULT_LANGUAGE, ROUTER_PATH, URL_WEBSITE } from '@/constants'
import { Launch, Videocam } from '@mui/icons-material'
import useMasterData from '@/hooks/useMasterData'
import { convertTimeToJapan, getValueMasterData, handleError } from '@/utils'
import { RESET_MESSAGE, SET_MESSAGE } from '@/redux/reducers/app.slice'
import { apiGetCurrentDate } from '@/api/jobManagement'

type ITableBookingProps = {
  queryParamsListBooking: JobManagementParams
  setQueryParamsListBooking: (queryParams: JobManagementParams) => void
}

function TableBooking({
  queryParamsListBooking,
  setQueryParamsListBooking,
}: ITableBookingProps) {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const navigate = useNavigate()

  const { listBooking, metaListBooking } = useAppSelector(
    state => state.jobManagement
  )

  const { languageMaster } = useAppSelector(state => state.app)

  const typeServiceList: Array<any> = useMasterData('freelance_service_type')

  const calendarStatusList: Array<any> = useMasterData(
    'freelance_calendar_status'
  )

  // Get text from master data with category freelance_service_type
  const checkTypeService = (status: number): string => {
    const typeService = getValueMasterData(typeServiceList)
    if (languageMaster === DEFAULT_LANGUAGE)
      return typeService[status]?.title_jp
    return typeService[status]?.title_en
  }

  // Get text from master data with category freelance_calendar_status
  const checkStatus = (status: number) => {
    const calendarStatus = getValueMasterData(calendarStatusList)
    if (languageMaster === DEFAULT_LANGUAGE)
      return calendarStatus[status]?.title_jp
    return calendarStatus[status]?.title_en
  }

  const [current, setCurrent] = useState('')
  useEffect(() => {
    async function getCurrentDate() {
      try {
        dispatch(RESET_MESSAGE())
        const response = await apiGetCurrentDate()
        if (response) {
          setCurrent(response?.data)
        }
      } catch (error) {
        dispatch(SET_MESSAGE(handleError(error)))
      }
    }
    getCurrentDate()
  }, [])
  const tableRef = useRef<HTMLDivElement>(null)

  const getTableScrollY = () => {
    const extraHeight = 88 // pagination 64 + padding 24
    const tableHeader = tableRef.current?.querySelector('.ant-table-header')
    const tableHeaderBottom = tableHeader?.getBoundingClientRect().bottom
    return `calc(100vh - ${tableHeaderBottom}px - ${extraHeight}px)`
  }

  // #240768: Get mentor call before minute from master data with category mentor_call_before_minutre
  const mentorCallBeforeMinute: any = Object.values(
    getValueMasterData(useMasterData('mentor_call_before_minutre'))
  )
  // #240768: Get user cleaning time from master data with category user_cleaning_time
  const userCleaningTime: any = Object.values(
    getValueMasterData(useMasterData('user_cleaning_time'))
  )

  const getIconCamera = (record: any) => {
    const nowDateTime = current ? new Date(current) : new Date(new Date().toLocaleString('ja-JP', {timeZone: 'Asia/Tokyo'}))

    // #240768: Calculate time to open camera
    let timeOpenCam = new Date(record.date_time)
    const beforeMinuteValue =
      mentorCallBeforeMinute.length > 0 ? mentorCallBeforeMinute[0].value : 0
    timeOpenCam = new Date(
      new Date(record.date_time).getTime() - beforeMinuteValue * 60000
    )

    let cameraStatus = 0
    // #240768: Open camera if now >= fre_service_box.date_time - mentor_call_before_minutre
    if (timeOpenCam && nowDateTime >= timeOpenCam) {
      cameraStatus = 2
    }

    const serviceTime = record.service_time ?? 0
    // #240768: Calculate time to close camera
    let timeCloseCam = new Date(
      new Date(record.date_time).getTime() + serviceTime * 60000
    )
    const userCleaningTimeValue =
      userCleaningTime.length > 0 ? userCleaningTime[0].value : 0
    timeCloseCam = new Date(
      new Date(record.date_time).getTime() +
        serviceTime * 60000 +
        userCleaningTimeValue * 60000
    )
    // #240768: Close camera if now >= fre_service_box.date_time + fre_service.time + user_cleaning_time
    if (timeCloseCam && nowDateTime >= timeCloseCam) {
      cameraStatus = 1
    }

    switch (cameraStatus) {
      case 1:
        return (
          <IconButton disabled>
            {/* #240768 ThienHK 20220630 hide VideocamOff if call ended */}
            {/* <VideocamOff /> */}
          </IconButton>
        )
      case 2:
        return (
          <IconButton
            onClick={() =>
              window.open(
                URL_WEBSITE +
                  ROUTER_PATH.JOB_MANAGEMENT_TRAINING_NEW_TAB +
                  record.id,
                '_blank'
              )
            }
          >
            <Videocam className="icon-videocam" />
          </IconButton>
        )
      default:
        return <IconButton disabled />
    }
  }

  const columns: ColumnsType<Reservation> = [
    {
      title: t('job_management.table_reservation.service_name'),
      dataIndex: 'service_name',
      key: 'service_name',
      width: '25%',
      align: 'left',
      responsive: ['xs', 'md'],
      render: (_, record) => (
        <Tooltip zIndex={9999} placement="topLeft" title={record.service_name}>
          <Typography className="ellipsis-text">
            {record.service_name}
          </Typography>
        </Tooltip>
      ),
    },
    {
      title: t('job_management.table_reservation.reservation_reception_date'),
      key: 'ctime',
      sorter: true,
      align: 'center',
      render: (_, record) => (
        <Stack direction="column">
          <Typography>
            {convertTimeToJapan(record.ctime || '').format('YYYY/MM/DD')}
          </Typography>
          <Typography>
            {convertTimeToJapan(record.ctime || '').format('HH:mm:ss')}
          </Typography>
        </Stack>
      ),
      width: '7%',
      ellipsis: true,
      responsive: ['xs', 'md'],
    },
    {
      title: t('job_management.table_reservation.form'),
      key: 'service_type',
      render: (_, record) => checkTypeService(record.service_type),
      filters: typeServiceList.map(item => ({
        text:
          languageMaster === DEFAULT_LANGUAGE ? item.title_jp : item.title_en,
        value: Number(item.value),
      })),
      filterMultiple: false,
      width: '7%',
      align: 'center',
      ellipsis: true,
      responsive: ['xs', 'md'],
    },
    {
      title: t('job_management.table_reservation.start_time'),
      key: 'date_time',
      sorter: true,
      width: '7%',
      align: 'center',
      ellipsis: true,
      render: (_, record) => (
        <Stack direction="column">
          <Typography>
            {convertTimeToJapan(record.date_time || '').format('YYYY/MM/DD')}
          </Typography>
          <Typography>
            {convertTimeToJapan(record.date_time || '').format('HH:mm:ss')}
          </Typography>
        </Stack>
      ),
      responsive: ['xs', 'md'],
    },
    {
      title: t('job_management.table_reservation.reservation_num'),
      key: 'reservation_num',
      align: 'center',
      render: (_, record) => (
        <Typography>
          {record.reservation_num}
        </Typography>
      ),
      width: '7%',
      ellipsis: true,
      responsive: ['xs', 'md'],
    },
    {
      title: t('job_management.table_reservation.price'),
      key: 'service_price',
      sorter: true,
      align: 'right',
      render: (_, record) => (
        <Typography>
          {record.service_price.toLocaleString('ja-JP', {
            style: 'currency',
            currency: 'JPY',
          })}
        </Typography>
      ),
      width: '6%',
      ellipsis: true,
      responsive: ['xs', 'md'],
    },
    {
      title: t('job_management.table_reservation.status'),
      key: 'status',
      render: (_, record) => (
        <Typography>{checkStatus(record.booking_status)}</Typography>
      ),
      filters: calendarStatusList
        .filter(
          item =>
            Number(item.value) === 1 ||
            Number(item.value) === 3 ||
            Number(item.value) === 4
        )
        .map(item => ({
          text:
            languageMaster === DEFAULT_LANGUAGE ? item.title_jp : item.title_en,
          value: Number(item.value),
        })),
      align: 'center',
      filterMultiple: false,
      width: '6%',
      ellipsis: true,
    },
    {
      title: t('job_management.table_reservation.action'),
      key: 'action',
      align: 'left',
      render: (_, record) => (
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={{ md: 0, lg: 1, xl: 2 }}
        >
          <IconButton
            onClick={() =>
              navigate(
                generatePath(ROUTER_PATH.JOB_MANAGEMENT_BOOKING, {
                  id: `${record.id}`,
                })
              )
            }
          >
            <Launch />
          </IconButton>
          {getIconCamera(record)}
        </Stack>
      ),
      width: '6%',
      ellipsis: true,
    },
  ]

  const filterDataBooking = (pagination: any, filters: any, sorter: any) => {
    let queryParamsClone = { ...queryParamsListBooking }
    queryParamsClone = { ...queryParamsClone, page: pagination.current }
    if (Array.isArray(filters.service_type))
      queryParamsClone = {
        ...queryParamsClone,
        type: filters.service_type[0],
        page:
          queryParamsListBooking.page === pagination.current
            ? 1
            : pagination.current,
      }
    else
      queryParamsClone = {
        ...queryParamsClone,
        type: '',
        page:
          queryParamsListBooking.page === pagination.current
            ? 1
            : pagination.current,
      }
    if (Array.isArray(filters.status))
      queryParamsClone = {
        ...queryParamsClone,
        status: filters.status[0],
        page:
          queryParamsListBooking.page === pagination.current
            ? 1
            : pagination.current,
      }
    else
      queryParamsClone = {
        ...queryParamsClone,
        status: '',
        page:
          queryParamsListBooking.page === pagination.current
            ? 1
            : pagination.current,
      }

    if (sorter.column) {
      if (sorter.order === 'ascend')
        queryParamsClone = {
          ...queryParamsClone,
          sort: `${sorter.column.key}:ASC`,
          page:
            queryParamsListBooking.page === pagination.current
              ? 1
              : pagination.current,
        }
      else
        queryParamsClone = {
          ...queryParamsClone,
          sort: `${sorter.column.key}:DESC`,
          page:
            queryParamsListBooking.page === pagination.current
              ? 1
              : pagination.current,
        }
    } else
      queryParamsClone = {
        ...queryParamsClone,
        sort: '',
        page:
          queryParamsListBooking.page === pagination.current
            ? 1
            : pagination.current,
      }
    setQueryParamsListBooking(queryParamsClone)
  }

  return (
    <Table
      scroll={{ y: getTableScrollY() }}
      columns={columns}
      dataSource={listBooking}
      ref={tableRef}
      onChange={filterDataBooking}
      locale={{
        emptyText: t('common.no_data'),
        filterReset: t('common.reset'),
        filterConfirm: t('common.apply'),
        triggerAsc: t('common.sort_asc'),
        triggerDesc: t('common.sort_desc'),
        cancelSort: t('common.cancel_sort'),
      }}
      pagination={{
        total: metaListBooking.total,
        current: metaListBooking.current_page,
        showSizeChanger: false,
        showTotal: () =>
          `${
            metaListBooking.per_page * (metaListBooking.current_page - 1) + 1
          }-${
            metaListBooking.per_page * (metaListBooking.current_page - 1) +
            listBooking.length
          } / ${metaListBooking.total}`,
      }}
    />
  )
}

export default React.memo(TableBooking)
