import '@/assets/styles/app.scss'
import React from 'react'
import Routes from '@/routes'
import { store } from '@/redux/store'
import { Provider } from 'react-redux'
import { SnackbarProvider } from 'notistack'

function App() {
  return (
    <Provider store={store}>
      <SnackbarProvider maxSnack={3} autoHideDuration={3000}>
        <Routes />
      </SnackbarProvider>
    </Provider>
  )
}

export default App
