import React from 'react'
import MainLayout from '@/layout/MainLayout'
import { RouteObject } from 'react-router-dom'
import PrivateRoute from '@components/common/PrivateRoute'

import Contact from '@/pages/Contact'
import Dashboard from '@/pages/Dashboard'
import Information from '@/pages/Information'
import InformationEdit from '@/pages/Information/Edit'
import Mentor from '@/pages/Mentor'
import Notification from '@/pages/Notification'
import Setting from '@/pages/Setting'
import ServiceDetail from '@/pages/Service/Detail'
import Training from '@/pages/Training'
import BookingDetail from '@/pages/Booking'
import RecordVideo from '@/pages/RecordVideo'
import BoxSetting from '@/pages/JobManagement/BoxSetting'
import ServiceList from '@/pages/JobManagement/ServiceList'
import BookingList from '@/pages/JobManagement/BookingList'
import FriendRequestList from '@/pages/JobManagement/FriendRequestList'
import BoxServiceDelete from '@/pages/JobManagement/BoxServiceDelete'
import BoxServiceCreate from '@/pages/JobManagement/BoxServiceCreate'

const PrivateRouter: RouteObject = {
  path: '/',
  element: (
    <PrivateRoute>
      <MainLayout />
    </PrivateRoute>
  ),
  children: [
    {
      index: true,
      path: 'dashboard',
      element: <Dashboard />,
    },
    {
      path: 'information',
      element: <Information />,
    },
    {
      path: 'setting/edit',
      element: <InformationEdit />,
    },
    {
      path: 'job-management/service/:id',
      element: <ServiceDetail />,
    },
    {
      path: 'job-management/service/:id/:state',
      element: <ServiceDetail />,
    },
    {
      path: 'job-management/service/create',
      element: <ServiceDetail />,
    },
    {
      path: 'job-management/booking/:id',
      element: <BookingDetail />,
    },
    {
      path: 'job-management/training/:id',
      element: <Training />,
    },
    {
      path: 'job-management/recorded-video/:id',
      element: <RecordVideo />,
    },
    {
      path: 'job-management/box-service/:id',
      element: <BoxSetting />,
    },
    {
      path: 'job-management/set-to-box',
      element: <BoxServiceCreate />,
    },
    {
      path: 'job-management/box-service-delete/:id',
      element: <BoxServiceDelete />,
    },
    {
      path: 'Contact',
      element: <Contact />,
    },
    {
      path: 'setting',
      element: <Setting />,
    },
    {
      path: 'mentors',
      element: <Mentor />,
    },
    {
      path: 'notifications',
      element: <Notification />,
    },
    {
      path: 'job-management/service',
      element: <ServiceList />,
    },
    {
      path: 'job-management/booking',
      element: <BookingList />,
    },
    {
      path: 'job-management/friend-request',
      element: <FriendRequestList />,
    },
  ],
}

export default PrivateRouter
