import { all, takeLatest, put } from 'redux-saga/effects'
import Api from '@/api'
import { AxiosResponse } from 'axios'
import {
  FETCH_PRODUCTS,
  SET_PRODUCT,
  SET_ERROR_MESSAGE,
} from '@/redux/reducers/product.slice'
import { SET_LOADING } from '@/redux/reducers/app.slice'
import { PayloadAction } from '@reduxjs/toolkit'
import { PagingProduct } from '@/interfaces'

function* getProductList(action: PayloadAction<PagingProduct>) {
  yield put(SET_LOADING(true))
  try {
    const { page, limit } = action.payload
    const response: AxiosResponse = yield Api.productApi.getAll({
      page,
      limit,
    })
    const { items, count } = response.data
    yield put(
      SET_PRODUCT({
        items,
        meta: {
          total: count,
          page,
        },
      })
    )
  } catch (err) {
    yield put(SET_ERROR_MESSAGE)
  } finally {
    yield put(SET_LOADING(false))
  }
}

export default function* productSaga() {
  yield all([takeLatest(FETCH_PRODUCTS, getProductList)])
}
