import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Grid, Tabs, Tab, Paper } from '@mui/material'
import { useLocation, useNavigate } from 'react-router-dom'
import TabPanel from '@/components/common/TabPanel'
import Profile from '@components/Information/Profile'
// import Notification from '@/components/Information/Notification'
import Verification from '@/components/Information/Verification'
import BankAccount from '@/components/Information/BankAccount'
import ChangePassword from '@/components/Information/ChangePassword'

function Edit() {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    navigate(`#${newValue}`)
  }
  const location = useLocation()
  const navigatePage = useMemo(
    () => location.hash.replace('#', ''),
    [location.hash]
  )

  return (
    <Paper>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Grid container>
          <Grid item lg={12}>
            <Tabs
              value={navigatePage}
              onChange={handleChange}
              aria-label="caption table"
            >
              <Tab
                label={t('information_edit.tab_title.profile')}
                value="profile"
              />
              {/* <Tab
                label={t('information_edit.tab_title.notification')}
                value="notification"
              /> */}
              <Tab
                label={t('information_edit.tab_title.verification')}
                value="verification"
              />
              <Tab
                label={t('information_edit.tab_title.bank_account')}
                value="bank-info"
              />
              <Tab
                label={t('information_edit.tab_title.change_password')}
                value="change-password"
              />
            </Tabs>
          </Grid>
          <Grid item lg={12}>
            <TabPanel index={navigatePage} value="profile">
              <Profile />
            </TabPanel>
            {/* <TabPanel index={navigatePage} value="notification">
              <Notification />
            </TabPanel> */}
            <TabPanel index={navigatePage} value="verification">
              <Verification />
            </TabPanel>
            <TabPanel index={navigatePage} value="bank-info">
              <BankAccount />
            </TabPanel>
            <TabPanel index={navigatePage} value="change-password">
              <ChangePassword />
            </TabPanel>
          </Grid>
        </Grid>
      </Box>
    </Paper>
  )
}

export default React.memo(Edit)
