import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  AuthRegister,
  AuthSignIn,
  AuthState,
  AuthForgotPassword,
  AuthResetPassword,
} from '@/interfaces'

const initialState: AuthState = {
  currentUser: {},
  loading: true,
}

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    AUTH_USER_LOGIN: (state, action: PayloadAction<AuthSignIn>) => {},
    AUTH_SET_CURRENT_USER: (state, action: PayloadAction<any>) => {
      state.currentUser = action.payload
      state.loading = false
    },
    AUTH_USER_FORGOT_PASSWORD: (
      state,
      action: PayloadAction<AuthForgotPassword>
    ) => {},
    AUTH_USER_REGISTER: (state, action: PayloadAction<AuthRegister>) => {},
    AUTH_USER_RESET_PASSWORD: (
      state,
      action: PayloadAction<AuthResetPassword>
    ) => {},
    AUTH_GET_INFO: () => {},
    AUTH_LOADING: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload
    },
    AUTH_LOGOUT: state => {
      state.currentUser = {}
    },
  },
})

// Actions
export const {
  AUTH_USER_LOGIN,
  AUTH_SET_CURRENT_USER,
  AUTH_USER_REGISTER,
  AUTH_USER_FORGOT_PASSWORD,
  AUTH_USER_RESET_PASSWORD,
  AUTH_GET_INFO,
  AUTH_LOADING,
  AUTH_LOGOUT,
} = authSlice.actions

// Selector
export const currentUser = (state: AuthState) => state.currentUser

// Reducer
const authReducer = authSlice.reducer

export default authReducer
