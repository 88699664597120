import { apiGetIpCamera, apiRecordVideoFreelancer } from '@/api/jobManagement'
import ConfirmDialog from '@/components/common/ConfirmDialog'
// apiGetMirrorCodeByShopId
import { ROUTER_PATH, URL_WEBSITE } from '@/constants'
import useMasterData from '@/hooks/useMasterData'
import useSocket from '@/hooks/useSocket'
import { useAppDispatch, useAppSelector } from '@/redux/hooks'
import { RESET_MESSAGE, SET_MESSAGE } from '@/redux/reducers/app.slice'
import {
  FETCH_BOOKING_DETAIL,
  RESET_BOOKING_DETAIL,
} from '@/redux/reducers/jobManagement.slice'
import {
  convertSeconds,
  convertTimeToJapan,
  generateKey,
  getValueMasterData,
  handleError,
} from '@/utils'
import {
  selectIsConnectedToRoom,
  selectIsLocalAudioEnabled,
  useHMSActions,
  useHMSStore,
  HMSPeer,
} from '@100mslive/react-sdk'
import {
  HourglassTop,
  Mic,
  MicOff,
  NavigateNext,
  VolumeDown,
  VolumeOff,
  VolumeUp,
} from '@mui/icons-material'
import {
  Box,
  Breadcrumbs,
  Button,
  Grid,
  Paper,
  Slider,
  Stack,
  Typography,
} from '@mui/material'
import dayjs from 'dayjs'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useNavigate, useParams } from 'react-router-dom'
import ParticipantStream from '../../components/JobManagement/Training/ParticipantStream'
import ParticipantStreamMain from '../../components/JobManagement/Training/ParticipantStreamMain'
import useHMSRoom from '../../hooks/useHMSRoom'

const showSpeaker = (value: number, toggleVolume: () => void) => {
  if (value === 0) {
    return <VolumeOff onClick={() => toggleVolume()} />
  }
  if (value <= 50) {
    return <VolumeDown onClick={() => toggleVolume()} />
  }
  return <VolumeUp onClick={() => toggleVolume()} />
}
function Training() {
  const hmsActions = useHMSActions()
  const { bookingDetail } = useAppSelector(state => state.jobManagement)
  const isLocalAudioEnabled = useHMSStore(selectIsLocalAudioEnabled)
  const { currentUser } = useAppSelector(state => state.auth)
  const [mirrorCode, setMirrorCode] = useState<string>('')
  const [listParticipant, setListParticipant] = useState<any[]>([])
  const [valueVolume, setValueVolume] = useState<number>(100)

  const navigate = useNavigate()

  const [listMicParticipants, setListMicParticipants] = useState<any[]>([])

  const { peers, localVideoTrackId, currentRoom, localPeer } =
    useHMSRoom(mirrorCode)
  const isConnectedHMS = useHMSStore(selectIsConnectedToRoom)

  const [toggleDialog, setToggleDialog] = useState<boolean>(false)
  const [isZoom, setIsZoom] = useState<boolean>(false)
  const [isCall, setIsCall] = useState<boolean>(false)
  const [isRecording, setIsRecording] = useState<boolean>(false)
  const videoContainer = useRef(null)

  const [timer, setTimer] = useState<string>('')
  const [timesUp, setTimesUp] = useState<boolean>(false)

  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const { isConnected, socket, isDisconnected } = useSocket()
  const params = useParams()

  const category = getValueMasterData(useMasterData('freelance_training_type'))

  // Handle service time
  const serviceTime = convertTimeToJapan(
    convertTimeToJapan(
      bookingDetail?.start_time ? bookingDetail?.start_time.toString() : ''
    )
      .add(bookingDetail?.duration, 'm')
      .format('YYYY-MM-DD HH:mm')
  )

  const timeNow = convertTimeToJapan(
    dayjs().tz('Asia/Tokyo').format('YYYY-MM-DD HH:mm')
  )
  console.log('Service time: ', serviceTime)
  // Handle diff time now & service time
  const durationActual = useMemo(() => {
    if (bookingDetail.id) {
      return serviceTime.diff(timeNow, 'second')
    }
    return 60
  }, [bookingDetail])
  const checkUserMirrorCode = (user: string) => user.split('_')[1]

  const checkUserId = (user: string) => Number(user.split('_')[2])
  const getIpCamera = async (userMirrorCode: any) => {
    const response: any = await apiGetIpCamera(userMirrorCode)
    return response.data
  }
  // const getMirrorCode = async (boxx)
  const openUserCamera = async (_username: any) => {
    const userMirrorCode = checkUserMirrorCode(_username.name)

    const userId = checkUserId(_username.name)
    const cameraList = await getIpCamera(userMirrorCode)
    const width = cameraList.length > 1 ? 900 : 600
    const height = cameraList.length > 1 ? 520 : 670
    const left = window.screen.width / 2 - width / 2
    const top = window.screen.height / 2 - height / 2
    window.open(
      `${URL_WEBSITE}/job-management/training/${params.id}/cameras/${userMirrorCode}/${userId}`,
      '_blank',
      `height=${height}, width=${width}, left=${left}, top=${top}`
    )
  }

  // Handle on/off volume
  const toggleVolume = () => {
    if (valueVolume === 0) {
      setValueVolume(22)
      return
    }
    setValueVolume(0)
  }

  const handleChange = (event: Event, newValue: number | number[]) => {
    setValueVolume(newValue as number)
  }

  // handle on/off microphone
  const toggleMic = () => {
    hmsActions.setLocalAudioEnabled(!isLocalAudioEnabled)
  }

  // handle leave room
  const leaveRoomTraining = () => {
    console.log('Leave room training')
    const video: any = videoContainer.current
    if (localVideoTrackId) {
      hmsActions.detachVideo(localVideoTrackId, video)
    }
    navigate(ROUTER_PATH.JOB_MANAGEMENT_BOOKING_LIST)
    window.close()
  }

  const handleEndClass = () => {
    // eslint-disable-next-line no-restricted-syntax
    for (const participant of listParticipant) {
      const participantMirrorCode = checkUserMirrorCode(participant.name)
      socket.emit('out_room', {
        mirrorCode: participantMirrorCode
      })
    }
    setToggleDialog(false)
  }

  // Handle record video
  const callApiRecordVideoFreelancer = async () => {
    if (!isRecording) {
      try {
        dispatch(RESET_MESSAGE())
        await apiRecordVideoFreelancer(Number(bookingDetail.service_box_id), {
          reservation_id: Number(bookingDetail?.id),
          room_id: currentUser.room.room_id,
        })
        setIsRecording(true)
      } catch (err) {
        dispatch(SET_MESSAGE(handleError(err)))
      }
    }
  }

  useEffect(() => {
    console.log('Mentors join', localPeer)
    if (localVideoTrackId && videoContainer.current) {
      if (localVideoTrackId) {
        hmsActions.attachVideo(localVideoTrackId, videoContainer.current)
        localStorage.setItem('isHaveCall', 'true')
        setIsCall(true)
        // FIXME: setting mic before join
        if (!isLocalAudioEnabled) toggleMic()
        callApiRecordVideoFreelancer()
      }
    }
  }, [localPeer])

  useEffect(() => {
    if (isCall && localStorage.getItem('isHaveCall')) {
      window.onbeforeunload = () => localStorage.removeItem('isHaveCall')
    }
    return () => {
      if (isCall) {
        setIsCall(false)
        localStorage.removeItem('isHaveCall')
        window.onbeforeunload = () => undefined
      }
    }
  }, [isCall])

  const getDeliverTime = () => {
    const date = new Date() // 获取一个时间对象
    const a = String(date.getFullYear()) // 获取完整的年份(4位,1970)
    let b = String(date.getMonth() + 1) // 获取月份(0-11,0代表1月,用的时候记得加上1)
    if (b.length === 1) {
      b = 0 + b
    }
    let c = String(date.getDate()) // 获取日(1-31)
    if (c.length === 1) {
      c = 0 + c
    }
    let d = String(date.getMilliseconds()) // 获取毫秒数)
    if (d.length === 1) {
      d = 0 + 0 + d
    }
    if (d.length === 2) {
      d = 0 + d
    }
    let e = String(date.getHours()) // 获取小时数(0-23)
    if (e.length === 1) {
      e = 0 + e
    }
    let f = String(date.getMinutes()) // 获取分钟数(0-59)
    if (f.length === 1) {
      f = 0 + f
    }
    let g = String(date.getSeconds()) // 获取秒数(0-59)
    if (g.length === 1) {
      g = 0 + g
    }
    const h = Number(a + b + c + e + f + g + d)
    return h
  }

  const isFitness = () => {
    const categoryJp = bookingDetail.category
      .toString()
      .split(',')
      .map(item => category[item]?.title_jp)
    return categoryJp.length > 0 && categoryJp[0] === 'フィットネス' ? 1 : 0
  }

  const callSocketJoinRoom = () => {
    try {
      const startTime = dayjs(bookingDetail.start_time).format('YYYYMMDDHHmm')
      const mirrorCodes = bookingDetail.mirror_codes
      // eslint-disable-next-line no-restricted-syntax
      for (const mirrorCodeInBooked of mirrorCodes) {
        const data = {
          companyCode: 'GIN',
          shopId: bookingDetail.shop_id,
          mirrorCode: mirrorCodeInBooked,
          deliveredTime: getDeliverTime(),
          reservationStartTime: startTime, // fre_service_box.date_time
          userId: bookingDetail.user_id, // reservation.user_id
          mentor_id: currentUser.id,
          eventInformation: [
            {
              eventPageName: '07_TOP',
              eventPageId: 'mirror-homepage',
              eventButtonId: isFitness()
                ? 'call-mentor-not-qr'
                : 'este-call-mentor-not-qr',
              eventType: '',
              eventUpdown: '',
              eventValue: 3,
            },
          ],
          courseDetailSetting: [],
          room_id: bookingDetail.room_id,
          passcode: bookingDetail.passcode,
        }
        socket.emit('mentor_join_room', JSON.stringify(data))
      }
    } catch (error) {
      dispatch(SET_MESSAGE(handleError(error)))
    }
  }

  useEffect(() => {
    if (isConnected) {
      socket.on('connect', () => {})
      socket.on('user_muted', (data: any) => {
        setListMicParticipants([...listMicParticipants, data])
      })
    }
    return () => {
      if (socket) {
        socket.off('user_muted')
      }
    }
  }, [isConnected])
  // Send socket mentor successful connection
  useEffect(() => {
    console.log('Mentor connection to room successful', isConnectedHMS)
    if (isConnectedHMS) {
      callSocketJoinRoom()
    }
  }, [isConnectedHMS])

  const timeDebug = Number(window.location.hash.replaceAll('#', '')) || 3600
  const duration = params.id === 'debug' ? timeDebug : durationActual
  // #240768: Get user cleaning time from master data with category user_cleaning_time

  // Get User participants room

  useEffect(() => {
    if (!peers.length) return
    const remotePeers = peers
      .filter(
        (peer: HMSPeer) =>
          !peer.isLocal && peer.roleName !== '__internal_recorder'
      )
      .map((remotePeer: any) => ({ ...remotePeer, isZoom: false }))
    if (
      remotePeers &&
      remotePeers.length > 0 &&
      remotePeers.every((peer: any) => !peer.isZoom)
    ) {
      remotePeers[0].isZoom = true
    }
    setListParticipant(remotePeers)
    // TODO: console.log
    console.log('ListParticipant in room', listParticipant)
  }, [peers])

  // Handle zoom in/zoom out video
  const handleZoomVideo = (indexZoom: number) => {
    try {
      const listParticipantClone = [...listParticipant]

      listParticipantClone.forEach((item, index) => {
        item.isZoom = indexZoom === index
      })
      setListParticipant(listParticipantClone)
      setIsZoom(!isZoom)
    } catch (error) {
      dispatch(SET_MESSAGE(handleError(error)))
    }
  }

  const userCleaningTime: any = Object.values(
    getValueMasterData(useMasterData('user_cleaning_time'))
  )

  useEffect(() => {
    dispatch(RESET_BOOKING_DETAIL())
    if (params.id && params.id !== 'debug') {
      dispatch(FETCH_BOOKING_DETAIL(params.id))
    }
    setMirrorCode(`fitness_${currentUser?.id}`)
    // Ticket Id #232679
    const isHaveCall: boolean = JSON.parse(
      localStorage.getItem('isHaveCall') ?? 'false'
    )
    if (isHaveCall) {
      // Task id #233870
      dispatch(RESET_MESSAGE())
      dispatch(
        SET_MESSAGE({
          type: 'error',
          content: t('job_management.training.err_already_joined'),
        })
      )
      leaveRoomTraining()
    }
    return () => {
      if (currentRoom) setMirrorCode('')
    }
  }, [params.id])

  useEffect(() => {
    // #240768: Thoi gian ket thuc cuoc goi se cong them so phut don dep
    const userCleaningTimeValue =
      userCleaningTime.length > 0 ? userCleaningTime[0].value : 0
    let durationWithCleaningTime = duration || 0
    durationWithCleaningTime = duration + userCleaningTimeValue * 60
    const intervalTimer = setInterval(() => {
      setTimer(convertSeconds(durationWithCleaningTime || 0))
      durationWithCleaningTime -= 1
      if (durationWithCleaningTime < 60 && durationWithCleaningTime > 0) {
        setTimesUp(true)
        return
      }
      if (durationWithCleaningTime <= 0) {
        // Task id #233870

        setTimeout(() => {
          dispatch(RESET_MESSAGE())
          dispatch(
            SET_MESSAGE({
              type: 'warning',
              content: t('job_management.training.time_end'),
            })
          )
          leaveRoomTraining()
        }, 800)
      }
    }, 1000)
    return () => {
      setTimesUp(false)
      clearInterval(intervalTimer)
    }
  }, [bookingDetail])

  useEffect(() => {
    if (isDisconnected) {
      leaveRoomTraining()
      alert(t('job_management.training.message_disconnect_call'))
    }
  }, [isDisconnected])

  return (
    <Box className="training-container">
      <Breadcrumbs
        separator={<NavigateNext fontSize="small" />}
        aria-label="breadcrumb"
      >
        <Link
          to="/job-management"
          className="breadcrumb-text breadcrumb-text-list"
        >
          {t('job_management.training.breadcrumb.list')}
        </Link>
        <Box className="breadcrumb-text">
          {t('job_management.training.breadcrumb.course')}
        </Box>
        ,
      </Breadcrumbs>
      <Paper elevation={0} sx={{ mt: 1 }}>
        <Box className="training-title">
          {params.id === 'debug' ? 'DEBUG' : bookingDetail.name}
        </Box>
        <Box className="training-stream">
          <Stack
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
            className="training-remaining-time"
            spacing={1}
          >
            <Typography fontWeight="bold" className={timesUp ? 'times-up' : ''}>
              {timer}
            </Typography>
            <HourglassTop />
          </Stack>
          <Grid container spacing={2}>
            <Grid item xs={10}>
              {listParticipant.length > 0 ? (
                listParticipant.map((item, index) => (
                  <ParticipantStreamMain
                    key={generateKey('participant_zoom', index)}
                    participant={item}
                    valueVolume={valueVolume}
                    isZoom={isZoom}
                    openUserCamera={openUserCamera}
                  />
                ))
              ) : (
                <Box className="training-stream-main" />
              )}
            </Grid>
            <Grid item xs={2}>
              <Stack
                direction="column"
                sx={{ maxHeight: 683 }}
                className="stream-participants-wrapper"
              >
                {listParticipant.length > 0 &&
                  listParticipant.map((item, index) => (
                    <ParticipantStream
                      key={generateKey('participant', index)}
                      participant={item}
                      valueVolume={valueVolume}
                      handleZoomVideo={handleZoomVideo}
                      index={index}
                      openUserCamera={openUserCamera}
                    />
                  ))}
                <Box position="relative">
                  <Box className="training-stream-participants">
                    <video ref={videoContainer} autoPlay muted playsInline />
                  </Box>
                  <Stack
                    className="training-stream-participants-info"
                    direction="row"
                    width="100%"
                    justifyContent="space-between"
                  >
                    <Box>
                      {!isLocalAudioEnabled ? (
                        <MicOff fontSize="small" />
                      ) : (
                        <Mic fontSize="small" />
                      )}
                    </Box>
                  </Stack>
                </Box>
              </Stack>
            </Grid>
          </Grid>
          <Stack
            className="training-stream-control"
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={8}
          >
            <Stack
              spacing={2}
              direction="row"
              alignItems="center"
              className="controller-volumn"
            >
              {showSpeaker(valueVolume, toggleVolume)}
              <Slider
                aria-label="Volume"
                value={valueVolume}
                onChange={handleChange}
                sx={{ mr: '16px !important' }}
              />
            </Stack>
            <Stack
              spacing={0}
              direction="row"
              alignItems="center"
              className="controller-mic"
              onClick={toggleMic}
            >
              {!isLocalAudioEnabled ? <MicOff /> : <Mic />}
            </Stack>
            <Stack
              spacing={0}
              direction="row"
              alignItems="center"
              justifyContent="center"
              sx={{ m: '23px !important' }}
            >
              <Button
                onClick={leaveRoomTraining}
                variant="contained"
                color="error"
                size="small"
                sx={{ height: 50 }}
              >
                {t('job_management.training.button')}
              </Button>
            </Stack>
            <Stack
              spacing={0}
              direction="row"
              alignItems="center"
              justifyContent="center"
              sx={{ m: '23px !important' }}
            >
              <Button
                onClick={() => {
                  setToggleDialog(true)
                }}
                variant="contained"
                color="error"
                size="small"
                sx={{ height: 50 }}
              >
                {t('job_management.training.end_of_class.button')}
              </Button>
            </Stack>
          </Stack>
          <ConfirmDialog
            openDialog={toggleDialog}
            setOpenDialog={setToggleDialog}
            onConfirmDialog={() => {
              handleEndClass()
              leaveRoomTraining()
            }}
            dialogContent={t('job_management.training.end_of_class.content')}
            okText={t('job_management.training.end_of_class.button_ok')}
            cancelText={t('job_management.training.end_of_class.button_cancel')}
          />
        </Box>
      </Paper>
    </Box>
  )
}

export default React.memo(Training)
