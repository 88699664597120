import React, { useEffect, useRef } from 'react'
import {
  Box,
  Paper,
  Grid,
  Stack,
  Tabs,
  Tab,
  TextField,
  IconButton,
  Typography,
} from '@mui/material'
import '@/assets/styles/pages/jobManagement.scss'
import { SearchOutlined } from '@mui/icons-material'
import { useTranslation } from 'react-i18next'
import { JobManagementParams } from '@/interfaces/jobManagement'
import { useAppDispatch, useAppSelector } from '@/redux/hooks'
import { TabPanelProps } from '@/interfaces'
import {
  FETCH_LIST_BOOKING,
  GET_AMOUNT,
} from '@/redux/reducers/jobManagement.slice'
import TableBooking from '@/components/JobManagement/TableBooking'

function TabPanel({ index, value, children }: TabPanelProps) {
  return <div>{value === index && <Box>{children}</Box>}</div>
}

function BookingList() {
  const [queryParamsListBooking, setQueryParamsListBooking] =
    React.useState<JobManagementParams>({
      page: 1,
      type: '',
      status: '',
      sort: '',
      search: '',
    })

  const searchRef = useRef<HTMLInputElement>(null)

  const { t } = useTranslation()

  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(FETCH_LIST_BOOKING(queryParamsListBooking))
  }, [queryParamsListBooking])

  useEffect(() => {
    dispatch(GET_AMOUNT())
  }, [])

  const { totalAmount } = useAppSelector(state => state.jobManagement)

  const searchValue = (event: any) => {
    if (event.key === 'Enter') {
      setQueryParamsListBooking({
        ...queryParamsListBooking,
        search: event.target.value.trim(),
        page: 1,
      })
    }
  }

  return (
    <Box sx={{ flexGrow: 1 }} className="job-container">
      <Grid container spacing={2}>
        <Grid item xl={12}>
          <Paper elevation={3}>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              spacing={2}
              sx={{ marginRight: 2 }}
            >
              <Stack
                direction="row"
                justifyContent="center"
                alignItems="center"
                spacing={20}
              >
                <Tabs
                  value={0}
                  aria-label="basic tabs example"
                  className="reservation-container"
                >
                  <Tab label={t('job_management.tab_title.reservation_list')} />
                </Tabs>
                <Stack direction="row" spacing={4}>
                  <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                    {t('job_management.title.current_balance')}
                  </Typography>
                  <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                    {totalAmount.toLocaleString('ja-JP', {
                      style: 'currency',
                      currency: 'JPY',
                    })}
                  </Typography>
                </Stack>
              </Stack>
              <Box minWidth={310}>
                <TextField
                  className="search-input"
                  size="small"
                  fullWidth
                  onKeyDown={searchValue}
                  variant="outlined"
                  placeholder={t(
                    'job_management.table_reservation.search_placeholder'
                  )}
                  InputProps={{
                    startAdornment: (
                      <IconButton>
                        <SearchOutlined />
                      </IconButton>
                    ),
                  }}
                  inputProps={{ ref: searchRef, maxLength: 500 }}
                />
              </Box>
            </Stack>
            <TabPanel value={0} index={0}>
              <TableBooking
                queryParamsListBooking={queryParamsListBooking}
                setQueryParamsListBooking={setQueryParamsListBooking}
              />
            </TabPanel>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  )
}

export default React.memo(BookingList)
