import React from 'react'
import { Stack, Dialog, DialogTitle, Button } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useAppDispatch } from '@/redux/hooks'
import {
  FETCH_DATA
} from '@/redux/reducers/information.slice'
import {
  RESET_MESSAGE,
  SET_LOADING,
  SET_MESSAGE,
} from '@/redux/reducers/app.slice'
import { apiDeleteServiceBox } from '@/api/jobManagement'
import { handleError } from '@/utils'

interface ModalProps {
  isOpen: boolean
  toggleModal(open: any): void
  setTooltipVisible(): void
  serviceId: any
  deleteStatus: any
}

function ConfirmDeleteServiceDialog({
  isOpen,
  toggleModal,
  serviceId,
  setTooltipVisible,
  deleteStatus,
}: ModalProps) {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const handleDeleteService = async (serviceBoxId: number) => {
    try {
      if (serviceBoxId) {
        dispatch(SET_LOADING(true))
        dispatch(RESET_MESSAGE())
        await apiDeleteServiceBox(serviceBoxId)
      }
      dispatch(FETCH_DATA())
    } catch (error) {
      dispatch(SET_MESSAGE(handleError(error)))
      deleteStatus(handleError(error))
    } finally {
      setTooltipVisible()
      dispatch(SET_LOADING(false))
    }
  }

  const onConfirmDeleteService = () => {
    handleDeleteService(serviceId)
    toggleModal(!isOpen)
  }

  return (
    <Dialog
      className="dialog-container"
      onClose={() => toggleModal(!isOpen)}
      open={isOpen}
      maxWidth="sm"
      fullWidth
      sx={{
        padding: 3,
      }}
    >
      <Stack direction="column" spacing={5} alignItems="center" p={3}>
        <Stack direction="row" alignItems="center" spacing={2}>
          <DialogTitle sx={{ padding: 0, fontWeight: 'bold', fontSize: 24 }}>
            {t('common.delete_content')}
          </DialogTitle>
        </Stack>
        <Stack direction="row" spacing={4} width="60%">
          <Button
            fullWidth
            variant="contained"
            color="error"
            sx={{
              fontWeight: 600,
              fontSize: 16,
              height: 36,
              whiteSpace: 'nowrap',
            }}
            onClick={() => toggleModal(!isOpen)}
          >
            {t('common.cancel')}
          </Button>
          <Button
            variant="outlined"
            sx={{
              fontWeight: 600,
              fontSize: 16,
              height: 36,
              minWidth: 120,
            }}
            onClick={onConfirmDeleteService}
          >
            {t('common.ok')}
          </Button>
        </Stack>
      </Stack>
    </Dialog>
  )
}

export default React.memo(ConfirmDeleteServiceDialog)
