import { all, takeLatest, put, call, ForkEffect } from 'redux-saga/effects'
import {
  apiGetServiceAndSchedule,
  apiGetProfile,
  apiProfileEdit,
  apiVerificationEdit,
  apiBankAccountEdit,
  apiChangePassword,
  apiGetVerification,
  apiGetBankAccount,
} from '@/api/information'
import { AxiosResponse } from 'axios'
import {
  FETCH_DATA,
  SET_DATA,
  FETCH_MENTOR_PROFILE,
  SET_MENTOR_PROFILE,
  EDIT_PROFILE,
  PROFILE_FAILED,
  FETCH_MENTOR_VERIFICATION,
  SET_VERIFICATION,
  EDIT_VERIFICATION,
  VERIFICATION_FAILED,
  FETCH_MENTOR_BANK_ACCOUNT,
  SET_BANK_ACCOUNT,
  EDIT_BANK_ACCOUNT,
  BANK_ACCOUNT_FAILED,
  CHANGE_PASSWORD_REQUEST,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_FAILED,
} from '@/redux/reducers/information.slice'

import { AUTH_GET_INFO, AUTH_LOGOUT } from '@/redux/reducers/auth.slice'
import { SET_LOADING, SET_MESSAGE } from '@/redux/reducers/app.slice'
import { handleError } from '@/utils'
import { PayloadAction } from '@reduxjs/toolkit'
import { IChangePassword, IProfile, IVerification } from '@/interfaces'

function* getData() {
  try {
    yield put(SET_LOADING(true))
    const response: AxiosResponse = yield call(apiGetServiceAndSchedule)
    const { data } = response
    yield put(SET_DATA(data))
  } catch (err) {
    yield put(SET_MESSAGE(handleError(err)))
  } finally {
    yield put(SET_LOADING(false))
  }
}

function* getMentorProfile() {
  try {
    const response: AxiosResponse = yield call(apiGetProfile)
    const { data } = response
    yield put(SET_MENTOR_PROFILE({ ...data }))
  } catch (err) {
    yield put(SET_MESSAGE(handleError(err)))
    yield put(PROFILE_FAILED())
  }
}

function* editProfile(action: PayloadAction<IProfile>) {
  try {
    const data = action.payload
    yield call(apiProfileEdit, data)
    yield put(FETCH_MENTOR_PROFILE())
    yield put(AUTH_GET_INFO())
  } catch (err) {
    yield put(SET_MESSAGE(handleError(err)))
    yield put(PROFILE_FAILED())
  }
}

function* getMentorVerification() {
  try {
    const response: AxiosResponse = yield call(apiGetVerification)
    const { data } = response
    yield put(SET_VERIFICATION({ ...data }))
  } catch (err) {
    yield put(SET_MESSAGE(handleError(err)))
    yield put(VERIFICATION_FAILED())
  }
}

function* editVerification(action: PayloadAction<IVerification>) {
  try {
    const data = action.payload
    yield call(apiVerificationEdit, data)
    yield put(FETCH_MENTOR_VERIFICATION())
  } catch (err) {
    yield put(SET_MESSAGE(handleError(err)))
    yield put(VERIFICATION_FAILED())
  }
}

function* getMentorBankAccount() {
  try {
    const response: AxiosResponse = yield call(apiGetBankAccount)
    const { data } = response
    yield put(SET_BANK_ACCOUNT({ ...data }))
  } catch (err) {
    yield put(SET_MESSAGE(handleError(err)))
    yield put(BANK_ACCOUNT_FAILED())
  }
}

function* editBankAccount(action: PayloadAction<IVerification>) {
  try {
    const data = action.payload
    yield call(apiBankAccountEdit, data)
    yield put(FETCH_MENTOR_BANK_ACCOUNT())
  } catch (err) {
    yield put(SET_MESSAGE(handleError(err)))
    yield put(BANK_ACCOUNT_FAILED())
  }
}

function* changePassword(action: PayloadAction<IChangePassword>) {
  try {
    const data = action.payload
    yield call(apiChangePassword, data)
    yield put(CHANGE_PASSWORD_SUCCESS())
    yield put(
      SET_MESSAGE({
        type: 'success',
        content: 'change_password_success',
      })
    )
    yield put(AUTH_LOGOUT())
  } catch (err) {
    yield put(SET_MESSAGE(handleError(err)))
    yield put(CHANGE_PASSWORD_FAILED())
  }
}

export default function* informationSaga() {
  const filteredSagas: ForkEffect[] = [
    takeLatest(FETCH_DATA, getData),

    takeLatest(FETCH_MENTOR_PROFILE, getMentorProfile),
    takeLatest(EDIT_PROFILE, editProfile),

    takeLatest(FETCH_MENTOR_VERIFICATION, getMentorVerification),
    takeLatest(EDIT_VERIFICATION, editVerification),

    takeLatest(FETCH_MENTOR_BANK_ACCOUNT, getMentorBankAccount),
    takeLatest(EDIT_BANK_ACCOUNT, editBankAccount),

    takeLatest(CHANGE_PASSWORD_REQUEST, changePassword),
  ]
  yield all(filteredSagas)
}
