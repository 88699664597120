import client from '@/api/axiosClient'
import {
  JobManagementParams,
  Service,
  BoxSettingServiceDetailParamsRequest,
  BoxSettingServiceDetailParams,
  JobManagementServiceListParams,
} from '@/interfaces'

export const apiGetAmount = () => client.get('/fitness/service/amount')

export const apiGetListBooking = (params: JobManagementParams) =>
  client.get('/fitness/reservations', {
    params: {
      page: params.page,
      type: params.type,
      status: params.status,
      sort: params.sort,
      search: params.search,
    },
  })

export const apiGetListService = (params: JobManagementServiceListParams) =>
  client.get('/fitness/service/service-list', {
    params: {
      page: params.page,
      type: params.type,
      sort: params.sort,
    },
  })

export const apiGetServiceInfo = (id: string) =>
  client.get(`/fitness/reservations/${id}/service-info`)

export const apiCreateService = (data: Service) =>
  client.post('/fitness/service', data)

export const apiUpdateService = (data: Service) =>
  client.put(`/fitness/service/${data.id}`, data)

export const apiGetParticipant = (id: string) =>
  client.get(`/fitness/reservations/${id}/participants`)
export const apiGetListServiceDetail = (params: string) =>
  client.get(`/fitness/service/${params}`)
export const apiGetListFriendRequest = (params: JobManagementParams) =>
  client.get('/fitness/friends', {
    params: {
      page: params.page,
      service_name: params.service_name,
      status: params.status,
      sort: params.sort,
      search: params.search,
    },
  })

export const apiApproveFriendRequest = (params: number) =>
  client.post(`/fitness/friends/${params}/approve`)

export const apiRejectFriendRequest = (params: number) =>
  client.post(`/fitness/friends/${params}/reject`)

export const apiGetBoxSettingServiceDetail = (
  request: BoxSettingServiceDetailParamsRequest
) =>
  client.get(`/fitness/service/${request.id}/box-list`, {
    params: {
      group_box_id1: request.params.group_box_id1,
      group_box_id2: request.params.group_box_id2,
      date_time: request.params.date_time,
      box_name: request.params.box_name,
      page: request.params.page,
      results_per_page: request.params.results_per_page,
      prefecture: request.params.prefecture,
    },
  })

export const apiGetGroup1 = () => client.get(`fitness/groups/group-list1`)

export const apiGetGroup2 = (data: any) =>
  client.get(`fitness/groups/group-list2`, {
    params: {
      group_id1: data,
    },
  })

export const apiGetBoxEditDetail = (
  request: BoxSettingServiceDetailParamsRequest
) =>
  client.get(`fitness/service/${request.id}/box-detail`, {
    params: {
      box_id: request.params.box_id,
      date_time: request.params.date_time,
    },
  })

export const apiSetBox = (data: BoxSettingServiceDetailParams) =>
  client.post(`fitness/service/${data.service_id}/set-box`, {
    box_id: data.box_id,
    date: data.date,
    loop_type: data.loop_type,
    loop_end_date: data.loop_end_date,
    loop_start_date: data.loop_start_date,
    time: data.time,
    pack_id: data.pack_id,
    pack_item_id: data.pack_item_id,
  })

export const apiGetMirrorCodeByShopId = (params: number) =>
  client.get(`fitness/boxes/${params}/get-mirror-info`)

export const apiRecordVideoFreelancer = (
  params: number,
  data: {
    reservation_id: number,
    room_id: string
  }
) => client.post(`fitness/boxes/${params}/video-record`, data)

export const apiDeleteService = (serviceId: number) =>
  client.delete(`fitness/service/${serviceId}`)

// 250064: api delete service box
export const apiDeleteServiceBox = (serviceBoxId: number) =>
  client.delete(`fitness/service/service-box/${serviceBoxId}`)

export const apiCheckButtonDelete = (serviceBoxId: number) =>
  client.get(`fitness/service/service-box/${serviceBoxId}`)

export const apiGetIpCamera = (mirrorCode: string) =>
  client.get(`fitness/boxes/${mirrorCode}/master-link`)

export const apiGetUsername = (userIds: string) =>
  client.get('fitness/boxes/participants-name', {
    params: {
      userIds,
    },
  })

export const apiUpdateIP = ({
  mirrorCode,
  cameraIP,
}: {
  mirrorCode: string
  cameraIP: string
}) =>
  client.post(`fitness/boxes/${mirrorCode}/update-master-link`, {
    ip_address: cameraIP,
  })

// 264021: api get current date from backend
export const apiGetCurrentDate = () =>
  client.get('fitness/reservations/current-date')

export const getListPackItem = (packId: string, serviceId: string) =>
  client.get(`fitness/boxes/${serviceId}/${packId}/pack-items`)

export const apiDeleteServiceBoxes = (serviceBoxId: any, boxIds: any[], dateFrom: string, dateTo: string) =>
  client.post(`fitness/service/${serviceBoxId}/delete-service-boxes`, { boxIds, dateFrom, dateTo })

// 286898 set general services details
export const apiGeneralServices = () =>
  client.get('fitness/service/general/details')

// 286898 set service box to multiple box
export const apiSetServiceBoxes = (date: string, boxIds: any[], listData: any[]) =>
  client.post(`fitness/service/set-service-boxes`, { boxIds, listData, date })
