import React, { useState, useEffect, useRef, useMemo } from 'react'
import { Box, Avatar, Stack, IconButton, Typography } from '@mui/material'
import { Delete } from '@mui/icons-material'
import { UploadImageProps } from '@/interfaces'
import { apiUpload } from '@/api/uploadImage'
import { handleMessageError } from '@/utils/helper'
import { useAppDispatch } from '@/redux/hooks'
import { SET_LOADING } from '@/redux/reducers/app.slice'
import ConfirmDeleteModal from '@components/common/ConfirmDeleteModal'

function UploadImage({
  type,
  size,
  imageURL,
  formType,
  children,
  onChange,
  idUpload,
  isDelete,
  errMessage,
  maxSize,
  setShowAdvise,
  isRequired,
  requiredMessage,
  permitUpload = false,
}: UploadImageProps) {
  const imageRef = useRef<HTMLInputElement>(null)
  const [toggleModal, setToggleModal] = useState<boolean>(false)
  const dispatch = useAppDispatch()
  const [imageUpload, setImageUpload] = useState({
    isLoading: false,
    imageURL,
    err: false,
    uploadError: '',
  })

  // Handle upload image (type, file, size)
  const handleUpload = async (file: any) => {
    if (file) {
      setImageUpload({ ...imageUpload, isLoading: true, err: false })
      if (maxSize && file.size >= maxSize * 1024 ** 2) {
        if (setShowAdvise) {
          setShowAdvise(true)
        }
        setImageUpload({
          isLoading: false,
          imageURL: '',
          err: true,
          uploadError: errMessage || '',
        })
        return
      }
      if (setShowAdvise) {
        setShowAdvise(false)
      }
      const iconForm = new FormData()
      iconForm.append('type', type)
      iconForm.append('file', file)

      try {
        dispatch(SET_LOADING(true))
        const response = await apiUpload(iconForm)
        const { id } = response.data
        onChange(id)
        setImageUpload({
          ...imageUpload,
          imageURL: response.data.file_url,
          isLoading: false,
          err: false,
        })
      } catch (error) {
        setImageUpload({
          imageURL: '',
          isLoading: false,
          err: true,
          uploadError: handleMessageError(error),
        })
      } finally {
        dispatch(SET_LOADING(false))
        if (imageRef.current) {
          imageRef.current.value = ''
        }
      }
    }
  }
  const handleClearValue = () => {
    if (imageRef.current) {
      imageRef.current.value = ''
    }
  }

  // Handle remove image
  const handleConfirmDelete = () => {
    onChange(null)
    setImageUpload({ ...imageUpload, imageURL: '' })
    handleClearValue()
    if (setShowAdvise) {
      setShowAdvise(true)
    }
    setToggleModal(false)
  }

  useEffect(() => {
    setImageUpload({ ...imageUpload, imageURL })
    if (imageURL?.length !== 0 && setShowAdvise) {
      setShowAdvise(false)
    }
  }, [imageURL])

  const renderRequiredImage = useMemo(() => {
    if (isRequired) {
      return (
        <Typography variant="body2" color="error">
          {requiredMessage}
        </Typography>
      )
    }
    return <> </>
  }, [requiredMessage])

  return (
    <Stack
      direction="column"
      spacing={1}
      sx={{ wordBreak: 'break-word' }}
      maxWidth="50%"
    >
      <label
        htmlFor={idUpload}
        style={{ width: size.width, height: size.height }}
      >
        <Stack direction="row" spacing={3} alignItems="center">
          <Avatar
            className="avatar-upload"
            src={imageUpload.imageURL}
            alt="Avatar"
            variant="square"
            sx={{
              width: size.width,
              height: size.height,
              borderRadius: 2,
              cursor:
                formType === 'view' || permitUpload ? 'default' : 'pointer',
              objectFit: 'cover',
            }}
          >
            {children}
          </Avatar>
          <input
            ref={imageRef}
            disabled={formType === 'view' || permitUpload}
            style={{ display: 'none' }}
            id={idUpload}
            type="file"
            accept="image/png, image/jpeg, image/jpg"
            onChange={e => {
              if (e.target.files) {
                handleUpload(e.target.files[0])
              }
            }}
          />
          {isDelete && imageUpload.imageURL !== '' && (
            <Box>
              <IconButton
                disabled={type === 'view'}
                onClick={() => setToggleModal(true)}
              >
                <Delete />
              </IconButton>
            </Box>
          )}
        </Stack>
      </label>
      {imageUpload.err ? (
        <Typography variant="body2" color="error">
          {imageUpload.uploadError || errMessage}
        </Typography>
      ) : (
        <> </>
      )}
      {renderRequiredImage}
      <ConfirmDeleteModal
        openDialog={toggleModal}
        setOpenDialog={setToggleModal}
        onConfirmDialog={handleConfirmDelete}
      />
    </Stack>
  )
}

export default React.memo(UploadImage)
