import React, { useEffect, useState } from 'react'
import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm, Controller } from 'react-hook-form'
import {
  Paper,
  Breadcrumbs,
  Box,
  Divider,
  Stack,
  Button,
  TextField,
  Grid,
  Typography,
  RadioGroup,
  Radio,
  FormControlLabel,
  FormLabel,
} from '@mui/material'
import CheckboxCommon from '@/components/common/CheckboxCommon'
import SelectCommon from '@/components/common/SelectCommon'
import UploadImage from '@/components/common/UploadImage'
import { FormType } from '@/interfaces'
import { NavigateNext, PhotoSizeSelectActual } from '@mui/icons-material'
import '@/assets/styles/pages/serviceDetail.scss'
import { Link, useParams, useNavigate, generatePath } from 'react-router-dom'
import { useAppSelector, useAppDispatch } from '@/redux/hooks'
import { Trans, useTranslation } from 'react-i18next'
import { ROUTER_PATH } from '@/constants/router'
import {
  FETCH_LIST_SERVICE_DETAIL,
  SET_LIST_SERVICE_DETAIL,
} from '@/redux/reducers/jobManagement.slice'
import ConfirmDialog from '@components/common/ConfirmDialog'
import { DEFAULT_LANGUAGE, NUMBER_REGEX } from '@/constants'
import {
  renderLabelFormType,
  getValueMasterData,
  formatPrice,
  handleError,
} from '@/utils'
import useMasterData from '@/hooks/useMasterData'
import { apiCreateService, apiUpdateService } from '@/api/jobManagement'
import {
  RESET_MESSAGE,
  SET_LOADING,
  SET_MESSAGE,
} from '@/redux/reducers/app.slice'
import Tooltip from 'antd/es/tooltip'

const DUMMY_SERVICE = {
  id: undefined,
  mentor_id: undefined,
  name: '',
  type: 0,
  price: 0,
  group_limit: 0,
  time: 0,
  training_type: [] as any[],
  description: '',
  deleted_at: '',
  created_at: '',
  updated_at: '',
  banner: '',
  service_box: [],
  subscription_flg: 0,
  totalUseFromNow: 0,
}

function Detail() {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const params = useParams()
  const [formType, setFormType] = useState<FormType>('view')
  const [openDialog, setOpenDialog] = useState<boolean>(false)
  const [confirmSubmit, setConfirmSubmit] = useState<any>({})

  const { listServiceDetail } = useAppSelector(state => state.jobManagement)
  const { languageMaster } = useAppSelector(state => state.app)
  const { currentUser } = useAppSelector(state => state.auth)
  const [valueRadio, setValueRadio] = useState(0)

  const trainingType = getValueMasterData(
    useMasterData('freelance_service_type')
  )

  useEffect(() => {
    setValueRadio(listServiceDetail?.subscription_flg ? listServiceDetail?.subscription_flg : 0)
  }, [listServiceDetail?.subscription_flg])

  const onSubmit = (data: any) => {
    const submitData = {
      ...data,
      upload_id: Number(data.banner),
      training_type: data.training_type.join(','),
      price: currentUser?.type === 1 ? 0 : Number(data.price),
      type: Number(data.type),
      time: Number(data.time),
      subscription_flg: valueRadio,
    }
    setConfirmSubmit(submitData)
    setOpenDialog(!openDialog)
  }

  // Submit data form base formType(create, edit, view)
  const handleConfirmSubmit = async () => {
    if (formType === 'create') {
      try {
        dispatch(SET_LOADING(true))
        dispatch(RESET_MESSAGE())
        await apiCreateService(confirmSubmit)
        navigate(ROUTER_PATH.JOB_MANAGEMENT_SERVICE_LIST)
      } catch (error) {
        dispatch(SET_MESSAGE(handleError(error)))
      } finally {
        dispatch(SET_LOADING(false))
      }
    } else {
      try {
        dispatch(SET_LOADING(true))
        dispatch(RESET_MESSAGE())
        await apiUpdateService(confirmSubmit)
        navigate(ROUTER_PATH.JOB_MANAGEMENT_SERVICE_LIST)
      } catch (error) {
        dispatch(SET_MESSAGE(handleError(error)))
      } finally {
        dispatch(SET_LOADING(false))
      }
    }
  }

  // Validation filed required, length, type,...
  const Schema = Yup.object().shape({
    name: Yup.string()
      .trim()
      .required(t('job_management.service.validation.require'))
      .max(500, t('validation.length')),
    type: Yup.number()
      .moreThan(0, t('job_management.service.validation.require_service'))
      .required(t('job_management.service.validation.require_service'))
      .nullable(true),
    training_type: Yup.array()
      .min(1)
      .required(t('job_management.service.validation.require_checkbox')),
    price: Yup.string()
      .nullable()
      .required(t('job_management.service.validation.price'))
      .matches(
        NUMBER_REGEX,
        t('job_management.service.validation.price_format')
      ),
    time: Yup.number()
      .moreThan(0, t('job_management.service.validation.service_time'))
      .required(t('job_management.service.validation.service_time'))
      .nullable(true),
    group_limit: Yup.number()
      .required(t('job_management.service.validation.join'))
      .moreThan(0, t('job_management.service.validation.join'))
      .nullable(true),
    description: Yup.string()
      .nullable()
      .trim()
      .max(1000, t('validation.length')),
  })
  const { handleSubmit, control, reset } = useForm({
    resolver: yupResolver(Schema),
    defaultValues: listServiceDetail,
    mode: 'onChange',
  })

  // Show text base formType(create, edit, view)
  const textService = (type: string) => {
    switch (type) {
      case 'create':
        return t('job_management.service.breadcrumb.service_detail_add')
      case 'view':
        return t('job_management.service.breadcrumb.service_detail')
      case 'edit':
        return t('job_management.service.breadcrumb.service_detail_edit')
      default:
        return ''
    }
  }

  // Check form type (create, edit, view) base variable formType
  useEffect(() => {
    if (params.state === 'edit') setFormType('edit')
    else if (params.id) setFormType('view')
    else setFormType('create')
  }, [params])

  useEffect(() => {
    if (formType === 'create') {
      reset(DUMMY_SERVICE)
      dispatch(SET_LIST_SERVICE_DETAIL(DUMMY_SERVICE))
    } else {
      reset({
        ...listServiceDetail,
        training_type:
          typeof listServiceDetail?.training_type === 'string'
            ? listServiceDetail?.training_type?.split(',')
            : [],
        type: listServiceDetail?.type,
        time: listServiceDetail?.time,
        group_limit: listServiceDetail?.group_limit,
        price: listServiceDetail?.price,
        subscription_flg: listServiceDetail?.subscription_flg,
      })
    }

    return () => reset(DUMMY_SERVICE)
  }, [formType, listServiceDetail])

  useEffect(() => {
    if (params.id) {
      dispatch(FETCH_LIST_SERVICE_DETAIL(params.id))
    }
  }, [])

  return (
    <Box className="service-detail-container">
      <Breadcrumbs
        separator={<NavigateNext fontSize="small" />}
        aria-label="breadcrumb"
      >
        <Link
          to="/job-management/service"
          className="breadcrumb-text breadcrumb-text-list"
        >
          {t('job_management.service.breadcrumb.list')}
        </Link>
        <Box className="breadcrumb-text">{textService(formType)}</Box>
      </Breadcrumbs>
      <Paper elevation={0} sx={{ marginTop: 1 }}>
        <Box className="service-detail-title">{textService(formType)}</Box>
        <Divider />
        <Grid container spacing={2} className="service-detail-detail">
          <Grid item xs={9}>
            <Box component="form" onSubmit={handleSubmit(onSubmit)}>
              <Stack direction="column" spacing={3}>
                <Controller
                  name="name"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <Tooltip placement="topRight" title={field.value}>
                      <TextField
                        {...field}
                        disabled={
                          formType !== 'view'
                            ? listServiceDetail?.service_box.length !== 0
                            : true
                        }
                        id="name"
                        label={t('job_management.service.title.service_name')}
                        error={!!error}
                        helperText={error?.message}
                        className={
                          formType !== 'view' ? 'text-field-require' : ''
                        }
                        inputProps={{
                          maxLength: 500,
                          style: {
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                          },
                        }}
                      />
                    </Tooltip>
                  )}
                />
                <Controller
                  name="training_type"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <CheckboxCommon
                      api="freelance_training_type"
                      label={t('job_management.service.title.category')}
                      defaultValueMaster={field.value}
                      handleChange={field.onChange}
                      isRequire={
                        formType !== 'view' &&
                        listServiceDetail?.service_box.length === 0
                      }
                      errMessage={t(
                        'job_management.service.validation.require_checkbox'
                      )}
                      isDisable={
                        formType !== 'view'
                          ? listServiceDetail?.service_box.length !== 0
                          : true
                      }
                      isError={!!error}
                      isVertical
                    />
                  )}
                />
                <Controller
                  name="subscription_flg"
                  control={control}
                  render={() => (
                    <div>
                      <FormLabel id="demo-radio-buttons-group-label" required>
                        メニュータイプ
                      </FormLabel>
                      <RadioGroup
                        row
                        aria-label="メニュータイプ"
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        aria-required
                        style={{ marginLeft: '10px' }}
                        value={valueRadio}
                        // defaultValue={valueRadio}
                        onChange={(event: any) => {
                          setValueRadio(Number(event.target.value))
                        }}
                      >
                        <FormControlLabel
                          value={0}
                          control={<Radio />}
                          disabled={
                            formType !== 'view'
                              ? listServiceDetail?.service_box.length !== 0
                              : true
                          }
                          label="通常メニュー"
                        />
                        <FormControlLabel
                          value={1}
                          disabled={
                            formType !== 'view'
                              ? listServiceDetail?.service_box.length !== 0
                              : true
                          }
                          control={<Radio />}
                          label="サブスクメニュー"
                        />
                      </RadioGroup>
                    </div>
                  )}
                />
                <Stack>
                  <Controller
                    name="type"
                    control={control}
                    render={({ field, fieldState: { error } }) =>
                      formType !== 'view' ? (
                        <SelectCommon
                          api="freelance_service_type"
                          label={t('job_management.service.title.form')}
                          handleChange={(e: string) =>
                            field.onChange(Number(e))
                          }
                          errMessage={t(
                            'job_management.service.validation.require_service'
                          )}
                          isRequire
                          isError={!!error}
                          defaultValueMaster={field.value?.toString()}
                          placeHolder={t(
                            'job_management.service.validation.please_select'
                          )}
                          isDisable={
                            listServiceDetail?.service_box.length !== 0
                          }
                        />
                      ) : (
                        <TextField
                          {...field}
                          disabled={
                            formType !== 'view'
                              ? listServiceDetail?.service_box.length !== 0
                              : true
                          }
                          id="type"
                          label={t('job_management.service.title.form')}
                          error={!!error}
                          helperText={error?.message}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          value={
                            languageMaster === DEFAULT_LANGUAGE
                              ? trainingType[Number(field.value)]?.title_jp
                              : trainingType[Number(field.value)]?.title_en
                          }
                        />
                      )
                    }
                  />
                </Stack>
                <Controller
                  name="price"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      {...field}
                      disabled={formType === 'view' || currentUser?.type === 1}
                      id="price"
                      label={renderLabelFormType(
                        t('job_management.service.title.fee'),
                        formType
                      )}
                      error={!!error}
                      helperText={error?.message}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      InputProps={{
                        endAdornment: (
                          <Trans
                            parent="span"
                            i18nKey="job_management.service.title.fee_after"
                          />
                        ),
                      }}
                      inputProps={{ maxLength: 11, min: 0 }}
                      value={
                        formType === 'view'
                          ? `${formatPrice(field.value.toString())} `
                          : formatPrice(field.value.toString()).replaceAll(
                              '-',
                              ''
                            )
                      }
                      className={
                        formType !== 'view' ? 'text-field-require' : ''
                      }
                      onChange={e =>
                        field.onChange(
                          e.target.value.replaceAll(',', '').replaceAll('-', '')
                        )
                      }
                    />
                  )}
                />
                <Stack>
                  <Controller
                    name="time"
                    control={control}
                    render={({ field, fieldState: { error } }) =>
                      formType !== 'view' ? (
                        <SelectCommon
                          api="freelance_service_time"
                          label={t('job_management.service.title.service_time')}
                          defaultValueMaster={field.value?.toString()}
                          handleChange={(e: string) =>
                            field.onChange(Number(e))
                          }
                          isDisable={
                            listServiceDetail?.service_box.length !== 0
                          }
                          isRequire
                          placeHolder={t(
                            'job_management.service.validation.please_select'
                          )}
                          isError={!!error}
                          errMessage={t(
                            'job_management.service.validation.service_time'
                          )}
                        />
                      ) : (
                        <TextField
                          {...field}
                          disabled={
                            formType !== 'view'
                              ? listServiceDetail?.service_box.length !== 0
                              : true
                          }
                          value={
                            formType === 'view'
                              ? `${field.value} ${t(
                                  'job_management.service.title.minutes'
                                )}`
                              : field.value
                          }
                          id="time"
                          label={t('job_management.service.title.service_time')}
                          InputLabelProps={{ shrink: true }}
                          error={!!error}
                          helperText={error?.message}
                        />
                      )
                    }
                  />
                </Stack>
                <Stack>
                  <Controller
                    name="group_limit"
                    control={control}
                    render={({ field, fieldState: { error } }) =>
                      formType !== 'view' ? (
                        <SelectCommon
                          api="freelance_training_limit"
                          label={t('job_management.service.title.group_limit')}
                          defaultValueMaster={field.value?.toString()}
                          handleChange={(e: string) =>
                            field.onChange(Number(e))
                          }
                          isError={!!error}
                          isRequire
                          errMessage={t(
                            'job_management.service.validation.join'
                          )}
                          placeHolder={t(
                            'job_management.service.validation.please_select'
                          )}
                          isDisable={
                            listServiceDetail?.service_box.length !== 0
                          }
                        />
                      ) : (
                        <TextField
                          {...field}
                          disabled={
                            formType !== 'view'
                              ? listServiceDetail?.service_box.length !== 0
                              : true
                          }
                          value={
                            formType === 'view'
                              ? `${field.value} ${t(
                                  'job_management.service.title.people'
                                )}`
                              : field.value
                          }
                          id="group_limit"
                          label={t('job_management.service.title.group_limit')}
                          error={!!error}
                          helperText={error?.message}
                          InputLabelProps={{ shrink: true }}
                        />
                      )
                    }
                  />
                </Stack>
                <Controller
                  name="description"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      {...field}
                      value={field.value || ''}
                      disabled={formType === 'view'}
                      id="description"
                      label={t('job_management.service.title.description')}
                      placeholder={t(
                        'job_management.service.title.description_advise'
                      )}
                      multiline
                      rows={4}
                      error={!!error}
                      helperText={error?.message}
                      inputProps={{ maxLength: 1000 }}
                      InputLabelProps={{ shrink: true }}
                    />
                  )}
                />
                <Stack
                  direction="row"
                  spacing={2}
                  sx={{ alignItems: 'center' }}
                >
                  <Typography fontSize="medium">
                    {t('job_management.service.title.banner')}
                  </Typography>
                  <Typography fontSize="small" sx={{ opacity: 0.8 }}>
                    {t('job_management.service.title.text_banner')}
                  </Typography>
                </Stack>
                <Controller
                  name="banner"
                  control={control}
                  render={({ field }) => (
                    <UploadImage
                      size={{ height: '500px', width: '55vw' }}
                      onChange={field.onChange}
                      type="SERVICE"
                      idUpload="banner"
                      maxSize={5}
                      formType={formType}
                      imageURL={listServiceDetail?.banner || ''}
                      errMessage={t('validation.avatar_invalid')}
                      permitUpload={listServiceDetail?.service_box.length !== 0}
                    >
                      <PhotoSizeSelectActual
                        sx={{ height: '40%', width: '40%' }}
                      />
                    </UploadImage>
                  )}
                />

                {formType !== 'view' && (
                  <Button type="submit" variant="contained">
                    {formType === 'create'
                      ? t('job_management.service.title.register_button')
                      : t('job_management.service.title.save_button')}
                  </Button>
                )}
              </Stack>
            </Box>
          </Grid>
          <Grid container item xs={3} justifyContent="flex-end">
            <Box sx={{ width: '50%' }}>
              {formType === 'view' && (
                <Button
                  variant="contained"
                  color="error"
                  size="small"
                  sx={{ width: '100%' }}
                  onClick={() =>
                    navigate(
                      generatePath(ROUTER_PATH.JOB_MANAGEMENT_SERVICE_EDIT, {
                        id: params.id,
                        state: 'edit',
                      })
                    )
                  }
                >
                  {t('job_management.service.button.edit')}
                </Button>
              )}
            </Box>
          </Grid>
        </Grid>
      </Paper>
      <ConfirmDialog
        openDialog={openDialog}
        setOpenDialog={setOpenDialog}
        dialogContent={t('common.submit_button')}
        onConfirmDialog={handleConfirmSubmit}
      />
    </Box>
  )
}
export default React.memo(Detail)
