import React, { useState, useEffect } from 'react'
import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm, Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Box, Stack, TextField, Button, Typography } from '@mui/material'
import { DoubleArrowRounded } from '@mui/icons-material'
import ConfirmDialog from '@components/common/ConfirmDialog'
import { useAppSelector, useAppDispatch } from '@/redux/hooks'
import {
  FETCH_MENTOR_BANK_ACCOUNT,
  EDIT_BANK_ACCOUNT,
} from '@/redux/reducers/information.slice'
import { renderLabel } from '@/utils'
import { NUMBER_REGEX } from '@/constants/common'
import ComponentLoading from '../common/ComponentLoading'

function BankAccount() {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const [openDialog, setOpenDialog] = useState<boolean>(false)
  const [confirmSubmit, setConfirmSubmit] = useState({})
  const { currentMentor, isLoading } = useAppSelector(
    state => state.information
  )
  const { bankAccount } = currentMentor
  const onSubmit = (data: any) => {
    delete data.id
    setConfirmSubmit(data)
    setOpenDialog(!openDialog)
  }
  const handleConfirmSubmit = () => {
    dispatch(EDIT_BANK_ACCOUNT({ ...confirmSubmit }))
    setOpenDialog(!openDialog)
  }
  // Check validation form field
  const Schema = Yup.object().shape({
    bank_code: Yup.string()
      .trim()
      .nullable(true)
      .required(t('information_edit.validation.bank_code_require'))
      .matches(
        NUMBER_REGEX,
        t('information_edit.validation.bank_code_invalid')
      ),
    bank_branch: Yup.string()
      .trim()
      .nullable(true)
      .required(t('information_edit.validation.bank_branch_require'))
      .matches(
        NUMBER_REGEX,
        t('information_edit.validation.bank_branch_invalid')
      ),
    bank_number: Yup.string()
      .trim()
      .nullable(true)
      .required(t('information_edit.validation.bank_number_require'))
      .matches(
        NUMBER_REGEX,
        t('information_edit.validation.bank_number_invalid')
      ),
    bank_name: Yup.string()
      .trim()
      .nullable(true)
      .required(t('information_edit.validation.bank_name_require')),
  })

  const { handleSubmit, control, reset } = useForm({
    resolver: yupResolver(Schema),
    defaultValues: bankAccount,
    mode: 'all',
  })

  useEffect(() => {
    dispatch(FETCH_MENTOR_BANK_ACCOUNT())
  }, [])

  useEffect(() => {
    reset(bankAccount)
  }, [bankAccount])

  return !isLoading.bankAccount ? (
    <Box component="form" onSubmit={handleSubmit(onSubmit)}>
      <Stack direction="column" spacing={3}>
        <Typography variant="h6" fontWeight="bold">
          {t('information_edit.bank_account_panel.title_1')}
        </Typography>

        <Stack direction="row" alignItems="end">
          <Typography variant="h6" fontWeight="bold">
            {t('information_edit.bank_account_panel.title_2')}
          </Typography>
          <a href="https://zengin.ajtw.net/" target="blank">
            <DoubleArrowRounded sx={{ mb: -0.3 }} />
          </a>
        </Stack>

        <Stack maxWidth="50%">
          <Controller
            name="bank_code"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                id="bankCode"
                value={field.value || ''}
                label={renderLabel(
                  t('information_edit.bank_account_panel.bank_code')
                )}
                placeholder={t(
                  'information_edit.bank_account_panel.placeholder_bank_code'
                )}
                error={!!error}
                helperText={error?.message}
                className="text-field-require"
                inputProps={{ maxLength: 10 }}
              />
            )}
          />
        </Stack>
        <Stack maxWidth="50%">
          <Controller
            name="bank_branch"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                id="branchCode"
                placeholder={t(
                  'information_edit.bank_account_panel.placeholder_bank_branch'
                )}
                label={renderLabel(
                  t('information_edit.bank_account_panel.branch_code')
                )}
                error={!!error}
                helperText={error?.message}
                className="text-field-require"
                inputProps={{ maxLength: 50 }}
              />
            )}
          />
        </Stack>
        <Stack maxWidth="50%">
          <Controller
            name="bank_number"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                id="accountNumber"
                label={renderLabel(
                  t('information_edit.bank_account_panel.account_number')
                )}
                placeholder={t(
                  'information_edit.bank_account_panel.placeholder_bank_number'
                )}
                error={!!error}
                className="text-field-require"
                helperText={error?.message}
                inputProps={{ maxLength: 30 }}
              />
            )}
          />
        </Stack>
        <Stack maxWidth="50%">
          <Controller
            name="bank_name"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                id="accountHolder"
                label={renderLabel(
                  t('information_edit.bank_account_panel.account_holder')
                )}
                placeholder={t(
                  'information_edit.bank_account_panel.placeholder_bank_name'
                )}
                error={!!error}
                helperText={error?.message}
                className="text-field-require"
                inputProps={{ maxLength: 50 }}
              />
            )}
          />
        </Stack>
        <Button type="submit" variant="contained">
          {t('information_edit.bank_account_panel.save_button')}
        </Button>
      </Stack>
      <ConfirmDialog
        openDialog={openDialog}
        setOpenDialog={setOpenDialog}
        dialogContent={t('information_edit.confirm')}
        onConfirmDialog={handleConfirmSubmit}
      />
    </Box>
  ) : (
    <ComponentLoading height="51vh" />
  )
}

export default React.memo(BankAccount)
