import { all, takeLatest, put, call, ForkEffect } from 'redux-saga/effects'
import {
  apiGetParticipant,
  apiGetServiceInfo,
  apiApproveFriendRequest,
  apiGetListBooking,
  apiGetAmount,
  apiGetListFriendRequest,
  apiGetListService,
  apiRejectFriendRequest,
  apiGetListServiceDetail,
  apiCreateService,
  apiUpdateService,
  apiGetGroup1,
  apiGetBoxSettingServiceDetail,
  apiGetGroup2,
  apiGetBoxEditDetail,
  apiSetBox,
} from '@/api/jobManagement'
import { AxiosResponse } from 'axios'
import {
  GET_AMOUNT,
  SET_AMOUNT,
  FETCH_LIST_BOOKING,
  SET_LIST_BOOKING,
  FETCH_LIST_SERVICE,
  SET_LIST_SERVICE,
  FETCH_BOOKING_DETAIL,
  SET_BOOKING_DETAIL,
  FETCH_PARTICIPANT_LIST,
  SET_PARTICIPANT_LIST,
  SET_LIST_SERVICE_DETAIL,
  FETCH_LIST_SERVICE_DETAIL,
  FETCH_LIST_FRIEND_REQUEST,
  SET_LIST_FRIEND_REQUEST,
  APPROVE_LIST_FRIEND_REQUEST,
  REJECT_LIST_FRIEND_REQUEST,
  ADD_SERVICE,
  UPDATE_SERVICE,
  FETCH_BOX_SETTING_DETAIL,
  SET_BOX_SETTING_DETAIL,
  FETCH_GROUP_1,
  SET_GROUP_1,
  SET_GROUP_2,
  FETCH_GROUP_2,
  FETCH_BOX_SETTING_EDIT,
  SET_BOX_SETTING_EDIT,
  SET_BOX_EDIT,
} from '@/redux/reducers/jobManagement.slice'

import {
  SET_LOADING,
  SET_MESSAGE,
  RESET_MESSAGE,
} from '@/redux/reducers/app.slice'
import { handleError } from '@/utils'
import { PayloadAction } from '@reduxjs/toolkit'
import {
  JobManagementParams,
  JobManagementRequestParams,
  Service,
  BoxSettingServiceDetailParamsRequest,
  BoxSettingServiceDetailParams,
  JobManagementServiceListParams,
} from '@/interfaces'

function* getAmount() {
  try {
    yield put(SET_LOADING(true))
    yield put(RESET_MESSAGE())
    const response: AxiosResponse = yield call(apiGetAmount)
    yield put(SET_AMOUNT(response.data))
  } catch (err) {
    yield put(SET_MESSAGE(handleError(err)))
  } finally {
    yield put(SET_LOADING(false))
  }
}

function* getListBooking(action: PayloadAction<JobManagementParams>) {
  try {
    const { payload } = action
    yield put(SET_LOADING(true))
    yield put(RESET_MESSAGE())
    const response: AxiosResponse = yield apiGetListBooking(payload)
    const { data } = response
    yield put(SET_LIST_BOOKING(data.reservation))
  } catch (err) {
    // AxiosError
    yield put(SET_MESSAGE(handleError(err)))
  } finally {
    yield put(SET_LOADING(false))
  }
}

function* getListService(
  action: PayloadAction<JobManagementServiceListParams>
) {
  try {
    const { payload } = action
    yield put(SET_LOADING(true))
    yield put(RESET_MESSAGE())
    const response: AxiosResponse = yield call(apiGetListService, payload)
    const { data } = response
    yield put(SET_LIST_SERVICE(data))
  } catch (err) {
    // AxiosError
    yield put(SET_MESSAGE(handleError(err)))
  } finally {
    yield put(SET_LOADING(false))
  }
}

function* getBookingDetail(action: PayloadAction<string>) {
  try {
    yield put(SET_LOADING(true))
    yield put(RESET_MESSAGE())
    const response: AxiosResponse = yield call(
      apiGetServiceInfo,
      action.payload
    )
    yield put(SET_BOOKING_DETAIL(response.data))
  } catch (err) {
    yield put(SET_MESSAGE(handleError(err)))
  } finally {
    yield put(SET_LOADING(false))
  }
}
function* getListServiceDetail(action: PayloadAction<string>) {
  try {
    const { payload } = action
    yield put(SET_LOADING(true))
    yield put(RESET_MESSAGE())
    const response: AxiosResponse = yield apiGetListServiceDetail(payload)
    const { data } = response
    yield put(SET_LIST_SERVICE_DETAIL(data))
  } catch (err) {
    // AxiosError
    yield put(SET_MESSAGE(handleError(err)))
  } finally {
    yield put(SET_LOADING(false))
  }
}
function* createService(action: PayloadAction<Service>) {
  try {
    const { payload } = action
    const params: JobManagementServiceListParams = {
      page: 1,
    }
    yield put(SET_LOADING(true))
    yield put(RESET_MESSAGE())
    yield apiCreateService(payload)
    const response: AxiosResponse = yield call(apiGetListService, params)
    const { data } = response
    yield put(SET_LIST_SERVICE(data))
  } catch (err) {
    // AxiosError
    yield put(SET_MESSAGE(handleError(err)))
  } finally {
    yield put(SET_LOADING(false))
  }
}
function* updateService(action: PayloadAction<Service>) {
  try {
    const { payload } = action
    const params: JobManagementServiceListParams = {
      page: 1,
    }
    yield put(SET_LOADING(true))
    yield put(RESET_MESSAGE())
    yield call(apiUpdateService, payload)
    const response: AxiosResponse = yield call(apiGetListService, params)
    const { data } = response
    yield put(SET_LIST_SERVICE(data))
  } catch (err) {
    // AxiosError
    yield put(SET_MESSAGE(handleError(err)))
  } finally {
    yield put(SET_LOADING(false))
  }
}
function* getListFriendRequest(action: PayloadAction<JobManagementParams>) {
  try {
    const { payload } = action
    yield put(SET_LOADING(true))
    yield put(RESET_MESSAGE())
    const response: AxiosResponse = yield apiGetListFriendRequest(payload)
    const { data } = response
    yield put(SET_LIST_FRIEND_REQUEST(data))
  } catch (err) {
    // AxiosError
    yield put(SET_MESSAGE(handleError(err)))
  } finally {
    yield put(SET_LOADING(false))
  }
}

function* approveFriendRequest(
  action: PayloadAction<JobManagementRequestParams>
) {
  try {
    const { payload } = action
    yield put(SET_LOADING(true))
    yield put(RESET_MESSAGE())
    yield apiApproveFriendRequest(payload.id)
    const response: AxiosResponse = yield apiGetListFriendRequest(
      payload.params
    )
    const { data } = response
    yield put(SET_LIST_FRIEND_REQUEST(data))
    yield put(SET_MESSAGE({ type: 'success', content: '' }))
  } catch (err) {
    // AxiosError
    yield put(SET_MESSAGE(handleError(err)))
  } finally {
    yield put(SET_LOADING(false))
  }
}

function* getParticipantList(action: PayloadAction<string>) {
  try {
    yield put(SET_LOADING(true))
    yield put(RESET_MESSAGE())
    const response: AxiosResponse = yield call(
      apiGetParticipant,
      action.payload
    )
    yield put(SET_PARTICIPANT_LIST(response.data))
  } catch (err) {
    yield put(SET_MESSAGE(handleError(err)))
  } finally {
    yield put(SET_LOADING(false))
  }
}

function* rejectFriendRequest(
  action: PayloadAction<JobManagementRequestParams>
) {
  try {
    const { payload } = action
    yield put(SET_LOADING(true))
    yield put(RESET_MESSAGE())
    yield apiRejectFriendRequest(payload.id)
    const response: AxiosResponse = yield apiGetListFriendRequest(
      payload.params
    )
    const { data } = response
    yield put(SET_LIST_FRIEND_REQUEST(data))
    yield put(SET_MESSAGE({ type: 'success', content: '' }))
  } catch (err) {
    // AxiosError
    yield put(SET_MESSAGE(handleError(err)))
  } finally {
    yield put(SET_LOADING(false))
  }
}

// Box Setting Detail
function* getBoxSettingServiceDetail(
  action: PayloadAction<BoxSettingServiceDetailParamsRequest>
) {
  try {
    yield put(SET_LOADING(true))
    const { payload } = action
    const response: AxiosResponse = yield apiGetBoxSettingServiceDetail(payload)
    const { data } = response
    yield put(SET_BOX_SETTING_DETAIL(data))
  } catch (err) {
    yield put(SET_MESSAGE(handleError(err)))
  } finally {
    yield put(SET_LOADING(false))
  }
}

// Group 1
function* getGroup1() {
  try {
    yield put(SET_LOADING(true))
    const response: AxiosResponse = yield call(apiGetGroup1)
    yield put(SET_GROUP_1(response.data))
  } catch (err) {
    yield put(SET_MESSAGE(handleError(err)))
  } finally {
    yield put(SET_LOADING(false))
  }
}

// Group 2
function* getGroup2(action: PayloadAction<number[]>) {
  try {
    yield put(SET_LOADING(true))
    const response: AxiosResponse = yield call(apiGetGroup2, action.payload)
    yield put(SET_GROUP_2(response.data))
  } catch (err) {
    yield put(SET_MESSAGE(handleError(err)))
  } finally {
    yield put(SET_LOADING(false))
  }
}

// Box Setting Detail
function* getEditBoxEditDetail(
  action: PayloadAction<BoxSettingServiceDetailParamsRequest>
) {
  try {
    yield put(SET_LOADING(true))
    const { payload } = action
    const response: AxiosResponse = yield call(apiGetBoxEditDetail, payload)
    const { data } = response
    yield put(SET_BOX_SETTING_EDIT(data))
  } catch (err) {
    yield put(SET_MESSAGE(handleError(err)))
  } finally {
    yield put(SET_LOADING(false))
  }
}

// Set Box Edit
function* setBoxEdit(action: PayloadAction<BoxSettingServiceDetailParams>) {
  try {
    const { payload } = action
    yield put(SET_LOADING(true))
    yield apiSetBox(payload)
    const response: AxiosResponse = yield apiGetBoxSettingServiceDetail({
      id: payload.service_id,
      params: {
        date_time: payload.date,
        group_box_id1: [],
        group_box_id2: [],
        box_name: '',
      },
    })
    const { data } = response
    yield put(SET_BOX_SETTING_DETAIL(data))
  } catch (err) {
    // AxiosError
    yield put(SET_MESSAGE(handleError(err)))
  } finally {
    yield put(SET_LOADING(false))
  }
}

export default function* jobManagementSaga() {
  const filteredSagas: ForkEffect[] = [
    takeLatest(GET_AMOUNT, getAmount),
    takeLatest(FETCH_LIST_BOOKING, getListBooking),
    takeLatest(FETCH_LIST_SERVICE, getListService),
    takeLatest(FETCH_BOOKING_DETAIL, getBookingDetail),
    takeLatest(FETCH_PARTICIPANT_LIST, getParticipantList),
    takeLatest(FETCH_LIST_SERVICE_DETAIL, getListServiceDetail),
    takeLatest(FETCH_LIST_FRIEND_REQUEST, getListFriendRequest),
    takeLatest(APPROVE_LIST_FRIEND_REQUEST, approveFriendRequest),
    takeLatest(REJECT_LIST_FRIEND_REQUEST, rejectFriendRequest),
    takeLatest(ADD_SERVICE, createService),
    takeLatest(UPDATE_SERVICE, updateService),
    takeLatest(FETCH_BOX_SETTING_DETAIL, getBoxSettingServiceDetail),
    takeLatest(FETCH_GROUP_1, getGroup1),
    takeLatest(FETCH_GROUP_2, getGroup2),
    takeLatest(FETCH_BOX_SETTING_EDIT, getEditBoxEditDetail),
    takeLatest(SET_BOX_EDIT, setBoxEdit),
  ]
  yield all(filteredSagas)
}
