/* eslint-disable no-plusplus */
import React, { useEffect, useRef, useState } from 'react'
import '@/assets/styles/pages/boxServiceCreate.scss'
import {
  Box,
  Button,
  Checkbox,
  Divider,
  Grid,
  IconButton,
  MenuItem,
  Paper,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import { DatePicker, LocalizationProvider } from '@mui/lab'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import { useTranslation } from 'react-i18next'
import { enUS, ja } from 'date-fns/locale'
import Table, { ColumnsType } from 'antd/es/table'
import {
  DATE_PICKER_INPUT_YMD,
  DATE_STRING_FOR_SCHEDULE,
  DEFAULT_LANGUAGE,
  HOUR_SECOND,
} from '@/constants'
import { useAppSelector, useAppDispatch } from '@/redux/hooks'
import { useForm, useFieldArray, Controller } from 'react-hook-form'
import CloseIcon from '@mui/icons-material/Close'
import { AddTask, Remove } from '@mui/icons-material'
import dayjs from 'dayjs'
import {
  RESET_MESSAGE,
  SET_LOADING,
  SET_MESSAGE,
} from '@/redux/reducers/app.slice'
import {
  apiGeneralServices,
  apiSetServiceBoxes
} from '@/api/jobManagement'
import { handleError } from '@/utils'
import SetServicesResultModal from '@/components/common/SetServicesResultModal'

function BoxServiceCreate() {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const { languageMaster } = useAppSelector(state => state.app)

  const [listChecked, setListChecked] = useState<any>([])
  const [checkAll, setCheckAll] = useState<boolean>(false)
  const [serviceList, setServiceList] = useState<any>([])
  const [packData, setPackData] = useState<any>([])
  const [boxList, setBoxList] = useState<any>([])
  const [hourList, setHourList] = useState<any>([])
  const [cleaningTime, setCleaningTime] = useState<any>(0)
  const [toggleResultDialog, setToggleResultDialog] = useState<boolean>(false)
  const [bookedServiceList, setBookedServiceList] = useState<any[]>([])
  const [data, setData] = useState<any>([])

  const tableRef = useRef<HTMLDivElement>(null)
  const getTableScrollY = () => {
    const extraHeight = 340
    return `calc(100vh - ${extraHeight}px)`
  }

  useEffect(() => {
    try {
      dispatch(SET_LOADING(true))
      dispatch(RESET_MESSAGE())
      const getGeneralServiceDetail = async () => {
        const response = await apiGeneralServices()
        setServiceList(response.data.services)
        setBoxList(response.data.boxes)
        setCleaningTime(response.data.cleaningTime)
        setPackData(response.data.packData)
      }
      getGeneralServiceDetail()
    } catch (error) {
      dispatch(SET_MESSAGE(handleError(error)))
    } finally {
      dispatch(SET_LOADING(false))
    }
  }, [])

  useEffect(() => {
    if (bookedServiceList.length > 0) {
      setToggleResultDialog(true)
    }
  }, [bookedServiceList])

  useEffect(() => {
    if (packData.length > 0) {
      // tao data object
      let tmpData: any[] = []
      for (const pack of packData) {
        const dataItem = tmpData.filter(x => x.pack_title === pack.pack_title)
        if (dataItem.length > 0) {
          const packItems = dataItem[0].pack_items
          const dataPackItems = packItems.filter((x: any) => x.title === pack.pack_item_title)
          if (dataPackItems.length === 0) {
            const newPackItems = [...packItems, {
              title: pack.pack_item_title,
              multi_item: pack.multi_item,
              type: pack.type,
              interval_time: pack.interval_time,
            }]
            const newDataItem = {
              ...dataItem[0],
              pack_items: newPackItems
            }
            tmpData = [
              ...tmpData.filter(x => x.pack_title !== pack.pack_title),
              newDataItem
            ]
          }
        } else {
          tmpData.push({
            subscription_flg: pack.subscription_flg,
            pack_title: pack.pack_title,
            multi_menu: pack.multi_menu,
            pack_items: [
              {
                title: pack.pack_item_title,
                multi_item: pack.multi_item,
                type: pack.type,
                interval_time: pack.interval_time,
              }
            ]
          })
        }
      }
      setData(tmpData)
    }
  }, [packData])

  useEffect(() => {
    // Lay thoi gian (bat dau -> ket thuc) chung cua cac box
    if (boxList.length > 0) {
      let startHour = ''
      let endHour = ''
      // Tim thoi gian bat dau lon nhat, va thoi gian ket thuc nho nhat
      for (const box of boxList) {
        startHour = startHour && startHour > box.hours.split('-')[0] ? startHour : box.hours.split('-')[0]
        endHour = endHour && endHour < box.hours.split('-')[1] ? endHour : box.hours.split('-')[1]
      }
      // Tao array select hour block 10 phut
      const hours: any = []
      for (let i = 0; i < 24; i++) {
        for (let j = 0; j <= 5; j++) {
          const tempHours = `${(i < 10 ? '0' : '') + i  }:${  j  }0`
          if (tempHours >= startHour && tempHours <= endHour) {
            hours.push(tempHours)
          }
        }
      }
      setHourList(hours)
    }
  }, [boxList])
  
  const handleChangeCheckbox = (
    e: React.ChangeEvent<HTMLInputElement>,
    item: string | number
  ) => {
    const { checked } = e.target as HTMLInputElement
    let listCheckedClone = [...listChecked]
    if (checked) {
      listCheckedClone.push(item)
    } else {
      listCheckedClone = listCheckedClone.filter(
        checkedItem => checkedItem !== item
      )
    }
    setListChecked(listCheckedClone)
  }

  const columns: ColumnsType<any> = [
    {
      // eslint-disable-next-line react/no-unstable-nested-components
      title: () => (
        <Checkbox
          checked={checkAll}
          onChange={e => {
            if (e.target.checked) {
              setCheckAll(true)
              setListChecked(boxList?.map((x: any) => x.id))
            } else {
              setCheckAll(false)
              setListChecked([])
            }
          }}
        />
      ),
      width: '20%',
      dataIndex: 'id',
      key: 'id',
      render: text => (
        <Checkbox
          checked={!!listChecked.some((item: any) => item === text)}
          onChange={e => handleChangeCheckbox(e, text)}
        />
      ),
    },
    {
      title: t('job_management.service.service_delete.col_box_id'),
      width: '20%',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: t('job_management.service.service_delete.col_box_name'),
      width: '60%',
      dataIndex: 'nickname',
      key: 'nickname',
    },
  ]

  const today = new Date()
  const listDataDefault = {
    service_id: '',
    pack_id: '',
    pack_item_id: '',
    start_time: '',
    end_time: '',
    pack_options: [],
    pack_item_options: []
  }
  const { control, register, handleSubmit, setValue, getValues } = useForm({
    defaultValues: {
      listData: [listDataDefault],
      date_time: new Date(),
    },
    mode: 'onChange',
  })

  const { fields, append, remove, update } = useFieldArray({
    control,
    name: 'listData',
  })

  const createMultiItemArray = (multiItemStr: string) => {
    const multiItemJson = multiItemStr ? JSON.parse(multiItemStr) : []
    const multiItemArray: any[] = []
    for (const multiItem of multiItemJson) {
      if (multiItem.name === 'hifu') {
        if (Number(multiItem.value) > 0) {
          multiItemArray.push({
            key: 1,
            value: 1
          })
        } else {
          multiItemArray.push({
            key: 1,
            value: 0
          })
        }
      }
      if (multiItem.name === 'cavitation') {
        if (Number(multiItem.value) > 0) {
          multiItemArray.push({
            key: 11,
            value: 1
          })
        } else {
          multiItemArray.push({
            key: 11,
            value: 0
          })
        }
      }
      if (multiItem.name === 'frequency') {
        if (Number(multiItem.value) > 0) {
          multiItemArray.push({
            key: 12,
            value: 1
          })
        } else {
          multiItemArray.push({
            key: 12,
            value: 0
          })
        }
      }
    }
    return multiItemArray
  }

  const onSubmit = async (formData: any) => {
    try {
      dispatch(SET_LOADING(true))
      dispatch(RESET_MESSAGE())
      const date = dayjs(formData.date_time).format(DATE_STRING_FOR_SCHEDULE)
      const { listData } = formData
      const newFormData:any[] = []
      for (const rowData of listData ) {
        for (const boxId of listChecked) {
          const packTitle = rowData.pack_id
          const packItemTitle = rowData.pack_item_id
          const service = serviceList.filter((x:any) => x.id === rowData.service_id)[0]
          const subscriptionFlg = service?.subscription_flg
          const trainingTypeList = service?.training_type.split(',')

          const filterPackItemData = subscriptionFlg === 1 ?
            packData.filter((x: any) => 
              x.adminid === boxId &&
              x.pack_title === packTitle &&
              x.pack_item_title === packItemTitle &&
              x.subscription_flg === 1 &&
              x.interval_time === service?.time
            ) :
            packData.filter((x: any) => 
              x.adminid === boxId &&
              x.pack_title === packTitle &&
              x.pack_item_title === packItemTitle &&
              x.subscription_flg !== 1 &&
              x.interval_time === service?.time
            )

          const validPackItems: any[] = []
          for (const packItemData of filterPackItemData) {
            if (packItemData.multi_menu === 1) {
              let multiItemArray = createMultiItemArray(packItemData.multi_item)
                let isValid = true
                for (const trainingType of trainingTypeList) {
                  if ([1, 11, 12].includes(Number(trainingType))) {
                    if (multiItemArray.filter(x => x.key === Number(trainingType) && x.value === 1).length === 0) {
                      isValid = false
                      break
                    }
                    // loai bo nhung item da check
                    multiItemArray = multiItemArray.filter(x => x.key !== Number(trainingType))
                  }
                }
                // neu trong multi item da het, thi moi valid
                if (isValid && multiItemArray.filter(x => x.value === 1).length === 0) {
                  validPackItems.push(packItemData)
                }
            } else if (
              trainingTypeList.length === 1 &&
              trainingTypeList.includes(String(packItemData.type))
            ) {
              validPackItems.push(packItemData)
            }
          }

          newFormData.push({
            box_id: boxId,
            service_id: rowData.service_id,
            pack_id: validPackItems[0]?.pack_id,
            pack_item_id: validPackItems[0]?.pack_item_id,
            price: service?.price,
            name: service?.name,
            start_time: `${date} ${rowData.start_time}:00`,
            end_time: `${date} ${rowData.end_time}:00`,
            nickname: boxList.filter((x:any) => x.id === boxId)[0]?.nickname
          })
        }
      }
      const response = await apiSetServiceBoxes(date, listChecked, newFormData)
      setBookedServiceList(response.data)
      dispatch(
        SET_MESSAGE({
          type: 'success',
          content: t('job_management.box_setting.box_modal.set_service_success'),
        })
      )
    } catch (error) {
      dispatch(SET_MESSAGE(handleError(error)))
    } finally {
      dispatch(SET_LOADING(false))
    }
  }

  const isDuplicateTime = (start: any, end: any, _index: any) => {
    const listData = getValues('listData')
    let index = 0
    for (const dataDetail of listData) {
      if (_index !== index) {
        // Su dung 2022-09-02 de convert kieu string sang kieu date, de su dung ham dayjs.add minute
        const fieldStart = dataDetail.start_time && dayjs(`2022-09-02T${dataDetail.start_time}`).isValid() ?
          dayjs(`2022-09-02T${dataDetail.start_time}`) : ''
        const fieldEnd = dataDetail.end_time && dayjs(`2022-09-02T${dataDetail.end_time}`).isValid() ?
          dayjs(`2022-09-02T${dataDetail.end_time}`).add(cleaningTime, 'minute') : ''
        if (fieldStart && fieldEnd && (
          (start <= fieldStart && fieldStart < end) ||
          (start < fieldEnd && fieldEnd <= end) ||
          (fieldStart <= start && fieldEnd >= end))
        ) {
          return 1
        }
      }
      index += 1
    }
    return 0
  }

  return (
    <Box
      component="form"
      className="add-service-box-container"
      sx={{ overflowY: 'hidden' }}
      onSubmit={handleSubmit(onSubmit)}
    >
      <Stack
        direction="row"
        divider={<Divider />}
        spacing={2}
        alignItems="center"
        mb={4}
      >
        <Typography variant="h6">
          {t('job_management.box_setting.title')}
        </Typography>
      </Stack>
      <Grid container spacing={2}>
        <Grid item xs={12} sx={{ padding: '10px' }}>
          <Stack width="250px" direction="row" alignItems="center">
            <LocalizationProvider
              dateAdapter={AdapterDateFns}
              locale={DEFAULT_LANGUAGE === languageMaster ? ja : enUS}
            >
              <Controller
                name="date_time"
                control={control}
                render={({ field }) => (
                  <DatePicker
                    {...field}
                    minDate={today}
                    value={field.value}
                    onChange={newValue => {
                      field.onChange(newValue)
                    }}
                    renderInput={params => (
                      <TextField size="small" {...params} />
                    )}
                    mask={DATE_PICKER_INPUT_YMD}
                  />
                )}
              />
              <Button
                type="submit"
                sx={{
                  marginLeft: '15px',
                }}
                disabled={!(listChecked && listChecked.length >= 1)}
                color="info"
                size="small"
                className="btn"
                variant="contained"
              >
                適用
              </Button>
            </LocalizationProvider>
          </Stack>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={8}>
          <Box component={Paper} maxWidth="100%">
            {fields.map((item, index) => (
              <Box
                key={item.id}
                sx={{
                  padding: '20px',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <Controller
                  name={`listData.${index}.service_id`}
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      label={t('job_management.box_setting.box_modal.service')}
                      select
                      {...register(`listData.${index}.service_id`, { required: true })}
                      value={field.value}
                      error={!!error}
                      onChange={async (e: any) => {
                        const serviceId = e.target.value
                        const selectedService = serviceList.filter((service:any) => service.id === serviceId)[0]
                        const subscriptionFlg = selectedService?.subscription_flg ?? 0
                        const trainingTypeList = selectedService?.training_type.split(',')
                        const packs = subscriptionFlg === 1 ?
                          data.filter((d:any) => d.subscription_flg === 1) :
                          data.filter((d:any) => d.subscription_flg !== 1)

                        const validPacks: any[] = []
                        for (const pack of packs) {
                          const packItems = pack.pack_items ?? []
                          const validPackItems: any[] = []
                          for (const packItem of packItems) {
                            // kiem tra dieu kien service time === menu time
                            if (packItem.interval_time === selectedService?.time) {
                              // kiem tra dieu kien type
                              if (pack.multi_menu === 1) {
                                let multiItemArray = createMultiItemArray(packItem.multi_item)
                                let isValid = true
                                for (const trainingType of trainingTypeList) {
                                  if ([1, 11, 12].includes(Number(trainingType))) {
                                    if (multiItemArray.filter(x => x.key === Number(trainingType) && x.value === 1).length === 0) {
                                      isValid = false
                                      break
                                    }
                                    // loai bo nhung item da check
                                    multiItemArray = multiItemArray.filter(x => x.key !== Number(trainingType))
                                  }
                                }
                                // neu trong multi item da het, thi moi valid
                                if (isValid && multiItemArray.filter(x => x.value === 1).length === 0) {
                                  validPackItems.push(packItem.title)
                                }
                              } else if (
                                trainingTypeList.length === 1 &&
                                trainingTypeList.includes(String(packItem.type))
                              ) {
                                validPackItems.push(packItem.title)
                              }
                            }
                          }
                          if (packItems.filter((packItem: any) => validPackItems.includes(packItem.title)).length > 0) {
                            validPacks.push(pack.title)
                          }
                        }
                        update(index, {
                          ...listDataDefault,
                          service_id: serviceId,
                          pack_options: packs.filter((pack: any) => validPacks.includes(pack.title)),
                        })
                      }}
                      InputProps={{
                        endAdornment: (
                          <Box
                            tabIndex={0}
                            role="button"
                            style={{
                              marginRight: '20px',
                              marginTop: '5px',
                              cursor: 'pointer',
                            }}
                            onClick={() => {
                              update(index, {
                                ...listDataDefault,
                              })
                            }}
                          >
                            <CloseIcon />
                          </Box>
                        ),
                      }}
                      sx={{
                        width: '300px !important',
                      }}
                    >
                      {Array.isArray(serviceList) &&
                        serviceList.map((service: any) => (
                          <MenuItem
                            sx={{ height: 36, width: 250 }}
                            key={service.id}
                            value={service.id}
                          >
                            {service.name}
                          </MenuItem>
                        ))}
                    </TextField>
                  )}
                />
                <Controller
                  name={`listData.${index}.pack_id`}
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      label={t('job_management.box_setting.box_modal.menu')}
                      select
                      {...register(`listData.${index}.pack_id`, { required: true })}
                      value={field.value}
                      error={!!error}
                      onChange={async (e: any) => {
                        field.onChange(e.target.value)
                        const serviceId = getValues(`listData.${index}.service_id`)
                        const packTitle = e.target.value

                        // check dieu kien subscription_flg
                        const selectedService = serviceList.filter((service:any) => service.id === serviceId)[0]
                        const subscriptionFlg = selectedService?.subscription_flg ?? 0
                        const packs = subscriptionFlg === 1 ?
                          data.filter((d:any) => d.subscription_flg === 1 && d.pack_title === packTitle) :
                          data.filter((d:any) => d.subscription_flg !== 1 && d.pack_title === packTitle)

                        // check dieu kien multi_menu
                        const trainingTypeList = selectedService?.training_type.split(',')
                        const packItems = packs.length > 0 ? packs[0].pack_items : []
                        const validPackItems: any[] = []
                        for (const packItem of packItems) {
                          // kiem tra dieu kien service time === menu time
                          if (packItem.interval_time === selectedService?.time) {
                            // kiem tra dieu kien type
                            if (packs[0].multi_menu === 1) {
                              let multiItemArray = createMultiItemArray(packItem.multi_item)
                              let isValid = true
                              for (const trainingType of trainingTypeList) {
                                if ([1, 11, 12].includes(Number(trainingType))) {
                                  if (multiItemArray.filter(x => x.key === Number(trainingType) && x.value === 1).length === 0) {
                                    isValid = false
                                    break
                                  }
                                  // loai bo nhung item da check
                                  multiItemArray = multiItemArray.filter(x => x.key !== Number(trainingType))
                                }
                              }
                              // neu trong multi item da het, thi moi valid
                              if (isValid && multiItemArray.filter(x => x.value === 1).length === 0) {
                                validPackItems.push(packItem.title)
                              }
                            } else if (
                              trainingTypeList.length === 1 &&
                              trainingTypeList.includes(String(packItem.type))
                            ) {
                              validPackItems.push(packItem.title)
                            }
                          }
                        }
                        update(index, {
                          ...getValues(`listData.${index}`),
                          pack_item_id: '',
                          pack_item_options: packItems.filter((packItem: any) => validPackItems.includes(packItem.title)),
                        })
                      }}
                      InputProps={{
                        endAdornment: (
                          <Box
                            tabIndex={0}
                            role="button"
                            style={{
                              marginRight: '20px',
                              marginTop: '5px',
                              cursor: 'pointer',
                            }}
                            onClick={() => {
                              field.onChange('')
                              setValue(`listData.${index}.pack_item_id`,'')
                              setValue(`listData.${index}.pack_item_options`,[])
                            }}
                          >
                            <CloseIcon />
                          </Box>
                        ),
                      }}
                      sx={{
                        width: '230px !important',
                      }}
                    >
                      {Array.isArray(getValues(`listData.${index}.pack_options`)) &&
                        getValues(`listData.${index}.pack_options`).map((pack: any) => (
                          <MenuItem
                            sx={{ height: 36 }}
                            key={pack.pack_title}
                            value={pack.pack_title}
                          >
                            {pack.pack_title}
                          </MenuItem>
                        ))}
                    </TextField>
                  )}
                />
                <Controller
                  name={`listData.${index}.pack_item_id`}
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      label={t('job_management.box_setting.box_modal.sub_menu')}
                      select
                      {...register(`listData.${index}.pack_item_id`, { required: true })}
                      value={field.value}
                      onChange={(e: any) => {
                        field.onChange(e.target.value)
                      }}
                      error={!!error}
                      InputProps={{
                        endAdornment: (
                          <Box
                            tabIndex={0}
                            role="button"
                            style={{
                              marginRight: '20px',
                              marginTop: '5px',
                              cursor: 'pointer',
                            }}
                            onClick={() => {
                              field.onChange('')
                            }}
                          >
                            <CloseIcon />
                          </Box>
                        ),
                      }}
                      sx={{
                        width: '230px !important',
                      }}
                    >
                      {Array.isArray(getValues(`listData.${index}.pack_item_options`)) &&
                        getValues(`listData.${index}.pack_item_options`).map((packItem: any) => (
                          <MenuItem
                            sx={{ height: 36 }}
                            key={packItem.title}
                            value={packItem.title}
                          >
                            {packItem.title}
                          </MenuItem>
                        ))}
                    </TextField>
                  )}
                />
                <Controller
                  name={`listData.${index}.start_time`}
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      label={`${t(
                        'job_management.box_setting.box_modal.time'
                      )} ${index + 1}`}
                      select
                      {...register(`listData.${index}.start_time`, { required: true })}
                      value={field.value}
                      onChange={newValue => {
                        const serviceId = getValues(`listData.${index}.service_id`)
                        const serviceTime = serviceList.filter((service:any) => service.id === serviceId)[0]?.time ?? 0
                        const start = newValue.target.value && dayjs(`2022-09-02T${newValue.target.value}`).isValid() ?
                          dayjs(`2022-09-02T${newValue.target.value}`) : ''
                        const end = start ? start.add(serviceTime, 'minute') : ''
                        if (isDuplicateTime(start, end ? end.add(cleaningTime, 'minute') : '', index) === 0) {
                          field.onChange(newValue)
                          setValue(`listData.${index}.end_time`, end ? end.format(HOUR_SECOND) : '')
                        } else {
                          dispatch(SET_MESSAGE({
                            type: 'error',
                            content: t('job_management.box_setting.box_modal.duplicate_time'),
                            code: 404,
                          }))
                        }
                      }}
                      error={!!error}
                      sx={{
                        width: '120px !important',
                      }}
                    >
                      {Array.isArray(hourList) &&
                        hourList.map((_time: any) => (
                          <MenuItem key={_time} value={_time}>
                            {_time}
                          </MenuItem>
                        ))}
                    </TextField>
                  )}
                />
                <Controller
                  name={`listData.${index}.end_time`}
                  control={control}
                  render={({ field }) => (
                    <TextField
                      sx={{
                        width: '120px !important',
                      }}
                      value={field.value}
                      inputProps={{ readOnly: true }}
                    />
                  )}
                />
                {fields.length === 1 && (<Box sx={{minWidth: '50px'}} />)}
                {fields.length > 1 && 
                  <IconButton onClick={() => remove(index)} sx={{marginLeft: '10px'}}>
                    <Remove color="error" />
                  </IconButton>
                }

                {index !== fields.length - 1 && (<Box sx={{minWidth: '50px'}} />)}
                {index === fields.length - 1 && (
                  <IconButton
                    onClick={() => {
                      append(listDataDefault)
                    }}
                    sx={{marginLeft: '10px'}}
                  >
                    <AddTask color="primary" />
                  </IconButton>
                )}
              </Box>
            ))}
          </Box>
        </Grid>
        <Grid item xs={4}>
          <Box component={Paper} maxWidth="100%">
            <Table
              className="table-box-list"
              columns={columns}
              dataSource={boxList}
              rowKey="id"
              ref={tableRef}
              scroll={{ x: 'auto', y: getTableScrollY() }}
              pagination={false}
            />
          </Box>
        </Grid>
      </Grid>
      <SetServicesResultModal
        isOpen={toggleResultDialog}
        toggleModal={setToggleResultDialog}
        dataSource={bookedServiceList}
      />
    </Box>
  )
}

export default React.memo(BoxServiceCreate)
