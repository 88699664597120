import React, { useEffect, useState } from 'react'
import { Box, CircularProgress, Modal, Stack, Typography } from '@mui/material'
import { Cancel } from '@mui/icons-material'
import { useLocation } from 'react-router-dom'
import { useAppDispatch } from '@/redux/hooks'
import { RESET_MESSAGE } from '@/redux/reducers/app.slice'
import { apiActiveEmail } from '@/api/auth'
import { useTranslation } from 'react-i18next'

interface ActiveEmailModalProps {
  toggleModal: any
  setToggleModal(state: any): void
}

function ActiveEmailModal({
  toggleModal,
  setToggleModal,
}: ActiveEmailModalProps) {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const { search } = useLocation()

  const query = new URLSearchParams(search)
  const token = query.get('token')
  const [showError, setShowError] = useState<boolean>(false)

  // send code to active email
  const requestActiveEmail = async () => {
    try {
      dispatch(RESET_MESSAGE())
      if (token) {
        await apiActiveEmail(token)
      }
      setToggleModal({
        ...toggleModal,
        activeEmail: false,
        signUpSuccess: true,
      })
    } catch (error) {
      setShowError(true)
    }
  }

  useEffect(() => {
    if (token) {
      requestActiveEmail()
    }
  }, [token])

  return (
    <Modal
      open={toggleModal.activeEmail}
      onClose={() => setToggleModal({ ...toggleModal, activeEmail: false })}
    >
      <Box
        className="modal-content"
        sx={{ maxWidth: '600px', minWidth: '400px', height: '250px' }}
        display="flex"
        justifyContent="center"
        alignItems="center"
        textAlign="center"
        p={1}
      >
        <Stack direction="column" spacing={3} alignItems="center">
          <Typography className="title" fontSize="32px !important">
            {!showError
              ? t('auth.active_email.title')
              : t('auth.active_email_failed.title')}
          </Typography>
          {!showError ? (
            <CircularProgress size={50} />
          ) : (
            <Cancel sx={{ fontSize: 60 }} color="error" />
          )}
          <Typography variant="h6">
            {!showError
              ? t('auth.active_email.content')
              : t('auth.active_email_failed.content')}
          </Typography>
        </Stack>
      </Box>
    </Modal>
  )
}

export default React.memo(ActiveEmailModal)
