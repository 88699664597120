import { Box, Link as LinkMui, Stack } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
// import { constants, Session } from '@flashphoner/websdk/src/flashphoner-core'
import { apiGetUsername } from '@/api/jobManagement'
import { CusTomHMSPeer } from '@/interfaces/'
import {
  selectAudioTrackByPeerID,
  selectIsPeerAudioEnabled,
  useHMSActions,
  useHMSStore,
} from '@100mslive/react-sdk'
import { Mic, MicOff } from '@mui/icons-material'

interface Props {
  participant: CusTomHMSPeer
  valueVolume: number
  index: number
  handleZoomVideo: (indexZoom: number) => void
  openUserCamera(username: any): void
}
function ParticipantStream({
  participant,
  valueVolume,
  index,
  handleZoomVideo,
  openUserCamera,
}: Props) {
  const videoParticipant = useRef(null)
  const track = useHMSStore(selectAudioTrackByPeerID(participant.id))
  const isAudioEnabled = useHMSStore(selectIsPeerAudioEnabled(participant.id))
  const hmsActions = useHMSActions()
  const [username, setUsername] = useState<string>('')

  useEffect(() => {
    const video: any = videoParticipant.current
    if (video) {
      let child = video.lastElementChild
      while (child) {
        video.removeChild(child)
        child = video.lastElementChild
      }
    }
    if (participant && participant.videoTrack && video) {
      const localTrackID = participant.videoTrack
      hmsActions.attachVideo(localTrackID, video)
    }
  }, [participant])
  useEffect(() => {
    if (track) {
      hmsActions.setVolume(valueVolume, track.id)
    }
  }, [valueVolume])
  // get username participant stream
  const checkUserId = (user: string) => Number(user.split('_')[2])
  const userId = String(checkUserId(participant.name))
  const getUsername = async () => {
    const response: any = await apiGetUsername(userId ?? '')
    setUsername(response.data.length > 0 ? response.data[0].name : '')
  }
  useEffect(() => {
    if (userId !== '') {
      getUsername()
    } else {
      window.close()
    }
  }, [])

  return (
    <Box
      position="relative"
      onClick={() => handleZoomVideo(index)}
      sx={participant.isZoom ? { display: 'none' } : {}}
    >
      <LinkMui
        underline="none"
        sx={!participant.isZoom ? { color: 'white', fontSize: 20 } : {}}
        onClick={() => openUserCamera(participant)}
      >
        {username}
      </LinkMui>
      <Box className="training-stream-participants tds">
        <video ref={videoParticipant} autoPlay muted playsInline />
      </Box>
      <Stack
        className="training-stream-participants-info"
        direction="row"
        width="100%"
        justifyContent="space-between"
      >
        <Box display="none">{participant.name}</Box>
        <Box>
          {isAudioEnabled ? (
            <Mic fontSize="small" />
          ) : (
            <MicOff fontSize="small" />
          )}
        </Box>
      </Stack>
    </Box>
  )
}
export default React.memo(ParticipantStream)
