import {
  MentorInformationState,
  InformationResponse,
  IProfile,
  IVerification,
  IBankAccount,
  IChangePassword,
} from '@/interfaces'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

const initialState: MentorInformationState = {
  mentor: {},
  listService: [],
  currentMentor: {
    profile: {
      id: '',
      username: '',
      email: '',
      type: 0,
      memo: '',
      sns_twitter: '',
      sns_facebook: '',
      sns_instagram: '',
      sns_line: '',
      icon: '',
      avatar: '',
      fre_skills: [
        {
          id: 1,
          skill_name: '',
          skill_description: '',
          level: 0,
          years_exper: 0,
          fre_mentor_id: null,
          skill_desc: '',
        },
      ],
      fre_careers: [
        {
          id: 1,
          desc: '',
          from_ym: '',
          to_ym: '',
          fre_mentor_id: null,
        },
      ],
    },
    verification: {
      id: undefined,
      username: '',
      username_kana: '',
      birthday: '',
      sex: 0,
      phone: '',
      post_code: '',
      address: '',
      address_kana: '',
      inden_pic_before: '',
      inden_pic_before_id: 0,
      inden_pic_after: '',
      inden_pic_after_id: 0,
    },
    bankAccount: {
      id: undefined,
      bank_code: '',
      bank_branch: '',
      bank_number: '',
      bank_name: '',
    },
  },
  isLoading: {
    profile: false,
    verification: false,
    bankAccount: false,
    changePassword: false,
  },
}

export const informationSlice = createSlice({
  name: 'information',
  initialState,
  reducers: {
    FETCH_DATA: () => {},
    SET_DATA: (state, action: PayloadAction<InformationResponse>) => {
      const { mentor, service } = action.payload
      state.listService = service
      state.mentor = mentor
    },

    CLEAR_LIST_SERVICE: state => {
      state.listService = []
      state.mentor = {}
    },

    FETCH_MENTOR_PROFILE: state => {
      state.isLoading = { ...state.isLoading, profile: true }
    },
    SET_MENTOR_PROFILE: (state, action: PayloadAction<IProfile>) => {
      state.currentMentor.profile = action.payload
      state.isLoading = { ...state.isLoading, profile: false }
    },
    EDIT_PROFILE: (state, action: PayloadAction<IProfile>) => {},
    PROFILE_FAILED: state => {
      state.isLoading = { ...state.isLoading, profile: false }
    },

    FETCH_MENTOR_VERIFICATION: state => {
      state.isLoading = { ...state.isLoading, verification: true }
    },
    SET_VERIFICATION: (state, action: PayloadAction<IVerification>) => {
      state.currentMentor.verification = action.payload
      state.isLoading = { ...state.isLoading, verification: false }
    },
    EDIT_VERIFICATION: (state, action: PayloadAction<any>) => {},
    VERIFICATION_FAILED: state => {
      state.isLoading = { ...state.isLoading, verification: false }
    },

    FETCH_MENTOR_BANK_ACCOUNT: state => {
      state.isLoading = { ...state.isLoading, bankAccount: true }
    },
    SET_BANK_ACCOUNT: (state, action: PayloadAction<IBankAccount>) => {
      state.currentMentor.bankAccount = action.payload
      state.isLoading = { ...state.isLoading, bankAccount: false }
    },
    EDIT_BANK_ACCOUNT: (state, action: PayloadAction<IBankAccount>) => {},
    BANK_ACCOUNT_FAILED: state => {
      state.isLoading = { ...state.isLoading, bankAccount: false }
    },

    CHANGE_PASSWORD_REQUEST: (
      state,
      action: PayloadAction<IChangePassword>
    ) => {
      state.isLoading.changePassword = true
    },
    CHANGE_PASSWORD_SUCCESS: state => {
      state.isLoading.changePassword = false
    },
    CHANGE_PASSWORD_FAILED: state => {
      state.isLoading.changePassword = false
    },
  },
})

// Actions
export const {
  FETCH_DATA,
  SET_DATA,
  FETCH_MENTOR_PROFILE,
  SET_MENTOR_PROFILE,
  EDIT_PROFILE,
  PROFILE_FAILED,
  FETCH_MENTOR_VERIFICATION,
  SET_VERIFICATION,
  EDIT_VERIFICATION,
  VERIFICATION_FAILED,
  FETCH_MENTOR_BANK_ACCOUNT,
  SET_BANK_ACCOUNT,
  EDIT_BANK_ACCOUNT,
  BANK_ACCOUNT_FAILED,
  CHANGE_PASSWORD_REQUEST,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_FAILED,
  CLEAR_LIST_SERVICE,
} = informationSlice.actions

// Reducer
const informationReducer = informationSlice.reducer

export default informationReducer
