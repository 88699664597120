import React, { useState, useEffect, useMemo, useCallback, useRef } from 'react'
import '@/assets/styles/pages/serviceDetail.scss'
import Table from 'antd/es/table'
import { ColumnsType } from 'antd/lib/table/interface'
import {
  Box,
  Stack,
  Paper,
  Divider,
  Typography,
  TextField,
  Breadcrumbs,
  MenuItem,
  Button,
  Checkbox,
  Select,
  FormControl,
  InputLabel,
  ListItemText,
  Grid,
} from '@mui/material'
import { SearchOutlined } from '@mui/icons-material'
import { Link, useParams, useNavigate } from 'react-router-dom'
import {
  ROUTER_PATH,
  DEFAULT_LANGUAGE,
  DATE_STRING,
  HOUR_SECOND,
  MINUTE,
  DATE_PICKER_INPUT_YMD,
} from '@/constants'
import { DatePicker } from '@mui/lab'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import BoxSettingModal from '@components/JobManagement/BoxSettingModal'
import { useTranslation } from 'react-i18next'
import { useAppDispatch, useAppSelector } from '@/redux/hooks'
import {
  FETCH_BOX_SETTING_DETAIL,
  FETCH_BOX_SETTING_EDIT,
  FETCH_GROUP_1,
  FETCH_GROUP_2,
  RESET_BOX_SETTING_DETAIL,
} from '@/redux/reducers/jobManagement.slice'
import { BoxSettingService } from '@/interfaces'
import useMasterData from '@/hooks/useMasterData'
import { getEnv, getValueMasterData, showTotalPaginationTable } from '@/utils'
import dayjs from 'dayjs'
import isBetween from 'dayjs/plugin/isBetween'
import { enUS, ja } from 'date-fns/locale'
import Tooltip from 'antd/es/tooltip'

const TIME = Number(getEnv('TIME')) || 0

const SPACE_TIME = 60 / TIME

dayjs.extend(isBetween)

const renderTimeColumns = (createTimeArray: string[], selectedDate: any) =>
  createTimeArray.map((item, index) => ({
    title: item,
    width: 55,
    align: 'center' as const,
    className: 'time-range',
    key: `time-${index}`,
    dataIndex: 'serviceBoxes',
    render: (_: any, records: any) => {
      const dateStart = dayjs().format(DATE_STRING)
      const timeAddStart = records.hours.split('-')[0]
      const timeEndStart = records.hours.split('-')[1]
      // Box status close
      if (records.box_status === 1) {
        return ''
      }
      // Work time is not available
      if (records.isDayOff) {
        return ''
      }
      // Work time of shop
      if (
        dayjs(`${dateStart}T${item}`).isBetween(
          dayjs(`${dateStart}T${timeAddStart}`).subtract(TIME, MINUTE),
          dayjs(`${dateStart}T${timeEndStart}`).add(TIME, MINUTE)
        )
      ) {
        // Checkbox is hide when time is day off
        if (records.shopRests?.length > 0) {
          if (
            records.shopRests?.some((other: any) => {
              const dateValid = dayjs(selectedDate).format(DATE_STRING)
              if (
                dayjs(`${dateValid}T${item}`).isBetween(
                  dayjs(other.start_time),
                  dayjs(other.end_time)
                )
              ) {
                return true
              }
              return false
            })
          ) {
            return ''
          }
        }
        // Checkbox is hide when time has other service added
        if (records.otherServiceBoxes.length > 0) {
          if (
            records.otherServiceBoxes.some((other: any) => {
              const dateValid = dayjs(other.start_time).format(DATE_STRING)
              if (
                dayjs(`${dateValid}T${item}`).isBetween(
                  dayjs(other.start_time),
                  dayjs(other.end_time)
                )
              ) {
                return true
              }
              return false
            })
          ) {
            return ''
          }
          // Checkbox is checked when time has service added
          if (
            records.serviceBoxes.some((record: any) => {
              const dateAdd = dayjs(record.date_time).format(DATE_STRING)
              if (dayjs(record.date_time).format(HOUR_SECOND) === item) {
                return true
              }
              if (
                dayjs(`${dateAdd}T${item}`).isBetween(
                  dayjs(record.date_time).subtract(TIME, MINUTE),
                  dayjs(record.end_time)
                ) ||
                dayjs(`${dateAdd}T${item}`).format(HOUR_SECOND) ===
                  dayjs(record.end_time).format(HOUR_SECOND)
              )
                return true
              return false
            })
          ) {
            return (
              <div className="mui-custom-checkbox">
                <input type="checkbox" checked readOnly />
                <span />
              </div>
            )
          }
          return (
            <div className="mui-custom-checkbox">
              <input type="checkbox" checked={false} disabled />
              <span />
            </div>
          )
        }
        // Checkbox is checked when time has service added
        if (
          records.serviceBoxes.some((record: any) => {
            const dateAdd = dayjs(record.date_time).format(DATE_STRING)
            if (dayjs(record.date_time).format(HOUR_SECOND) === item) {
              return true
            }
            if (
              dayjs(`${dateAdd}T${item}`).isBetween(
                dayjs(record.date_time).subtract(TIME, MINUTE),
                dayjs(record.end_time)
              ) ||
              dayjs(`${dateAdd}T${item}`).format(HOUR_SECOND) ===
                dayjs(record.end_time).format(HOUR_SECOND)
            )
              return true
            return false
          })
        ) {
          return (
            <div className="mui-custom-checkbox">
              <input type="checkbox" checked readOnly />
              <span />
            </div>
          )
        }
        return (
          <div className="mui-custom-checkbox">
            <input type="checkbox" checked={false} disabled />
            <span />
          </div>
        )
      }
      return ''
    },
  }))

function BoxSetting() {
  const { t } = useTranslation()
  const [isEdit, setIsEdit] = useState<boolean>(false)
  const [boxId, setBoxId] = useState<string | number>('')
  const [selectedDate, setSelectedDate] = useState<Date | null>(new Date())
  const [selectedGroup1, setSelectedGroup1] = useState<number[]>([])
  const [selectedGroup2, setSelectedGroup2] = useState<number[]>([])
  const [timeAvailable, setTimeAvailable] = useState<any[]>([])
  const [queryParams, setQueryParams] = useState<{
    selectedGroup1: any[]
    selectedGroup2: any[]
    selectedDate: string
    search: string
    page: number
    results_per_page: number
    prefecture: string
  }>({
    selectedGroup1: [],
    selectedGroup2: [],
    selectedDate: dayjs(selectedDate).format(DATE_STRING),
    search: '',
    prefecture: '',
    page: 1,
    results_per_page: 10,
  })
  const paramDetails = useParams()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  // #251000 fix layout
  const tableRef = useRef<HTMLDivElement>(null)

  const getTableScrollY = () => {
    const extraHeight = 88 // pagination 64 + padding 24
    const tableHeader = tableRef.current?.querySelector('.ant-table-header')
    const tableHeaderBottom = tableHeader?.getBoundingClientRect().bottom
    return `calc(100vh - ${tableHeaderBottom}px - ${extraHeight}px)`
  }

  const { languageMaster, message } = useAppSelector(state => state.app)
  const { data, meta, areas } = useAppSelector(
    state => state.jobManagement.boxSettingServiceDetail
  )

  const { boxSettingServiceDetail } = useAppSelector(
    state => state.jobManagement
  )
  const { currentUser } = useAppSelector(state => state.auth)

  const trainingType = getValueMasterData(
    useMasterData('freelance_training_type')
  )

  // Render time step
  const createTimeArray = useMemo(() => {
    const testColumn: string[] = []
    for (let i = 0; i < 24 * SPACE_TIME; i += 1) {
      testColumn.push(
        `${
          Math.floor(i / SPACE_TIME) >= 10
            ? Math.floor(i / SPACE_TIME)
            : `0${Math.floor(i / SPACE_TIME)}`
        }:${(i % SPACE_TIME) * TIME > 0 ? (i % SPACE_TIME) * TIME : '00'}`
      )
    }
    return testColumn
  }, [])

  // Check if other service fill full available time => disable button edit button
  const timeFull = (record: any): boolean => {
    if (record.otherServiceBoxes.length > 0) {
      record.serviceBoxes.forEach((item: any) => {
        if (
          record.otherServiceBoxes.some((other: any) => {
            if (
              dayjs(item.date_time).isBetween(
                dayjs(other.start_time).subtract(TIME, MINUTE),
                dayjs(other.end_time).add(TIME, MINUTE)
              )
            ) {
              return true
            }
            return false
          })
        ) {
          return false
        }
        return false
      })
    }
    return false
  }

  const dataSource = useMemo(
    () =>
      data?.map(item => ({
        ...item,
        key: item.id,
        selectedTime: item.serviceBoxes
          .flatMap(item1 => item1.date_time)
          .map(item2 => dayjs(item2).format(HOUR_SECOND)),
        begin: item.hours.split('-')[0],
        end: item.hours.split('-')[1],
      })),
    [data]
  )

  const { group1, group2 } = useAppSelector(state => state.jobManagement)

  const serviceType = getValueMasterData(
    useMasterData('freelance_service_type')
  )

  const columns: ColumnsType<BoxSettingService> = [
    {
      title: t('job_management.box_setting.group_1'),
      width: 130,
      align: 'center',
      dataIndex: 'group_box_name_1',
      key: 'group_box_name_1',
      fixed: 'left',
      responsive: ['xs', 'md'],
      ellipsis: { showTitle: false },
      render: text => (
        <Tooltip placement="topLeft" title={text}>
          {text}
        </Tooltip>
      ),
    },
    {
      title: t('job_management.box_setting.group_2'),
      width: 130,
      align: 'center',
      dataIndex: 'group_box_name_2',
      key: 'group_box_name_2',
      fixed: 'left',
      responsive: ['xs', 'md'],
      ellipsis: { showTitle: false },
      render: text => (
        <Tooltip placement="topLeft" title={text}>
          {text}
        </Tooltip>
      ),
    },
    {
      title: t('job_management.box_setting.box_name'),
      width: 140,
      align: 'center',
      dataIndex: 'nickname',
      key: 'boxName',
      fixed: 'left',
      responsive: ['xs', 'md'],
      ellipsis: { showTitle: false },
      render: text => (
        <Tooltip placement="topLeft" title={text}>
          {text}
        </Tooltip>
      ),
    },
    {
      title: t('job_management.box_setting.prefecture'),
      width: 120,
      align: 'center',
      dataIndex: 'area',
      key: 'area',
      fixed: 'left',
      filters: areas?.map((item: any) => ({
        text: item,
        value: item,
      })),
      filterMultiple: false,
      responsive: ['xs', 'md'],
      ellipsis: { showTitle: false },
      render: text => (
        <Tooltip placement="top" title={text}>
          {text}
        </Tooltip>
      ),
    },
    {
      title: t('job_management.box_setting.municipality'),
      width: 140,
      align: 'center',
      dataIndex: 'address',
      key: 'address',
      fixed: 'left',
      responsive: ['xs', 'md'],
      ellipsis: { showTitle: false },
      render: text => (
        <Tooltip placement="topLeft" title={text}>
          {text}
        </Tooltip>
      ),
    },
    ...renderTimeColumns(createTimeArray, selectedDate),
    {
      title: '',
      key: 'operation',
      fixed: 'right',
      width: 100,
      render: record => (
        <Button
          size="small"
          variant="contained"
          disabled={
            record.box_status === 1 || record.isDayOff || timeFull(record)
          }
          onClick={() => {
            dispatch(
              FETCH_BOX_SETTING_EDIT({
                id: paramDetails.id,
                params: {
                  box_id: record.id,
                  date_time: queryParams.selectedDate,
                },
              })
            )
            setBoxId(record.id)
            setIsEdit(!isEdit)
            const timeAvailableClone: any[] = []
            createTimeArray.forEach(item => {
              const dateStart = dayjs().format(DATE_STRING)
              const timeAddStart = record.hours.split('-')[0]
              const timeEndStart = record.hours.split('-')[1]
              if (record.box_status === 1) {
                return
              }
              if (
                dayjs(`${dateStart}T${item}`).isBetween(
                  dayjs(`${dateStart}T${timeAddStart}`).subtract(TIME, MINUTE),
                  dayjs(`${dateStart}T${timeEndStart}`).add(TIME, MINUTE)
                )
              ) {
                if (record.shopRests?.length > 0) {
                  if (
                    record.shopRests?.some((other: any) => {
                      const dateValid = dayjs(selectedDate).format(DATE_STRING)
                      if (
                        dayjs(`${dateValid}T${item}`).isBetween(
                          dayjs(other.start_time),
                          dayjs(other.end_time)
                        )
                      ) {
                        return true
                      }
                      return false
                    })
                  ) {
                    return
                  }
                  timeAvailableClone.push(item)
                  return
                }
                if (record.otherServiceBoxes.length > 0) {
                  if (
                    record.otherServiceBoxes.some((other: any) => {
                      const dateValid = dayjs(other.start_time).format(
                        DATE_STRING
                      )
                      if (
                        dayjs(`${dateValid}T${item}`).isBetween(
                          dayjs(other.start_time),
                          dayjs(other.end_time)
                        )
                      ) {
                        return true
                      }
                      return false
                    })
                  ) {
                    return
                  }
                  timeAvailableClone.push(item)
                  return
                }
                timeAvailableClone.push(item)
              }
            })
            setTimeAvailable(timeAvailableClone)
          }}
        >
          {t('job_management.box_setting.edit_btn')}
        </Button>
      ),
      responsive: ['xs', 'md'],
    },
  ]

  // Handle function pagination, filter of table
  const handleAction = (pagination: any, filters: any) => {
    let queryParamsClone = { ...queryParams }
    queryParamsClone = { ...queryParamsClone, page: pagination.current }
    if (Array.isArray(filters.area))
      queryParamsClone = {
        ...queryParamsClone,
        prefecture: filters.area[0],
        page: queryParams.page === pagination.current ? 1 : pagination.current,
      }
    else
      queryParamsClone = {
        ...queryParamsClone,
        prefecture: '',
        page: queryParams.page === pagination.current ? 1 : pagination.current,
      }
    setQueryParams(queryParamsClone)
  }

  // create an event listener
  // TODO: refactor this function

  const handleChangeGroup1 = (e: any) => {
    setSelectedGroup1(e.target.value)
    if (e.target.value.length > 0) {
      setQueryParams({
        ...queryParams,
        selectedGroup1: e.target.value,
        page: 1,
      })
      dispatch(FETCH_GROUP_2(e.target.value))
    } else {
      setSelectedGroup2([])
      setQueryParams({
        ...queryParams,
        selectedGroup1: [],
        selectedGroup2: [],
        page: 1,
      })
    }
  }

  const handleChangeGroup2 = (e: any) => {
    setSelectedGroup2(e.target.value)
    setQueryParams({
      ...queryParams,
      selectedGroup2: e.target.value,
      page: 1,
    })
  }

  const handleChangeSelectedDate = (newValue: any) => {
    setSelectedDate(newValue as Date)
    if (
      queryParams.selectedDate !==
      dayjs(newValue).format(DATE_STRING)
    ) {
      setQueryParams({
        ...queryParams,
        selectedDate: dayjs(newValue).format(DATE_STRING),
        page: 1,
      })
    }
  }

  useEffect(() => {
    dispatch(FETCH_GROUP_1())
    return () => {
      dispatch(RESET_BOX_SETTING_DETAIL())
    }
  }, [])

  useEffect(() => {
    if (currentUser.status !== 1) {
      navigate(ROUTER_PATH.JOB_MANAGEMENT_SERVICE_LIST)
    }
  }, [currentUser.status])

  useEffect(() => {
    if (paramDetails.id) {
      dispatch(
        FETCH_BOX_SETTING_DETAIL({
          id: paramDetails.id,
          params: {
            date_time: queryParams.selectedDate,
            group_box_id1: queryParams.selectedGroup1,
            group_box_id2: queryParams.selectedGroup2,
            box_name: queryParams.search,
            page: queryParams.page,
            results_per_page: queryParams.results_per_page,
            prefecture: queryParams.prefecture,
          },
        })
      )
    }
  }, [queryParams, paramDetails])

  const fetchBoxSettingParams = useCallback(() => {
    dispatch(
      FETCH_BOX_SETTING_DETAIL({
        id: paramDetails.id,
        params: {
          date_time: queryParams.selectedDate,
          group_box_id1: queryParams.selectedGroup1,
          group_box_id2: queryParams.selectedGroup2,
          box_name: queryParams.search,
          page: queryParams.page,
          results_per_page: queryParams.results_per_page,
          prefecture: queryParams.prefecture,
        },
      })
    )
  }, [paramDetails, queryParams])

  useEffect(() => {
    if (message.code === 403) {
      navigate('/job-management/service', {
        replace: true,
      })
    }
  }, [message])

  return (
    <Box className="service-detail-container">
      <Stack
        direction="row"
        divider={<Divider />}
        spacing={2}
        alignItems="center"
        mb={4}
      >
        <Typography variant="h6">
          {t('job_management.box_setting.title')}
        </Typography>
        <Breadcrumbs>
          <Link
            color="inherit"
            to={ROUTER_PATH.JOB_MANAGEMENT_SERVICE_LIST}
            className="breadcrumb-text breadcrumb-text-list"
          >
            {t('job_management.box_setting.link_1')}
          </Link>
          <Box>{t('job_management.box_setting.link_2')}</Box>
        </Breadcrumbs>
      </Stack>
      <Stack direction="column" spacing={3}>
        <Typography variant="body1" fontWeight="bold">
          {t('job_management.box_setting.service_information')}
        </Typography>
        <Divider />
        <Stack
          direction={{ lg: 'row', md: 'column', xs: 'column', sm: 'column' }}
          spacing={3}
        >
          <Stack width="50%">
            <Tooltip placement="topLeft" title={boxSettingServiceDetail.name}>
              <TextField
                label={t('job_management.box_setting.service_name')}
                value={boxSettingServiceDetail.name}
                disabled
                InputLabelProps={{ shrink: true }}
                inputProps={{
                  style: {
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  },
                }}
                sx={{ width: '50%' }}
              />
            </Tooltip>
          </Stack>
          <Stack direction="row" spacing={2} width="50%">
            <TextField
              label={t('job_management.box_setting.category')}
              value={boxSettingServiceDetail.training_type
                .toString()
                .split(',')
                .map(item => {
                  if (languageMaster === DEFAULT_LANGUAGE) {
                    return trainingType[item]?.title_jp
                  }
                  return trainingType[item]?.title_en
                })
                .join(', ')}
              disabled
              InputLabelProps={{ shrink: true }}
            />
            <TextField
              label={t('job_management.box_setting.form')}
              value={
                languageMaster === DEFAULT_LANGUAGE
                  ? serviceType[boxSettingServiceDetail.type]?.title_jp
                  : serviceType[boxSettingServiceDetail.type]?.title_en
              }
              disabled
              InputLabelProps={{ shrink: true }}
            />
            <TextField
              label={t('job_management.box_setting.service_time')}
              value={`${boxSettingServiceDetail.time} ${t(
                'job_management.service.title.minutes'
              )}`}
              disabled
              InputLabelProps={{ shrink: true }}
            />
            <TextField
              label={t('job_management.box_setting.group_limit')}
              value={`${boxSettingServiceDetail.group_limit} ${t(
                'job_management.box_setting.people'
              )}`}
              disabled
              InputLabelProps={{ shrink: true }}
            />
          </Stack>
        </Stack>
        <Box component={Paper} maxWidth="100%">
          <Box maxWidth="100%">
            <Grid container m={2}>
              <Grid item lg={2} md={4} mr={3}>
                <FormControl>
                  <InputLabel id="group1" sx={{ width: 400 }} shrink>
                    {t('job_management.box_setting.group1')}
                  </InputLabel>
                  <Select
                    labelId="group1"
                    multiple
                    value={selectedGroup1}
                    onChange={handleChangeGroup1}
                    displayEmpty
                    label
                    renderValue={() =>
                      selectedGroup1.length === 0
                        ? t('job_management.box_setting.value_all')
                        : group1
                            .filter((group: any) =>
                              selectedGroup1.includes(group.id)
                            )
                            .map((item: any) => item.name)
                            .join(', ')
                    }
                  >
                    {group1.map((group: any) => (
                      <MenuItem key={group.name} value={group.id}>
                        <Checkbox checked={selectedGroup1.includes(group.id)} />
                        <ListItemText primary={group.name} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item lg={2} md={4} mr={3} mb={1}>
                <FormControl>
                  <InputLabel id="group2" sx={{ width: 400 }} shrink>
                    {t('job_management.box_setting.group2')}
                  </InputLabel>
                  <Select
                    disabled={selectedGroup1.length === 0}
                    labelId="group2"
                    displayEmpty
                    multiple
                    value={selectedGroup2}
                    onChange={handleChangeGroup2}
                    renderValue={() =>
                      selectedGroup2.length === 0
                        ? t('job_management.box_setting.value_all')
                        : group2
                            .filter((group: any) =>
                              selectedGroup2.includes(group.id)
                            )
                            .map((item: any) => item.name)
                            .join(', ')
                    }
                  >
                    {group2.map((group: any) => (
                      <MenuItem key={group.name} value={group.id}>
                        <Checkbox
                          checked={selectedGroup2?.includes(group.id)}
                        />
                        <ListItemText primary={group.name} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item lg={2} md={4} mr={3}>
                <LocalizationProvider
                  dateAdapter={AdapterDateFns}
                  locale={DEFAULT_LANGUAGE === languageMaster ? ja : enUS}
                >
                  <DatePicker
                    label={t('job_management.box_setting.box_modal.date')}
                    value={selectedDate}
                    views={['year', 'month', 'day']}
                    onChange={handleChangeSelectedDate}
                    renderInput={params => (
                      <TextField
                        {...params}
                        onKeyDown={(e: any) => e.preventDefault()}
                      />
                    )}
                    allowSameDateSelection
                    mask={DATE_PICKER_INPUT_YMD}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item lg={5} md={4}>
                <Stack direction="row" justifyContent="end">
                  <TextField
                    className="search-input"
                    sx={{
                      maxWidth: 320,
                    }}
                    InputProps={{
                      startAdornment: <SearchOutlined sx={{ mr: 1 }} />,
                    }}
                    onKeyDown={(event: any) => {
                      if (event.key === 'Enter') {
                        setQueryParams({
                          ...queryParams,
                          search: event.target.value,
                          page: 1,
                        })
                      }
                    }}
                    placeholder={t(
                      'job_management.box_setting.search_placeholder'
                    )}
                  />
                </Stack>
              </Grid>
            </Grid>
          </Box>
          <Box width="100%">
            <Table
              columns={columns}
              dataSource={dataSource}
              ref={tableRef}
              scroll={{ x: 1500, y: getTableScrollY() }}
              onChange={handleAction}
              locale={{
                emptyText: t('common.no_data'),
                filterReset: t('common.reset'),
                filterConfirm: t('common.apply'),
                triggerAsc: t('common.sort_asc'),
                triggerDesc: t('common.sort_desc'),
                cancelSort: t('common.cancel_sort'),
              }}
              pagination={{
                total: meta?.total,
                current: meta?.current_page,
                showSizeChanger: false,
                showTotal: () =>
                  showTotalPaginationTable(
                    meta?.per_page || 0,
                    meta?.current_page || 0,
                    data?.length || 0,
                    meta?.total || 0
                  ),
              }}
            />
          </Box>
        </Box>
      </Stack>
      <BoxSettingModal
        isOpen={isEdit}
        toggleModal={setIsEdit}
        date={selectedDate}
        boxId={boxId}
        createTimeArray={timeAvailable}
        step={Number(boxSettingServiceDetail.time)}
        queryParams={fetchBoxSettingParams}
      />
    </Box>
  )
}

export default React.memo(BoxSetting)
