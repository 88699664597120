export const ROUTER_PATH = {
  LANDING: '/',
  AUTH: {
    SIGN_IN: '/sign-in',
    SIGN_UP: '/sign-up',
    FORGOT_PASSWORD: '/forgot-password',
    RESET_PASSWORD: '/reset-password',
    ACTIVE_EMAIL: '/verify-email',
  },
  DASHBOARD: '/dashboard',
  INFORMATION: '/information',
  JOB_MANAGEMENT_SERVICE: '/job-management/service/:id',
  JOB_MANAGEMENT_SERVICE_CREATE: '/job-management/service/create',
  JOB_MANAGEMENT_SERVICE_EDIT: '/job-management/service/:id/:state',
  JOB_MANAGEMENT_BOOKING: '/job-management/booking/:id',
  JOB_MANAGEMENT_TRAINING: '/job-management/training/:id',
  JOB_MANAGEMENT_TRAINING_NEW_TAB: '/job-management/training/',
  JOB_MANAGEMENT_RECORDED_VIDEO: '/job-management/recorded-video/:id',
  JOB_MANAGEMENT_BOX_SETTING: '/job-management/box-service/:id',
  JOB_MANAGEMENT_BOX_SERVICE_DELETE: '/job-management/box-service-delete/:id',
  JOB_MANAGEMENT_BOX_SERVICE_CREATE: '/job-management/set-to-box',
  CONTACT: '/contact',
  SETTING: '/setting',
  INFORMATION_EDIT: '/setting/edit#profile',
  NOTIFICATIONS: '/notifications',
  JOB_MANAGEMENT_SERVICE_LIST: '/job-management/service',
  JOB_MANAGEMENT_BOOKING_LIST: '/job-management/booking',
  JOB_MANAGEMENT_FRIEND_REQUEST_LIST: '/job-management/friend-request'
}
