import { apiGetUsername } from '@/api/jobManagement'
import { CusTomHMSPeer } from '@/interfaces/hms'
import {
  selectAudioTrackByPeerID,
  useHMSActions,
  useHMSStore,
} from '@100mslive/react-sdk'
import { Box, Link as LinkMui, Stack } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'

interface Props {
  participant: CusTomHMSPeer
  valueVolume: number
  isZoom: boolean
  openUserCamera(username: any): void
}

function ParticipantStreamMain({
  participant,
  valueVolume,
  isZoom,
  openUserCamera,
}: Props) {
  const videoParticipant = useRef<any>(null)
  const track = useHMSStore(selectAudioTrackByPeerID(participant.id))
  const [username, setUsername] = useState<string>('')

  const hmsActions = useHMSActions()

  useEffect(() => {
    const video: any = videoParticipant.current
    if (video) {
      let child = video.lastElementChild
      while (child) {
        video.removeChild(child)
        child = video.lastElementChild
      }
    }
    if (participant && participant.videoTrack && video) {
      const localTrackID = participant.videoTrack
      hmsActions.attachVideo(localTrackID, video)
    }
  }, [participant])
  useEffect(() => {
    if (track) {
      hmsActions.setVolume(valueVolume, track.id)
    }
  }, [valueVolume, isZoom])
  // get username participant stream main
  const checkUserId = (user: string) => Number(user.split('_')[2])
  const userId = String(checkUserId(participant.name))
  const getUsername = async () => {
    const response: any = await apiGetUsername(userId ?? '')
    setUsername(response.data.length > 0 ? response.data[0].name : '')
  }
  useEffect(() => {
    if (userId !== '') {
      getUsername()
    } else {
      window.close()
    }
  }, [])
  return (
    <Box>
      {username !== '' ? (
        <LinkMui
          underline="none"
          sx={
            participant.isZoom
              ? {
                  color: 'white',
                  ml: 24,
                  fontSize: 24,
                  padding: '2px 10px',
                  borderRadius: 10,
                  border: '3px solid #53a8ff',
                }
              : { display: 'none' }
          }
          onClick={() => {
            openUserCamera(participant)
          }}
        >
          {username}
        </LinkMui>
      ) : (
        ''
      )}

      <Box
        className="training-stream-main"
        sx={
          !participant.isZoom
            ? { display: 'none' }
            : { marginTop: 2, marginBottom: 2, width: '100%' }
        }
        position="relative"
      >
        <video ref={videoParticipant} autoPlay muted playsInline />
        <Stack
          className="training-stream-participants-info"
          direction="row"
          width="100%"
          justifyContent="space-between"
        >
          <Box display="none">{participant.name}</Box>
        </Stack>
      </Box>
    </Box>
  )
}
export default React.memo(ParticipantStreamMain)
