import client from '@/api/axiosClient'
import {
  AuthForgotPassword,
  AuthRegister,
  AuthResetPassword,
} from '@/interfaces'

// SignIn
export const apiUserSignIn = ({
  email,
  password,
}: {
  email: string
  password: string
}) => client.post('/fitness/login', { email, password })

// SignUp
export const apiUserRegister = (data: AuthRegister) =>
  client.post('/fitness/register', data)

// Forgot password
export const apiForgotPassword = (data: AuthForgotPassword) =>
  client.post('/fitness/forgot-password', data)

// Reset password
export const apiResetPassword = (data: AuthResetPassword) =>
  client.post('/fitness/reset-password', data)

// Sign out
export const apiSignOut = () => client.post('/fitness/logout')

// Active email
export const apiActiveEmail = (token: string) =>
  client.post('/fitness/verify-email', { token })

// Contact
export const apiContactEmail = (data: any) =>
  client.post('/fitness/landing-page/contact', data)
