import React, { useRef } from 'react'
import Table from 'antd/es/table'
import { ColumnsType } from 'antd/lib/table/interface'
import { JobManagementParams, FriendRequest } from '@/interfaces/jobManagement'
import { useTranslation } from 'react-i18next'
import { Stack, Button, Typography, Avatar } from '@mui/material'
import Tooltip from 'antd/es/tooltip'
import { useAppDispatch, useAppSelector } from '@/redux/hooks'
import DialogFriendRequest from '@/components/JobManagement/DialogFriendRequest'
import {
  APPROVE_LIST_FRIEND_REQUEST,
  REJECT_LIST_FRIEND_REQUEST,
} from '@/redux/reducers/jobManagement.slice'
import AvatarDefault from '@/assets/images/avatar.png'
import useMasterData from '@/hooks/useMasterData'
import { DEFAULT_LANGUAGE } from '@/constants'
import { convertTimeToJapan, getValueMasterData } from '@/utils'
import FilterDropdown from '../common/FilterDropdown'

type ITableFriendRequestProps = {
  queryParamsListFriendRequest: JobManagementParams
  setQueryParamsListFriendRequest: (queryParams: JobManagementParams) => void
}

function TableFriendRequest({
  queryParamsListFriendRequest,
  setQueryParamsListFriendRequest,
}: ITableFriendRequestProps) {
  const dispatch = useAppDispatch()

  const { t } = useTranslation()

  const { listFriendRequest, metaFriendRequest, comboService } = useAppSelector(
    state => state.jobManagement
  )

  const { languageMaster } = useAppSelector(state => state.app)

  const [open, setOpen] = React.useState<boolean>(false)

  const [isApproval, setIsApproval] = React.useState<boolean>(true)

  const [nameParticipant, setNameParticipant] = React.useState<string>('')

  const [idParticipant, setIdParticipant] = React.useState<number>(0)

  const [avatarParticipant, setAvatarParticipant] = React.useState<string>('')

  // Approve/reject friend request
  const onSubmit = () => {
    if (isApproval)
      dispatch(
        APPROVE_LIST_FRIEND_REQUEST({
          id: idParticipant,
          params: queryParamsListFriendRequest,
        })
      )
    else
      dispatch(
        REJECT_LIST_FRIEND_REQUEST({
          id: idParticipant,
          params: queryParamsListFriendRequest,
        })
      )
    setOpen(false)
  }

  const onCancel = () => {
    setOpen(false)
  }

  const tableRef = useRef<HTMLDivElement>(null)

  const getTableScrollY = () => {
    const extraHeight = 88 // pagination 64 + padding 24
    const tableHeader = tableRef.current?.querySelector('.ant-table-header')
    const tableHeaderBottom = tableHeader?.getBoundingClientRect().bottom
    return `calc(100vh - ${tableHeaderBottom}px - ${extraHeight}px)`
  }

  const onClickButton = (record: any) => {
    setOpen(true)
    setNameParticipant(record.user_request_name)
    setIdParticipant(record.id)
    if (record.user_request_avatar)
      setAvatarParticipant(record.user_request_avatar)
    else setAvatarParticipant(AvatarDefault)
  }

  const statusRequest: Array<any> = useMasterData('freelance_invite_status')

  const checkStatusRequest = (status: number) => {
    const statusText = getValueMasterData(statusRequest)
    if (languageMaster === DEFAULT_LANGUAGE) return statusText[status]?.title_jp
    return statusText[status]?.title_en
  }

  const filterDropdown = (props: any) => <FilterDropdown {...props} />

  const columnsFriendRequest: ColumnsType<FriendRequest> = [
    {
      title: t('job_management.table_friend_request.id'),
      dataIndex: 'id',
      key: 'id',
      sorter: true,
      align: 'center',
      width: '5%',
    },
    {
      title: t('job_management.table_friend_request.user'),
      key: 'user_request_name',
      align: 'left',
      width: '10%',
      render: (_, record) => (
        <Stack
          direction="row"
          alignItems="center"
          spacing={2}
          sx={{ height: '100%' }}
        >
          <Avatar
            src={record.user_request_avatar}
            sx={{ width: 58, height: 58, mr: 1 }}
          />
          {record.user_request_name}
        </Stack>
      ),
    },
    {
      title: t('job_management.table_friend_request.friend'),
      key: 'friend_name',
      align: 'left',
      width: '6%',
      dataIndex: 'friend_name',
    },
    {
      title: t(
        'job_management.table_friend_request.time_receive_friend_request'
      ),
      key: 'created_at',
      align: 'center',
      width: '7%',
      render: (_, record) => (
        <Stack direction="column">
          <Typography>
            {convertTimeToJapan(record.created_at || '').format('YYYY/MM/DD')}
          </Typography>
          <Typography>
            {convertTimeToJapan(record.created_at || '').format('HH:mm')}
          </Typography>
        </Stack>
      ),
      sorter: true,
    },
    {
      title: t('job_management.table_friend_request.received_message'),
      dataIndex: 'mentor_message',
      key: 'message',
      align: 'left',
      width: '24%',
      render: (_, record) => (
        <Tooltip
          zIndex={9999}
          placement="topLeft"
          title={record.mentor_message}
        >
          <Typography className="ellipsis-text">
            {record.mentor_message}
          </Typography>
        </Tooltip>
      ),
    },

    {
      title: t('job_management.table_friend_request.service_name'),
      key: 'service_name',
      align: 'left',
      width: '25%',
      dataIndex: 'service_name',
      filters: comboService.map(item => ({
        text: item.name,
        value: item.name,
      })),
      filterDropdown,
      filterMultiple: false,
      render: (_, record) => (
        <Tooltip zIndex={9999} placement="topLeft" title={record.service_name}>
          <Typography className="ellipsis-text">
            {record.service_name}
          </Typography>
        </Tooltip>
      ),
    },
    {
      title: t('job_management.table_friend_request.status'),
      key: 'status',
      align: 'center',
      width: '7%',
      render: (_, record) => checkStatusRequest(record.status),
      filters: [
        {
          text: t('job_management.table_friend_request.request_approve'),
          value: 0,
        },
        { text: t('job_management.table_friend_request.approved'), value: 1 },
        { text: t('job_management.table_friend_request.rejected'), value: 2 },
        { text: t('job_management.table_friend_request.overdue'), value: 3 },
      ],
      filterMultiple: false,
    },
    {
      title: t('job_management.table_friend_request.action'),
      key: 'action',
      width: '6%',
      align: 'center',
      render: (_, record) => (
        <Stack direction="column" justifyContent="space-between" spacing={1}>
          {record.status === 0 && (
            <>
              <Button
                className="btn-reject"
                disabled={record.is_overdue}
                size="small"
                onClick={() => {
                  onClickButton(record)
                  setIsApproval(false)
                }}
              >
                {t('job_management.table_friend_request.button.reject')}
              </Button>
              <Button
                className="btn-approval"
                disabled={record.is_overdue}
                size="small"
                onClick={() => {
                  onClickButton(record)
                  setIsApproval(true)
                }}
              >
                {t('job_management.table_friend_request.button.approval')}
              </Button>
            </>
          )}
        </Stack>
      ),
    },
  ]

  const filterDataFriendRequest = (
    pagination: any,
    filters: any,
    sorter: any
  ) => {
    let queryParamsClone = { ...queryParamsListFriendRequest }
    queryParamsClone = { ...queryParamsClone, page: pagination.current }
    if (Array.isArray(filters.service_name))
      queryParamsClone = {
        ...queryParamsClone,
        service_name: filters.service_name[0],
        page:
          queryParamsListFriendRequest.page === pagination.current
            ? 1
            : pagination.current,
      }
    else
      queryParamsClone = {
        ...queryParamsClone,
        service_name: '',
        page:
          queryParamsListFriendRequest.page === pagination.current
            ? 1
            : pagination.current,
      }
    if (Array.isArray(filters.status))
      queryParamsClone = {
        ...queryParamsClone,
        status: filters.status[0],
        page:
          queryParamsListFriendRequest.page === pagination.current
            ? 1
            : pagination.current,
      }
    else
      queryParamsClone = {
        ...queryParamsClone,
        status: '',
        page:
          queryParamsListFriendRequest.page === pagination.current
            ? 1
            : pagination.current,
      }
    if (sorter.column) {
      if (sorter.order === 'ascend')
        queryParamsClone = {
          ...queryParamsClone,
          sort: `${sorter.column.key}:ASC`,
          page:
            queryParamsListFriendRequest.page === pagination.current
              ? 1
              : pagination.current,
        }
      else
        queryParamsClone = {
          ...queryParamsClone,
          sort: `${sorter.column.key}:DESC`,
          page:
            queryParamsListFriendRequest.page === pagination.current
              ? 1
              : pagination.current,
        }
    } else
      queryParamsClone = {
        ...queryParamsClone,
        sort: '',
        page:
          queryParamsListFriendRequest.page === pagination.current
            ? 1
            : pagination.current,
      }
    setQueryParamsListFriendRequest(queryParamsClone)
  }
  return (
    <>
      <Table
        columns={columnsFriendRequest}
        dataSource={listFriendRequest}
        onChange={filterDataFriendRequest}
        ref={tableRef}
        scroll={{ y: getTableScrollY() }}
        locale={{
          emptyText: t('common.no_data'),
          filterReset: t('common.reset'),
          filterConfirm: t('common.apply'),
          triggerAsc: t('common.sort_asc'),
          triggerDesc: t('common.sort_desc'),
          cancelSort: t('common.cancel_sort'),
        }}
        className="table-friend-request"
        pagination={{
          total: metaFriendRequest.total,
          current: metaFriendRequest.current_page,
          showSizeChanger: false,
          showTotal: () =>
            `${
              metaFriendRequest.per_page *
                (metaFriendRequest.current_page - 1) +
              1
            }-${
              metaFriendRequest.per_page *
                (metaFriendRequest.current_page - 1) +
              listFriendRequest.length
            } / ${metaFriendRequest.total}`,
        }}
      />
      <DialogFriendRequest
        content={`${nameParticipant} ${
          isApproval
            ? t('job_management.table_friend_request.dialog.approve')
            : t('job_management.table_friend_request.dialog.decline')
        }`}
        open={open}
        onCancel={onCancel}
        onSubmit={onSubmit}
        avatar={avatarParticipant}
      />
    </>
  )
}

export default React.memo(TableFriendRequest)
