import React from 'react'
import { Box, CircularProgress } from '@mui/material'
import { IComponentLoadingProps } from '@/interfaces'

function ComponentLoading({ height, width }: IComponentLoadingProps) {
  return (
    <Box
      sx={{
        height,
        width,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <CircularProgress />
    </Box>
  )
}

export default React.memo(ComponentLoading)
