import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import CalendarTodayIcon from '@mui/icons-material/CalendarToday'
import { Button, Menu, MenuItem, Stack } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'

const MENU_ITEM_STYLE = {
  width: 228,
}

interface UnitItem {
  title: string
  value: any
  selected: boolean
}

interface MenuProps {
  items: Array<UnitItem>
  onChange: (value: string) => void
}

function SelectTimeUnit({ items, onChange }: MenuProps) {
  const { t } = useTranslation()

  const [filterOption, setFilterOption] = React.useState<null | HTMLElement>(
    null
  )

  const open = Boolean(filterOption)

  const handleOpenFilter = (event: React.MouseEvent<HTMLButtonElement>) => {
    setFilterOption(event.currentTarget)
  }

  // Change type schedule (Month, week, day)
  const changeFilterOption = (value: any) => {
    onChange(value)
    items.forEach(item => {
      item.selected = item.value === value
    })
    setFilterOption(null)
  }

  return (
    <Stack
      direction="column"
      justifyContent="center"
      alignItems="flex-start"
      className="select-with-icon"
      sx={{ mr: 1 }}
    >
      <Button onClick={handleOpenFilter} className="btn-filter" size="small">
        <CalendarTodayIcon />
        <span>{t(items.find(item => item.selected)?.title || '')}</span>
        <ArrowDropDownIcon fontSize="large" />
      </Button>
      <Menu
        anchorEl={filterOption}
        open={open}
        onClose={() => setFilterOption(null)}
      >
        {items.length > 0 &&
          items.map(item => (
            <MenuItem
              onClick={() => changeFilterOption(item.value)}
              style={MENU_ITEM_STYLE}
              key={item.value}
            >
              {t(item.title)}
            </MenuItem>
          ))}
      </Menu>
    </Stack>
  )
}

export default React.memo(SelectTimeUnit)
