import '@/assets/styles/pages/mentor.scss'
import '@/assets/styles/pages/mentorDetails.scss'
import React from 'react'
import Schedule from '@components/common/Schedule'
import { AppointmentModel } from '@devexpress/dx-react-scheduler'
import SelectTimeUnit from '@components/common/SelectTimeUnit'

import {
  Grid,
  Box,
  Stack,
  Paper,
  Tab,
  Tabs,
  Typography,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableHead,
  TableBody,
  Pagination,
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import { TabPanelProps } from '@/interfaces'

const data = [
  {
    id: 'a001',
    name: 'Ahihi',
    time: '17:30',
    ipAddress: '1.0.1.0',
  },
  {
    id: 'a002',
    name: 'Ahoho',
    time: '18:30',
    ipAddress: '1.1.1.0',
  },
  {
    id: 'a003',
    name: 'Ahaha',
    time: '19:30',
    ipAddress: '1.1.0.0',
  },
  {
    id: 'a004',
    name: 'Ahehe',
    time: '20:30',
    ipAddress: '1.1.1.1',
  },
  {
    id: 'a005',
    name: 'Ahehe',
    time: '21:30',
    ipAddress: '0.1.1.1',
  },
  {
    id: 'a006',
    name: 'Ahehe',
    time: '21:30',
    ipAddress: '0.1.1.1',
  },
  {
    id: 'a007',
    name: 'Ahehe',
    time: '21:30',
    ipAddress: '0.1.1.1',
  },
  {
    id: 'a008',
    name: 'Ahehe',
    time: '21:30',
    ipAddress: '0.1.1.1',
  },
]

const DUMMY_PROFILE = {
  fullName: 'Chau Map',
  id: 'm001',
  birthday: '20/11/1987',
  startedDate: '20/11/2021',
  recentActivity: '08:00',
  subscribers: 120,
  email: 'ahihi@ahoho.com',
  phoneNumber: '0909119119',
  status: 'active',
}

function TabPanel({ index, value, children }: TabPanelProps) {
  return <div>{value === index && <Box>{children}</Box>}</div>
}
const listItem = [
  {
    title: 'Day',
    value: 'day',
    selected: true,
  },
  { title: 'Month', value: 'month', selected: false },
]

const appointments: Array<AppointmentModel> = [
  {
    startDate: '2022-03-04T08:30',
    endDate: '2022-03-04T10:25',
    title: 'ヨガコース1',
  },
  {
    startDate: '2022-03-04T08:30',
    endDate: '2022-03-04T09:30',
    title: 'イベント1',
  },
]

function Index() {
  const { t } = useTranslation()
  const [value, setValue] = React.useState(1)
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue)
  }

  return (
    <Box className="mentor-detail-container">
      <Grid container direction="column" spacing={5}>
        <Grid item>
          <Stack direction="row" spacing={5}>
            <Paper className="mentors-info" sx={{ padding: 2 }}>
              <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                {t('admin.mentor.mentor_detail.information')}
              </Typography>
              <Box>
                <Stack direction="column" spacing={3} padding={1.5}>
                  {Object.entries(DUMMY_PROFILE).map(row => (
                    <Stack direction="row" spacing={3} key={row[0]}>
                      <Box style={{ width: 120 }}>{row[0]}</Box>
                      <Box>{row[1]}</Box>
                    </Stack>
                  ))}
                </Stack>
              </Box>
            </Paper>
            <Paper className="mentors-calendar">
              <Stack
                direction="row"
                justifyContent="space-between"
              // className="tab-wrapper"
              >
                <Tabs
                  value={value}
                  onChange={handleChange}
                  aria-label="wrapped label tabs example"
                >
                  <Tab
                    className="tab-btn"
                    value={1}
                    label={t('admin.mentor.mentor_detail.event_list')}
                    wrapped
                  />
                  <Tab
                    className="tab-btn"
                    value={2}
                    label={t('admin.mentor.mentor_detail.personal_calendar')}
                  />
                </Tabs>
                <SelectTimeUnit
                  onChange={() => {
                    // TODO (newValue: string) => console.log(newValue)
                  }}
                  items={listItem}
                />
              </Stack>
              <Paper elevation={1} square className="mentors-list">
                <TabPanel index={1} value={value}>
                  <TableContainer component={Paper}>
                    <Table
                      sx={{ minWidth: 500 }}
                      aria-label="custom pagination table"
                    >
                      <TableHead>
                        <TableRow>
                          <TableCell sx={{ width: '25%' }}>
                            <Typography variant="h6">Actions</Typography>
                          </TableCell>
                          <TableCell sx={{ width: '45%' }}>
                            <Typography variant="h6">Activity time</Typography>
                          </TableCell>
                          <TableCell sx={{ width: '30%' }}>
                            <Typography variant="h6">IP Address</Typography>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {data.map(row => (
                          <TableRow key={row.id}>
                            <TableCell>{row.name}</TableCell>
                            <TableCell>{row.time}</TableCell>
                            <TableCell>{row.ipAddress}</TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <Grid display="flex" justifyContent="flex-end">
                    <Pagination count={5} />
                  </Grid>
                </TabPanel>
                <TabPanel index={2} value={value}>
                  <Schedule dataProps={appointments} languageCalendar="en-US" dataSelected="" />
                </TabPanel>
              </Paper>
            </Paper>
          </Stack>
        </Grid>
      </Grid>
    </Box>
  )
}

export default React.memo(Index)
