import React, { useState, useEffect } from 'react'
import * as Yup from 'yup'
import { useForm, Controller } from 'react-hook-form'
import { useLocation } from 'react-router-dom'
import { yupResolver } from '@hookform/resolvers/yup'
import { useAppDispatch, useAppSelector } from '@/redux/hooks'
import {
  Grid,
  Button,
  Box,
  TextField,
  Typography,
  Modal,
  InputAdornment,
  IconButton,
  Stack,
} from '@mui/material'
import { Close, Visibility, VisibilityOff } from '@mui/icons-material'
import { useTranslation } from 'react-i18next'
import { AUTH_USER_RESET_PASSWORD } from '@/redux/reducers/auth.slice'
import { TYPE_MESSAGE, PASSWORD_REGEX } from '@/constants'
import { isShowError } from '@/utils'
import ResetPasswordSuccess from './ResetSuccessModal'

interface ModalProps {
  isOpen: any
  toggleModal(open: any): void
  handleSignIn: () => void
}

function ResetPasswordModal({ isOpen, toggleModal, handleSignIn }: ModalProps) {
  const { t } = useTranslation()
  const handleToggleModal = () => toggleModal(false)
  const dispatch = useAppDispatch()
  const message = useAppSelector(state => state.app.message)

  const [isSubmit, setIsSubmit] = useState(false)
  const [openModal, setOpenModal] = useState(false)

  // Get token
  const { search } = useLocation()
  const query = new URLSearchParams(search)
  const token = query.get('token')
  const time: number = Number(query.get('time')) || 0

  // Show password
  const [showPassword, setShowPassword] = useState(false)
  const [showPasswordConfirm, setShowPasswordConfirm] = useState(false)
  const handleClickShowPassword = () => setShowPassword(!showPassword)
  const handleClickShowPasswordConfirm = () =>
    setShowPasswordConfirm(!showPasswordConfirm)

  // Check validate form
  const defaultValues = {
    password: '',
    confirmPassword: '',
  }

  const ResetSchema = Yup.object().shape({
    password: Yup.string()
      .required(t('validation.password_valid'))
      .min(8, t('validation.password_valid'))
      .max(32, t('validation.password_valid'))
      .matches(PASSWORD_REGEX, t('validation.password_valid')),
    confirmPassword: Yup.string().oneOf(
      [Yup.ref('password'), null],
      t('auth.forgot_password.validation.confirm_valid')
    ),
  })

  const onSubmit = (data: any) => {
    dispatch(
      AUTH_USER_RESET_PASSWORD({
        password: data.password,
        confirm_password: data.confirmPassword,
        token,
      })
    )
    setIsSubmit(true)
  }

  const { handleSubmit, control } = useForm({
    resolver: yupResolver(ResetSchema),
    defaultValues,
    mode: 'onChange',
  })

  useEffect(() => {
    if (message.type === TYPE_MESSAGE.SUCCESS && isSubmit) {
      setOpenModal(true)
      toggleModal(false)
    }
  }, [message])

  return (
    <>
      <Modal open={isOpen.resetPassword} onClose={handleToggleModal}>
        <Box className="modal-content" sx={{ width: '25%' }}>
          <Grid container textAlign="center">
            <Grid item xs>
              <Typography className="title" textAlign="center">
                {t('auth.reset_password.title')}
              </Typography>
            </Grid>
            <Grid item>
              <IconButton onClick={handleToggleModal} className="close-button">
                <Close />
              </IconButton>
            </Grid>
          </Grid>
          {!isShowError(time) ? (
            <Box
              component="form"
              onSubmit={handleSubmit(onSubmit)}
              noValidate
              sx={{ mt: 1 }}
            >
              <Controller
                name="password"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    {...field}
                    type={showPassword ? 'text' : 'password'}
                    margin="normal"
                    fullWidth
                    id="password"
                    label={t('auth.reset_password.password')}
                    error={!!error}
                    autoComplete="off"
                    helperText={error?.message}
                    placeholder={t('validation.password_valid')}
                    inputProps={{ maxLength: 32 }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton onClick={handleClickShowPassword}>
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              />
              <Controller
                name="confirmPassword"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    {...field}
                    type={showPasswordConfirm ? 'text' : 'password'}
                    margin="normal"
                    fullWidth
                    id="confirmPassword"
                    label={t('auth.reset_password.confirm_password')}
                    error={!!error}
                    autoComplete="off"
                    helperText={error?.message}
                    placeholder={t('auth.reset_password.password_confirm')}
                    inputProps={{ maxLength: 32 }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton onClick={handleClickShowPasswordConfirm}>
                            {showPasswordConfirm ? (
                              <Visibility />
                            ) : (
                              <VisibilityOff />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                {t('auth.reset_password.reset_button')}
              </Button>
            </Box>
          ) : (
            <Stack>
              <Typography align="center" color="error" fontSize="large">
                {t('auth.reset_password.error_expired')}
              </Typography>
            </Stack>
          )}
        </Box>
      </Modal>
      <ResetPasswordSuccess
        isOpen={openModal}
        toggleModal={setOpenModal}
        handleSignIn={handleSignIn}
      />
    </>
  )
}

export default React.memo(ResetPasswordModal)
