import { requester } from '@/api/axiosTestConfig'
import { PagingProduct } from '@/interfaces'

const productApi = {
  getAll: ({ page, limit }: PagingProduct) =>
    requester.get('products', {
      page,
      limit,
    }),
}

export default productApi
