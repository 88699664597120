import { Tooltip, Radio, Space, Button } from 'antd'
import { FilterDropdownProps } from 'antd/lib/table/interface'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

function FilterDropdown({
  setSelectedKeys,
  selectedKeys,
  confirm,
  clearFilters,
  filters,
  visible,
}: FilterDropdownProps) {
  const { t } = useTranslation()
  const [filterValue, setFilterValue] = useState<string>('')
  const [valueChanged, setValueChanged] = useState<boolean>(false)

  useEffect(() => {
    if (!visible && valueChanged) {
      confirm()
      setSelectedKeys(selectedKeys)
    }
  }, [visible])

  const handleReset = (clearFilter: any) => {
    clearFilter()
    setFilterValue('')
    setValueChanged(true)
  }

  const handleSubmit = (_confirm: any, _selectedKeys: any) => {
    _confirm()
    setSelectedKeys(_selectedKeys)
  }

  return (
    <div className="custom-filter-dropdown">
      <Radio.Group
        onChange={e => {
          setFilterValue(e.target.value)
          setSelectedKeys([e.target.value])
          setValueChanged(true)
        }}
        value={filterValue}
      >
        <Space direction="vertical" className="radio-items">
          {filters?.map(item => (
            <Radio
              key={`item_${item.value}`}
              className="radio-item"
              value={item.value}
            >
              <Tooltip title={item.text} placement="right">
                {item.text}
              </Tooltip>
            </Radio>
          ))}
        </Space>
      </Radio.Group>
      <Space className="custom-filter-buttons">
        <Button
          disabled={!filterValue}
          size="small"
          onClick={() => {
            handleReset(clearFilters)
          }}
          className="custom-reset-button"
        >
          {t('common.reset')}
        </Button>
        <Button
          size="small"
          type="primary"
          onClick={() => {
            handleSubmit(confirm, selectedKeys)
          }}
        >
          {t('common.apply')}
        </Button>
      </Space>
    </div>
  )
}

export default React.memo(FilterDropdown)