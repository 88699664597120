import '@/assets/styles/layout/main.scss'
import React from 'react'
import { Box, Typography, Stack } from '@mui/material'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import Header from '@/layout/MainLayout/Header'
import { Trans, useTranslation } from 'react-i18next'
import CustomLink from '@components/common/Link'
import { ROUTER_PATH } from '@/constants'

const theme = createTheme({
  palette: {
    mode: 'light',
  },
  components: {},
})

function MainLayout() {
  const { t } = useTranslation()

  return (
    <ThemeProvider theme={theme}>
      <Box className="layout-container">
        <Header />
        <Stack
          direction="column"
          justifyContent="center"
          alignItems="center"
          spacing={5}
          textAlign="center"
          sx={{ height: '90vh' }}
        >
          <Typography variant="h1" sx={{ fontSize: '130px' }}>
            404
          </Typography>
          <Trans parent="h1" i18nKey="page_not_found.title" />
          <CustomLink
            to={ROUTER_PATH.DASHBOARD}
            activeClass="login-link-active"
          >
            {t('sign_in.dont_have_any_account')}
          </CustomLink>
        </Stack>
      </Box>
    </ThemeProvider>
  )
}

export default React.memo(MainLayout)
