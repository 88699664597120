import { useRoutes } from 'react-router-dom'

// routes
import PublicRouter from '@/routes/public'
import PrivateRouter from '@/routes/private'
import NotFoundRouter from '@/routes/not-found'
import CommonRouter from '@/routes/common'

// ==============================|| ROUTING RENDER ||============================== //
export default function Routes() {
  return useRoutes([
    PublicRouter,
    PrivateRouter,
    NotFoundRouter,
    CommonRouter
  ])
}
