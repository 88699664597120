import React, { useState } from 'react'
import * as Yup from 'yup'
import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import {
  Grid,
  Button,
  Box,
  TextField,
  Typography,
  Modal,
  InputAdornment,
  IconButton,
  Stack,
  Checkbox,
  InputLabel,
} from '@mui/material'
import { Close, Visibility, VisibilityOff } from '@mui/icons-material'
import { useTranslation } from 'react-i18next'
import { useAppDispatch, useAppSelector } from '@/redux/hooks'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { EMAIL_REGEX, ROUTER_PATH, TYPE_MESSAGE } from '@/constants'
import { AUTH_USER_LOGIN } from '@/redux/reducers/auth.slice'
import { AuthSignIn } from '@/interfaces'
import ForgotPasswordModal from '@/components/landing/ForgotPasswordModal'

interface ModalProps {
  isOpen: any
  setIsOpen(state: any): void
}

function SignInModal({ isOpen, setIsOpen }: ModalProps) {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const [queryParam] = useSearchParams()
  const handleToggleModal = () => setIsOpen({ ...isOpen, signIn: false })
  const navigate = useNavigate()
  const message = useAppSelector(state => state.app.message)
  const [isSubmit, setIsSubmit] = useState(false)

  const handleForgot = () => {
    setIsOpen({ ...isOpen, forgotPassword: true, signIn: false })
  }

  const handleSignUp = () => {
    setIsOpen({ ...isOpen, signUp: true, signIn: false })
  }

  // Show password
  const [showPassword, setShowPassword] = useState(false)

  const handleClickShowPassword = () => setShowPassword(!showPassword)
  const handleMouseDownPassword = () => setShowPassword(!showPassword)

  const defaultValues = {
    email: '',
    password: '',
    isRemember: false,
  }

  // Check validate form
  const SignInSchema = Yup.object().shape({
    email: Yup.string()
      .trim()
      .required(t('validation.email_required'))
      .matches(EMAIL_REGEX, t('validation.email_invalid'))
      .max(100, t('auth.sign_in.validation.login_valid')),
    password: Yup.string().required(t('validation.password_required')),
  })

  React.useEffect(() => {
    if (message.type === TYPE_MESSAGE.SUCCESS && isSubmit) {
      const pathname = queryParam.get('redirect') || ROUTER_PATH.DASHBOARD
      navigate(pathname, { replace: true })
    }
  }, [message])

  // Submit data sign in
  const onSubmit = (data: AuthSignIn) => {
    dispatch(
      AUTH_USER_LOGIN({
        email: data.email,
        password: data.password,
        isRemember: data.isRemember,
      })
    )
    setIsSubmit(true)
  }

  const { handleSubmit, control } = useForm({
    resolver: yupResolver(SignInSchema),
    defaultValues,
    mode: 'onChange',
  })

  return (
    <>
      <Modal open={isOpen.signIn} onClose={handleToggleModal}>
        <Box className="modal-content" sx={{ width: '25%' }}>
          <Grid container textAlign="center">
            <Grid item xs>
              <Typography className="title" textAlign="center">
                {t('auth.sign_in.title')}
              </Typography>
            </Grid>
            <Grid item>
              <IconButton onClick={handleToggleModal} className="close-button">
                <Close />
              </IconButton>
            </Grid>
          </Grid>
          <Box
            component="form"
            onSubmit={handleSubmit(onSubmit)}
            noValidate
            sx={{ mt: 1 }}
          >
            <Controller
              name="email"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  margin="normal"
                  fullWidth
                  id="email"
                  label={t('auth.sign_in.email_address')}
                  error={!!error}
                  helperText={error?.message}
                  inputProps={{ maxLength: 100 }}
                />
              )}
            />
            <Controller
              name="password"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  type={showPassword ? 'text' : 'password'}
                  margin="normal"
                  fullWidth
                  id="password"
                  label={t('auth.sign_in.password')}
                  error={!!error}
                  helperText={error?.message}
                  autoComplete="off"
                  inputProps={{ maxLength: 32 }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />
            <Stack justifyContent="center" alignItems="center">
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2, width: '200px' }}
              >
                {t('auth.sign_in.sign_in')}
              </Button>
            </Stack>
            <Stack justifyContent="center" alignItems="center" spacing={2}>
              <Box display="flex">
                <Controller
                  name="isRemember"
                  control={control}
                  render={({ field }) => (
                    <Checkbox
                      {...field}
                      value={field.value}
                      onChange={field.onChange}
                    />
                  )}
                />
                <InputLabel shrink sx={{ mt: 2 }}>
                  {t('auth.sign_in.keep_me_sign_in')}
                </InputLabel>
              </Box>
              <Stack spacing={2} textAlign="center">
                <Button
                  onClick={handleSignUp}
                  href=""
                  sx={{ textDecoration: 'underline' }}
                >
                  {t('auth.sign_in.dont_have_account')}
                </Button>
                <Button
                  onClick={handleForgot}
                  href=""
                  sx={{ textDecoration: 'underline' }}
                >
                  {t('auth.sign_in.forgot_password')}
                </Button>
              </Stack>
            </Stack>
          </Box>
        </Box>
      </Modal>
      <ForgotPasswordModal isOpen={isOpen} setIsOpen={setIsOpen} />
    </>
  )
}

export default React.memo(SignInModal)
