import React, { useState, useEffect, useMemo, useRef } from 'react'
import '@/assets/styles/pages/camera.scss'
import '@/assets/styles/pages/training.scss'
import { Grid, Box, Typography } from '@mui/material'
import { useParams } from 'react-router-dom'
import useSocket from '@/hooks/useSocket'
import { generateKey, handleError } from '@/utils'
import { apiGetIpCamera, apiGetUsername, apiUpdateIP } from '@/api/jobManagement'
import { useAppDispatch } from '@/redux/hooks'
import { RESET_MESSAGE } from '@/redux/reducers/app.slice'
import ReactHlsPlayer from 'react-hls-player';

function Camera() {
  const params = useParams()
  const dispatch = useAppDispatch()
  const [cameraList, setCameraList] = useState<any[]>([])
  const [cameraIp, setCameraIp] = useState<string>('')
  const [username, setUsername] = useState<string>('')
  const { socket } = useSocket()
  const cameraRef1 = useRef<any>(null)
  const cameraRef2 = useRef<any>(null)

  const renderCameras = useMemo(() =>
    cameraList.map((camera, idx) => (
      <Grid item xs={cameraList.length > 1 ? 6 : 12} key={generateKey('camera', idx)} p={2}>
        <ReactHlsPlayer
          src={camera}
          playerRef={idx === 1 ? cameraRef1 : cameraRef2}
          width="100%"
          autoPlay
          height="auto"
          muted
        />
      </Grid>
    )) , [cameraList])

  const getIpCamera = async () => {
    const response: any = await apiGetIpCamera(params.mirrorCode ?? '')
    setCameraList(response.data)
  }

  const getUsername = async () => {
    const response: any = await apiGetUsername(params.userId ?? '')
    setUsername(response.data.length > 0 ? response.data[0].name : '')
  }

  useEffect(() => {
    if (params.mirrorCode !== '' && params.userId !== '') {
      getIpCamera()
      getUsername()
    } else {
      window.close()
    }
  }, [])

  useEffect(() => {
    if (socket && params.mirrorCode !== '') {
      socket.emit('get_ip_change', {
        mirrorCode: params.mirrorCode,
      })
      socket.on('get_ip_change', (data: any) => {
        setCameraIp(data.public_ip)
      })
    }

    return () => {
      if (socket) {
        socket.off('get_ip_change')
      }
    }
  }, [socket])

  const handleUpdateCameraIp = async ({
    mirrorCode,
    cameraIP,
  }: {
    mirrorCode: string
    cameraIP: string
  }) => {
    try {
      if (mirrorCode !== '' && cameraIP !== '') {
        dispatch(RESET_MESSAGE())
        await apiUpdateIP({ mirrorCode, cameraIP })
      }
    } catch (error) {
      dispatch(handleError(error))
    }
  }

  useEffect(() => {
    if (cameraIp !== '') {
      handleUpdateCameraIp({ mirrorCode: params.mirrorCode ?? '', cameraIP: cameraIp })
    }
  }, [cameraIp])

  return (
    <Box className="mentor-detail-container">
      <Grid container height="90%" justifyContent="center" alignItems="end">
        <Grid item xs={12}>
          <Typography
            align="left"
            variant='h5'
            sx={{
              marginLeft: '16px',
              marginTop: '16px',
              color: 'white',
              borderRadius: 10,
              border: '3px solid #53a8ff',
              padding: '5px 10px',
              width: 'fit-content'
            }}
          >
            {username}
          </Typography>
        </Grid>
        {renderCameras}
      </Grid>
    </Box>
  )
}

export default React.memo(Camera)
