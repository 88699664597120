import * as React from 'react'
import {
  Paper,
  Breadcrumbs,
  Box,
  Stack,
  Grid,
  Slider,
  Typography,
  IconButton,
} from '@mui/material'
import { Link } from 'react-router-dom'
import {
  NavigateNext,
  VolumeDown,
  VolumeOff,
  VolumeUp,
  PlayArrow,
  Pause,
} from '@mui/icons-material'
import '@/assets/styles/pages/recordVideo.scss'
import { useTranslation } from 'react-i18next'

interface infoVideo {
  id: number
  thumbnail: string
  duration: string
}

const showSpeaker = (value: number, toggleVolume: () => void) => {
  if (value === 0) {
    return <VolumeOff onClick={() => toggleVolume()} />
  }
  if (value <= 50) {
    return <VolumeDown onClick={() => toggleVolume()} />
  }
  return <VolumeUp onClick={() => toggleVolume()} />
}

function RecordVideo() {
  const { t } = useTranslation()

  const [valueVolume, setValueVolume] = React.useState<number>(100)

  const [playing, setPlaying] = React.useState(false)

  const [currentTime, setCurrentTime] = React.useState(0)

  const [videoTime, setVideoTime] = React.useState(0)

  const [progress, setProgress] = React.useState(0)

  const videoRef = React.useRef<HTMLVideoElement | null>(null)

  // Handle pause/play video
  const videoHandler = (control: string) => {
    if (control === 'play') {
      videoRef.current!.play()
      setPlaying(true)
    } else if (control === 'pause') {
      videoRef.current!.pause()
      setPlaying(false)
    }
  }

  // Handle on/off volume
  const toggleVolume = () => {
    if (valueVolume === 0) setValueVolume(22)
    else setValueVolume(0)
  }

  // Handle change volume
  const handleChange = (event: Event, newValue: number | number[]) => {
    setValueVolume(newValue as number)
    videoRef.current!.volume = (newValue as number) / 100
  }

  // Handle progress bar
  const handleChangeProgressBar = (
    event: Event,
    newValue: number | number[]
  ) => {
    videoRef.current!.currentTime = ((newValue as number) * videoTime) / 100
    setProgress(newValue as number)
  }

  // Handle show/hide speaker
  const showSpeakerMemo = React.useMemo(
    () => showSpeaker(valueVolume, toggleVolume),
    [valueVolume]
  )

  const listVideo: Array<infoVideo> = [
    {
      id: 1,
      thumbnail: 'https://i.imgur.com/r2kwvGr.png',
      duration: '01:30:00',
    },
    {
      id: 2,
      thumbnail: 'https://i.imgur.com/r2kwvGr.png',
      duration: '30:00',
    },
    {
      id: 3,
      thumbnail: 'https://i.imgur.com/r2kwvGr.png',
      duration: '48:00',
    },
  ]

  React.useEffect(() => {
    const progressBar = setInterval(() => {
      setCurrentTime(videoRef.current!.currentTime)
      setProgress(
        (videoRef.current!.currentTime / videoRef.current!.duration) * 100
      )
      setVideoTime(videoRef.current!.duration)
    }, 1000)
    return () => {
      clearInterval(progressBar)
    }
  }, [])

  return (
    <Box className="record-video-container">
      <Breadcrumbs
        separator={<NavigateNext fontSize="small" />}
        aria-label="breadcrumb"
      >
        <Link
          to="/job-management/booking"
          className="breadcrumb-text breadcrumb-text-list"
        >
          {t('job_management.training.breadcrumb.list')}
        </Link>
        <Box className="breadcrumb-text">
          {t('job_management.training.breadcrumb.course')}
        </Box>
        ,
      </Breadcrumbs>
      <Paper elevation={0} sx={{ mt: 1 }}>
        <Box className="record-video-title">YGG101</Box>
        <Box className="record-video-stream">
          <Grid container spacing={2}>
            <Grid item xs={10}>
              <Box className="record-video-stream-main">
                <video width="100%" height="100%" ref={videoRef}>
                  <source
                    src="http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ElephantsDream.mp4"
                    type="video/mp4"
                  />
                  <track kind="captions" />
                </video>
              </Box>
            </Grid>
            <Grid item xs={2}>
              <Stack
                direction="column"
                spacing={5}
                sx={{ maxHeight: 683 }}
                className="stream-participants-wrapper"
              >
                {listVideo.map(item => (
                  <Stack
                    justifyContent="flex-end"
                    key={item.id}
                    className="record-video-stream-participants"
                    sx={{
                      backgroundImage: `url("${item.thumbnail}")`,
                    }}
                  >
                    <Typography component="span">{item.duration}</Typography>
                  </Stack>
                ))}
              </Stack>
            </Grid>
          </Grid>
          <Stack className="record-video-stream-control" direction="column">
            <Slider value={progress} onChange={handleChangeProgressBar} />

            <Grid container>
              <Grid item xs={4} display="flex" alignItems="center">
                <Stack
                  direction="row"
                  alignItems="center"
                  className="controller-volume"
                >
                  {showSpeakerMemo}
                  <Slider
                    aria-label="Volume"
                    value={valueVolume}
                    onChange={handleChange}
                    sx={{ m: '0 16px !important' }}
                  />
                </Stack>
              </Grid>
              <Grid
                item
                xs={4}
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                {playing ? (
                  <IconButton
                    className="controller-play"
                    onClick={() => videoHandler('pause')}
                  >
                    <Pause />
                  </IconButton>
                ) : (
                  <IconButton
                    className="controller-play"
                    onClick={() => videoHandler('play')}
                  >
                    <PlayArrow />
                  </IconButton>
                )}
              </Grid>
              <Grid
                item
                xs={4}
                display="flex"
                alignItems="center"
                justifyContent="flex-end"
              >
                <Typography>
                  {new Date(currentTime * 1000).toISOString().substr(11, 8)} /{' '}
                  {new Date(videoTime * 1000).toISOString().substr(11, 8)}
                </Typography>
              </Grid>
            </Grid>
          </Stack>
        </Box>
      </Paper>
    </Box>
  )
}
export default React.memo(RecordVideo)
