import '@/assets/styles/pages/landing.scss'
import React, { useEffect, useState } from 'react'
import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm, Controller } from 'react-hook-form'
import {
  Box,
  Grid,
  Container,
  Typography,
  Table,
  TableContainer,
  TableBody,
  TableRow,
  TableCell,
  Stack,
  TextField,
  Button,
  Link,
} from '@mui/material'
import { Trans, useTranslation } from 'react-i18next'
import { useLocation, Navigate } from 'react-router-dom'
import { handleError, renderLabel } from '@/utils'
import { EMAIL_REGEX } from '@/constants'
import { useAppDispatch } from '@/redux/hooks'
import { SET_LOADING, SET_MESSAGE } from '@/redux/reducers/app.slice'
import { apiContactEmail } from '@/api/auth'

function Index() {
  const { t } = useTranslation()
  const location = useLocation()
  const dispatch = useAppDispatch()

  const { search, pathname } = location
  const [isPreview, setIsPreview] = useState<boolean>(false)
  const [isEdit, setIsEdit] = useState<boolean>(false)
  const [confirmSubmit, setConfirmSubmit] = useState({})

  const inforCompany = [
    {
      id: 1,
      title: t('landing.about_us.parent_company'),
      content: '<0>株式会社GIANT SWING PRODUCTIONS</0>',
    },
    {
      id: 2,
      title: t('landing.about_us.representative'),
      content: '倉 卓治',
    },
    {
      id: 3,
      title: t('landing.about_us.location'),
      content: '<1>株式会社BIG YOUTH</1>',
    },
    {
      id: 4,
      title: t('landing.about_us.head_quarter'),
      content: '東京都港区南青山5-13-1 第42荒井ビル8F',
    },
    {
      id: 5,
      title: t('landing.about_us.tel'),
      content: '03-5843-1021',
    },
    {
      id: 6,
      title: t('landing.about_us.business_content'),
      content:
        '商品開発<br/>店舗運営<br/>FC本部<br/>美容機器製造<br/>美容コンサル',
    },
    {
      id: 7,
      title: t('landing.about_us.store'),
      content: '株式会社ヴィーナス・ラッシュ',
    },
    {
      id: 8,
      title: t('landing.about_us.business_partner'),
      content:
        '株式会社GIANT SWING PRODUCTIONS<br/>株式会社シャリオン<br/>オン株式会社ビューティーガレージ<br/>株式会社ジェイファムコーポレーション',
    },
    {
      id: 9,
      title: t('landing.about_us.plant'),
      content:
        '千葉工場<br/>〒289-1104<br/>千葉県八街市文違301-3183 文違倉庫102',
    },
    {
      id: 10,
      title: t('landing.about_us.member_group'),
      content: '一般財団法人ニューメディア開発協会',
    },
  ]

  const Schema = Yup.object().shape({
    user_name: Yup.string()
      .trim()
      .nullable(true)
      .required(t('landing.contact.validate_name')),
    furigana: Yup.string()
      .trim()
      .nullable(true)
      .required(t('landing.contact.validate_furigana')),
    email_address: Yup.string()
      .trim()
      .nullable(true)
      .required(t('landing.contact.validate_mail'))
      .matches(EMAIL_REGEX, t('validation.email_invalid')),
    title_content: Yup.string()
      .trim()
      .nullable(true)
      .required(t('landing.contact.validate_title')),
    message_body: Yup.string()
      .trim()
      .nullable(true)
      .required(t('landing.contact.validate_message')),
  })

  const defaultValues = {
    user_name: '',
    furigana: '',
    company_name: '',
    email_address: '',
    title_content: '',
    message_body: '',
  }

  const {
    handleSubmit,
    control,
    reset,
    formState: { isSubmitSuccessful, isValid, isDirty },
  } = useForm({
    resolver: yupResolver(Schema),
    defaultValues,
    mode: 'all',
  })

  const onSubmit = (data: any) => {
    const customData = {
      userName: data.user_name,
      furiganaName: data.furigana,
      companyName: data.company_name,
      email: data.email_address,
      subject: data.title_content,
      messageBody: data.message_body,
    }
    setConfirmSubmit(customData)
    reset({ ...data })
    setIsPreview(true)
    setIsEdit(false)
  }

  const handleConfirmSubmit = async () => {
    try {
      dispatch(SET_LOADING(true))
      await apiContactEmail({ ...confirmSubmit })
      dispatch(
        SET_MESSAGE({
          type: 'success',
          content: t('landing.contact.success_message'),
        })
      )
      reset({ ...defaultValues })
    } catch (error) {
      dispatch(SET_MESSAGE(handleError(error)))
    } finally {
      dispatch(SET_LOADING(false))
    }
  }

  const handleEdit = () => {
    setIsEdit(true)
  }

  // Show modal when click link email from forgot password
  if (pathname === '/reset-password') {
    const query = new URLSearchParams(search)
    const token = query.get('token')
    if (!token) {
      return <Navigate to="404" replace />
    }
  }
  useEffect(() => {
    if (isPreview && isDirty) {
      setIsPreview(false)
    }
  }, [isDirty])

  return (
    <Box className="landing-container" mt={3} pb={3}>
      <Container className="background-landing" />
      <Box className="landing-overlay">
        <Box className="landing-title">
          <Trans parent="span" i18nKey="landing.header.beauty_closer" />
        </Box>
        <Grid display="flex" justifyContent="flex-end" mt={5}>
          <Typography variant="h5" className="test-easy">
            {t('landing.header.text_easy_beautiful')}
          </Typography>
        </Grid>
      </Box>
      <Box
        className="landing-about-us"
        textAlign="center"
        mt={15}
        mb={5}
        id="information"
      >
        <Typography color="primary" variant="h2" fontWeight="bold" mb={4}>
          {t('landing.about_us.title')}
        </Typography>
        <TableContainer component={Container}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableBody>
              {inforCompany.map(row => (
                <TableRow key={row.id}>
                  <TableCell
                    style={{ width: '30%' }}
                    component="th"
                    scope="row"
                    align="center"
                    className="table-cell"
                  >
                    {row.title}
                  </TableCell>
                  <TableCell align="center">
                    <Trans
                      i18nKey={row.content}
                      components={[
                        <Link href="https://gspdn.com/" />,
                        <Link href="https://big-youth.com/" />,
                      ]}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <Box
        className="landing-contact"
        id="inquiries"
        component="form"
        onSubmit={handleSubmit(onSubmit)}
        noValidate
        sx={{ mt: 1 }}
      >
        <Typography
          color="primary"
          variant="h2"
          fontWeight="bold"
          textAlign="center"
        >
          {t('landing.contact.title')}
        </Typography>
        <Typography variant="h6" my={4} textAlign="center">
          {t('landing.contact.use_this_form')}
        </Typography>
        <Container>
          <Stack
            direction="column"
            spacing={3}
            className="landing-contact-form"
          >
            {isSubmitSuccessful && (
              <Typography color="error">
                {t('landing.contact.save_information')}
              </Typography>
            )}

            <Controller
              name="user_name"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  id="user_name"
                  label={renderLabel(t('landing.contact.name'))}
                  error={!!error}
                  helperText={error?.message}
                  disabled={!isEdit && isPreview && isSubmitSuccessful}
                />
              )}
            />
            <Controller
              name="furigana"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  id="furigana"
                  label={renderLabel(t('landing.contact.furigana'))}
                  error={!!error}
                  helperText={error?.message}
                  disabled={!isEdit && isPreview && isSubmitSuccessful}
                />
              )}
            />
            <Controller
              name="company_name"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  id="company_name"
                  label={renderLabel(t('landing.contact.company_name'))}
                  error={!!error}
                  helperText={error?.message}
                  disabled={!isEdit && isPreview && isSubmitSuccessful}
                />
              )}
            />
            <Controller
              name="email_address"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  id="email_address"
                  label={renderLabel(t('landing.contact.email_address'))}
                  error={!!error}
                  helperText={error?.message}
                  disabled={!isEdit && isPreview && isSubmitSuccessful}
                />
              )}
            />
            <Controller
              name="title_content"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  id="title_content"
                  label={renderLabel(t('landing.contact.title_content'))}
                  error={!!error}
                  helperText={error?.message}
                  disabled={!isEdit && isPreview && isSubmitSuccessful}
                />
              )}
            />
            <Controller
              name="message_body"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  className="message-body"
                  {...field}
                  id="message_body"
                  multiline
                  minRows={4}
                  maxRows={8}
                  label={renderLabel(t('landing.contact.message_body'))}
                  error={!!error}
                  helperText={error?.message}
                  disabled={!isEdit && isPreview && isSubmitSuccessful}
                />
              )}
            />
          </Stack>
          {(!isSubmitSuccessful || !isValid || !isPreview) && (
            <Button
              type="submit"
              variant="contained"
              sx={{ marginTop: 3, alignItems: 'left' }}
            >
              {t('landing.contact.save')}
            </Button>
          )}
          {isSubmitSuccessful && isValid && isPreview && (
            <Stack direction="row" spacing={3} mt={3}>
              <Button variant="outlined" onClick={handleEdit}>
                {t('landing.contact.edit')}
              </Button>
              <Button
                type="submit"
                variant="contained"
                onClick={handleConfirmSubmit}
              >
                {t('landing.contact.submit')}
              </Button>
            </Stack>
          )}
        </Container>
      </Box>
    </Box>
  )
}

export default React.memo(Index)
