import '@/assets/styles/layout/main.scss'
import React, { useEffect } from 'react'
import { Box, CssBaseline } from '@mui/material'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import { Outlet, useLocation } from 'react-router-dom'
import Header from '@/layout/MainLayout/Header'
import Sidebar from '@/layout/MainLayout/Sidebar'
import { useAppDispatch, useAppSelector } from '@/redux/hooks'
import PageLoading from '@components/common/PageLoading'
import { useSnackbar } from 'notistack'
import { ROUTER_PATH, TYPE_MESSAGE } from '@/constants'
import { useTranslation } from 'react-i18next'
import { getTabId } from '@/utils'
import { FETCH_NOTICE } from '@/redux/reducers/contact.slice'

const theme = createTheme({
  palette: {
    mode: 'light',
  },
  components: {},
})

function MainLayout() {
  const dispatch = useAppDispatch()
  const location = useLocation()
  const { toggleSidebar, isLoading, message } = useAppSelector(
    state => state.app
  )
  const { t } = useTranslation()
  const drawerWidth = toggleSidebar ? 250 : 0
  const { enqueueSnackbar } = useSnackbar()
  const authChannel = new BroadcastChannel('auth')

  useEffect(() => {
    if (message.type) {
      if (message.content !== '')
        enqueueSnackbar(message.content, {
          variant: message.type,
          anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
        })
      else if (message.type === TYPE_MESSAGE.SUCCESS)
        enqueueSnackbar(t('message.default_success'), {
          variant: message.type,
          anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
        })
      else
        enqueueSnackbar('message.default_error', {
          variant: message.type,
          anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
        })
    }
  }, [message])

  useEffect(() => {
    authChannel.addEventListener('message', e => {
      if (e.data.action === 'login' && e.data.tabId !== getTabId()) {
        window.location.reload()
      }
    })
  }, [])

  useEffect(() => {
    if (location.pathname !== ROUTER_PATH.CONTACT) {
      dispatch(FETCH_NOTICE())
    }
  }, [location.pathname])

  return (
    <ThemeProvider theme={theme}>
      <Box className="layout-container">
        <CssBaseline />
        {isLoading && <PageLoading />}
        <Header />
        <Box className="layout-wrapper">
          <Sidebar width={drawerWidth} />
          <Box
            component="main"
            className="main-layout"
            sx={{
              width: { md: `calc(100% - ${drawerWidth}px)` },
            }}
          >
            <Outlet />
          </Box>
        </Box>
      </Box>
    </ThemeProvider>
  )
}

export default React.memo(MainLayout)
