import { JobManagementServiceListParams } from '@/interfaces/jobManagement'
import { useAppDispatch } from '@/redux/hooks'
import { FETCH_LIST_SERVICE } from '@/redux/reducers/jobManagement.slice'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Paper, Grid, Stack, Tabs, Tab, Button } from '@mui/material'
import '@/assets/styles/pages/jobManagement.scss'
import { TabPanelProps } from '@/interfaces'
import TableService from '@/components/JobManagement/TableService'
import { ROUTER_PATH } from '@/constants'
import { useNavigate } from 'react-router-dom'

function TabPanel({ index, value, children }: TabPanelProps) {
  return <div>{value === index && <Box>{children}</Box>}</div>
}

function ServiceList() {
  const navigate = useNavigate()

  const [queryParamsListService, setQueryParamsListService] =
    React.useState<JobManagementServiceListParams>({
      page: 1,
      type: '',
      sort: '',
    })

  const { t } = useTranslation()

  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(FETCH_LIST_SERVICE(queryParamsListService))
  }, [queryParamsListService])

  return (
    <Box sx={{ flexGrow: 1 }} className="job-container">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Paper elevation={3}>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              spacing={2}
              sx={{ marginRight: 2 }}
            >
              <Tabs
                value={0}
                aria-label="basic tabs example"
                className="service-container"
              >
                <Tab label={t('job_management.tab_title.service_list')} />
              </Tabs>
              <Button
                variant="contained"
                color="primary"
                size="small"
                sx={{ minWidth: '7em' }}
                onClick={() =>
                  navigate(ROUTER_PATH.JOB_MANAGEMENT_SERVICE_CREATE, {
                    state: {
                      create: 'create',
                    },
                  })
                }
              >
                {t('job_management.button.add')}
              </Button>
            </Stack>
            <TabPanel value={0} index={0}>
              <TableService
                queryParamsListService={queryParamsListService}
                setQueryParamsListService={setQueryParamsListService}
              />
            </TabPanel>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  )
}

export default React.memo(ServiceList)
