import React, { useState } from 'react'
import * as Yup from 'yup'
import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import {
  Grid,
  Button,
  Box,
  TextField,
  Typography,
  Modal,
  IconButton,
  Divider,
} from '@mui/material'
import { Close } from '@mui/icons-material'
import { useTranslation } from 'react-i18next'
import { useAppDispatch, useAppSelector } from '@/redux/hooks'
import { AUTH_USER_FORGOT_PASSWORD } from '@/redux/reducers/auth.slice'
import { AuthForgotPassword } from '@/interfaces'
import { EMAIL_REGEX, TYPE_MESSAGE } from '@/constants'

interface ModalProps {
  isOpen: any
  setIsOpen(open: any): void
}

function ForgotPasswordModal({ isOpen, setIsOpen }: ModalProps) {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const handleToggleModal = () =>
    setIsOpen({ ...isOpen, forgotPassword: false })
  const [isSubmit, setIsSubmit] = useState(false)
  const message = useAppSelector(state => state.app.message)

  const defaultValues = {
    email: '',
  }

  // Check validate form
  const forgotSchema = Yup.object().shape({
    email: Yup.string()
      .trim()
      .required(t('auth.forgot_password.validation.email_require'))
      .matches(EMAIL_REGEX, t('validation.email_invalid'))
      .max(100, t('validation.length')),
  })

  const onSubmit = (data: AuthForgotPassword) => {
    dispatch(AUTH_USER_FORGOT_PASSWORD({ email: data.email }))
    setIsSubmit(true)
  }

  const { handleSubmit, control } = useForm({
    resolver: yupResolver(forgotSchema),
    defaultValues,
    mode: 'onChange',
  })

  return (
    <Modal open={isOpen.forgotPassword} onClose={handleToggleModal}>
      <Box className="modal-content" sx={{ minWidth: '500px' }}>
        <Grid container textAlign="center">
          <Grid item xs>
            <Typography className="title" textAlign="center">
              {t('auth.forgot_password.title')}
            </Typography>
          </Grid>
          <Grid item>
            <IconButton onClick={handleToggleModal} className="close-button">
              <Close />
            </IconButton>
          </Grid>
        </Grid>
        <Divider />
        <Box
          component="form"
          onSubmit={handleSubmit(onSubmit)}
          noValidate
          sx={{ mt: 2 }}
        >
          <Typography fontSize="small">
            {t('auth.forgot_password.description')}
          </Typography>
          <Controller
            name="email"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                margin="normal"
                fullWidth
                id="email"
                label={t('auth.forgot_password.email_placeholder')}
                error={!!error}
                helperText={error?.message}
                placeholder={t('auth.forgot_password.email_placeholder')}
              />
            )}
          />
          {message.type === TYPE_MESSAGE.SUCCESS && isSubmit && (
            <Typography color="error" textAlign="center">
              {t('auth.forgot_password.notification_email')}
            </Typography>
          )}
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            {t('auth.forgot_password.send')}
          </Button>
        </Box>
      </Box>
    </Modal>
  )
}

export default React.memo(ForgotPasswordModal)
