import React, { useEffect } from 'react'
import { getUserToken, removeUserToken } from '@/utils/helper'
import { useAppSelector, useAppDispatch } from '@/redux/hooks'
import { Navigate } from 'react-router-dom'
import PageLoading from '@components/common/PageLoading'
import { AUTH_GET_INFO } from '@/redux/reducers/auth.slice'

function PrivateRoute({ children }: { children: React.ReactElement }) {
  const dispatch = useAppDispatch()
  const hasRerender = (prevState: any, state: any) => !state.loading

  const { currentUser, loading } = useAppSelector(
    state => state.auth,
    hasRerender
  )
  const clearToken = () => {
    removeUserToken()
  }

  const token = getUserToken()

  if (!token) {
    return <Navigate to="/" />
  }

  useEffect(() => {
    if (loading) {
      dispatch(AUTH_GET_INFO())
    }
  }, [loading])

  if (loading) {
    return <PageLoading />
  }

  if (currentUser.id) {
    return children
  }

  clearToken()
  return <Navigate to="/" />
}

export default PrivateRoute
