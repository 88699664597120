import React from 'react'
import {
  Grid,
  Button,
  Box,
  Stack,
  Typography,
  Modal,
  IconButton,
  Divider,
} from '@mui/material'
import { Close } from '@mui/icons-material'
import { useTranslation } from 'react-i18next'
import reset from '@/assets/images/reset.png'

interface ModalProps {
  isOpen: boolean
  toggleModal(open: any): void
  handleSignIn: () => void
}

// Show modal when reset password reset success
function ResetPasswordSuccess({
  isOpen,
  toggleModal,
  handleSignIn,
}: ModalProps) {
  const { t } = useTranslation()
  const handleToggleModal = () => toggleModal(false)

  return (
    <Modal open={isOpen} onClose={handleToggleModal}>
      <Box className="modal-content" sx={{ minWidth: '400px' }}>
        <Grid container textAlign="center">
          <Grid item xs>
            <Typography className="title" textAlign="center">
              {t('auth.reset_password.reset_success')}
            </Typography>
          </Grid>
          <Grid item>
            <IconButton onClick={handleToggleModal} className="close-button">
              <Close />
            </IconButton>
          </Grid>
        </Grid>
        <Divider />
        <Stack
          direction="column"
          justifyContent="center"
          alignItems="center"
          spacing={2}
        >
          <Box
            mt={2}
            className="logo-image"
            component="img"
            src={reset}
            alt=""
          />
          <Typography variant="h6">
            {t('auth.reset_password.description_reset_password')}
          </Typography>
          <Button variant="contained" onClick={handleSignIn}>
            {t('auth.reset_password.to_login')}
          </Button>
        </Stack>
      </Box>
    </Modal>
  )
}

export default React.memo(ResetPasswordSuccess)
