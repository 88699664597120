import React, { useState, useEffect, useRef, useMemo } from 'react'
import '@/assets/styles/pages/serviceDetail.scss'
import Table, { ColumnsType } from 'antd/es/table'
import {
  Box,
  Stack,
  Paper,
  Divider,
  Typography,
  TextField,
  Breadcrumbs,
  MenuItem,
  Checkbox,
  Select,
  FormControl,
  InputLabel,
  ListItemText,
  Grid,
  Button,
} from '@mui/material'
import { SearchOutlined } from '@mui/icons-material'
import { Link, useParams, useNavigate } from 'react-router-dom'
import {
  ROUTER_PATH,
  DEFAULT_LANGUAGE,
  DATE_PICKER_INPUT_YMD,
  DATE_STRING_FOR_SCHEDULE,
} from '@/constants'
import { useTranslation } from 'react-i18next'
import { useAppDispatch, useAppSelector } from '@/redux/hooks'
import {
  FETCH_BOX_SETTING_DETAIL,
  FETCH_GROUP_1,
  FETCH_GROUP_2,
  RESET_BOX_SETTING_DETAIL,
} from '@/redux/reducers/jobManagement.slice'
import useMasterData from '@/hooks/useMasterData'
import { getValueMasterData, handleError, showTotalPaginationTable } from '@/utils'
import dayjs from 'dayjs'
import isBetween from 'dayjs/plugin/isBetween'
import Tooltip from 'antd/es/tooltip'
import { BoxSettingService } from '@/interfaces'
import { RESET_MESSAGE, SET_LOADING, SET_MESSAGE } from '@/redux/reducers/app.slice'
import { apiDeleteServiceBoxes } from '@/api/jobManagement'
import ConfirmDialog from '@/components/common/ConfirmDialog'
import DeleteServiceResultModal from '@/components/common/DeleteServiceResultModal'
import { DatePicker, LocalizationProvider } from '@mui/lab'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import { enUS, ja } from 'date-fns/locale'

dayjs.extend(isBetween)

function BoxServiceDelete() {
  const { t } = useTranslation()
  const [selectedGroup1, setSelectedGroup1] = useState<number[]>([])
  const [selectedGroup2, setSelectedGroup2] = useState<number[]>([])
  const [toggleDialog, setToggleDialog] = useState<boolean>(false)
  const [toggleResultDialog, setToggleResultDialog] = useState<boolean>(false)
  const [bookedServiceList, setBookedServiceList] = useState<any[]>([])
  const [checkAll, setCheckAll] = useState<boolean>(false)
  const [dateFrom, setDateFrom] = React.useState<any>(null);
  const [dateTo, setDateTo] = React.useState<any>(null);
  const [queryParams, setQueryParams] = useState<{
    selectedGroup1: any[]
    selectedGroup2: any[]
    search: string
    page: number
    results_per_page: number
    prefecture: string
  }>({
    selectedGroup1: [],
    selectedGroup2: [],
    search: '',
    prefecture: '',
    page: 1,
    results_per_page: 10,
  })
  const paramDetails = useParams()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const tableRef = useRef<HTMLDivElement>(null)
  const getTableScrollY = () => {
    const extraHeight = 88 // pagination 64 + padding 24
    const tableHeader = tableRef.current?.querySelector('.ant-table-header')
    const tableHeaderBottom = tableHeader?.getBoundingClientRect().bottom
    return `calc(100vh - ${tableHeaderBottom}px - ${extraHeight}px)`
  }

  const { languageMaster, message } = useAppSelector(state => state.app)
  const { data, meta } = useAppSelector(
    state => state.jobManagement.boxSettingServiceDetail
  )

  const { boxSettingServiceDetail } = useAppSelector(
    state => state.jobManagement
  )
  const { currentUser } = useAppSelector(state => state.auth)

  const trainingType = getValueMasterData(
    useMasterData('freelance_training_type')
  )

  const dataSource = useMemo(
    () =>
      data?.map(item => {
        let isBooked = false
        for (const service of item.serviceBoxes) {
          if (service.reservations > 0) {
            isBooked = true
            break
          }
        }
        return {
          ...item,
          key: item.id,
          isBooked
        }
      }),
    [data]
  )

  const { group1, group2 } = useAppSelector(state => state.jobManagement)
  const [listChecked, setListChecked] = useState<any>([])

  const serviceType = getValueMasterData(
    useMasterData('freelance_service_type')
  )

  // Handle function pagination, filter of table
  const handleAction = (pagination: any, filters: any) => {
    let queryParamsClone = { ...queryParams }
    queryParamsClone = { ...queryParamsClone, page: pagination.current }
    if (Array.isArray(filters.area))
      queryParamsClone = {
        ...queryParamsClone,
        prefecture: filters.area[0],
        page: queryParams.page === pagination.current ? 1 : pagination.current,
      }
    else
      queryParamsClone = {
        ...queryParamsClone,
        prefecture: '',
        page: queryParams.page === pagination.current ? 1 : pagination.current,
      }
    setQueryParams(queryParamsClone)
    setListChecked([])
    setCheckAll(false)
  }

  const handleChangeGroup1 = (e: any) => {
    setSelectedGroup1(e.target.value)
    if (e.target.value.length > 0) {
      setQueryParams({
        ...queryParams,
        selectedGroup1: e.target.value,
        page: 1,
      })
      dispatch(FETCH_GROUP_2(e.target.value))
    } else {
      setSelectedGroup2([])
      setQueryParams({
        ...queryParams,
        selectedGroup1: [],
        selectedGroup2: [],
        page: 1,
      })
    }
  }

  const handleChangeGroup2 = (e: any) => {
    setSelectedGroup2(e.target.value)
    setQueryParams({
      ...queryParams,
      selectedGroup2: e.target.value,
      page: 1,
    })
  }

  useEffect(() => {
    dispatch(FETCH_GROUP_1())
    return () => {
      dispatch(RESET_BOX_SETTING_DETAIL())
    }
  }, [])

  useEffect(() => {
    if (bookedServiceList.length > 0) {
      setToggleResultDialog(true)
    }
  }, [bookedServiceList])

  useEffect(() => {
    if (currentUser.status !== 1) {
      navigate(ROUTER_PATH.JOB_MANAGEMENT_SERVICE_LIST)
    }
  }, [currentUser.status])

  useEffect(() => {
    if (paramDetails.id) {
      dispatch(
        FETCH_BOX_SETTING_DETAIL({
          id: paramDetails.id,
          params: {
            group_box_id1: queryParams.selectedGroup1,
            group_box_id2: queryParams.selectedGroup2,
            box_name: queryParams.search,
            page: queryParams.page,
            results_per_page: queryParams.results_per_page,
            prefecture: queryParams.prefecture,
          },
        })
      )
      setListChecked([])
      setCheckAll(false)
    }
  }, [queryParams, paramDetails])

  useEffect(() => {
    if (message.code === 403) {
      navigate('/job-management/service', {
        replace: true,
      })
    }
  }, [message])

  const confirmSubmit = async () => {
    try {
      setToggleDialog(false)
      if (paramDetails.id && listChecked.length > 0) {
        dispatch(SET_LOADING(true))
        dispatch(RESET_MESSAGE())
        const dateFromStr = dayjs(dateFrom).isValid() ? dayjs(dateFrom).format(DATE_STRING_FOR_SCHEDULE) : ''
        const dateToStr = dayjs(dateTo).isValid() ? dayjs(dateTo).format(DATE_STRING_FOR_SCHEDULE) : ''
        const response = await apiDeleteServiceBoxes(paramDetails.id, listChecked, dateFromStr, dateToStr)
        setBookedServiceList(response.data)
        dispatch(
          SET_MESSAGE({
            type: 'success',
            content: t('job_management.box_setting.box_modal.delete_service_success'),
          })
        )
      }
    } catch (error) {
      dispatch(SET_MESSAGE(handleError(error)))
    } finally {
      dispatch(SET_LOADING(false))
    }
  }

  const handleChangeCheckbox = (
    e: React.ChangeEvent<HTMLInputElement>,
    item: string | number
  ) => {
    const { checked } = e.target as HTMLInputElement
    let listCheckedClone = [...listChecked]
    if (checked) {
      listCheckedClone.push(item)
    } else {
      listCheckedClone = listCheckedClone.filter(
        checkedItem => checkedItem !== item
      )
    }
    setListChecked(listCheckedClone)
  }

  const columns: ColumnsType<BoxSettingService> = [
    {
      // eslint-disable-next-line react/no-unstable-nested-components
      title: () => (
        <Checkbox
          checked={checkAll}
          onChange={e => {
            if (e.target.checked) {
              setCheckAll(true)
              setListChecked(dataSource?.map((x: any) => (x.id)))
            } else {
              setCheckAll(false)
              setListChecked([])
            }
          }}
        />
      ),
      width: 15,
      align: 'center',
      dataIndex: 'id',
      key: 'id',
      fixed: 'left',
      responsive: ['xs', 'md'],
      ellipsis: { showTitle: false },
      render: text => (
        <Checkbox
          checked={!!listChecked.some((item: any) => item === text)}
          onChange={e => handleChangeCheckbox(e, text)}
        />
      ),
    },
    {
      title: t('job_management.box_setting.group_1'),
      width: 25,
      align: 'center',
      dataIndex: 'group_box_name_1',
      key: 'group_box_name_1',
      fixed: 'left',
      responsive: ['xs', 'md'],
      ellipsis: { showTitle: false },
      render: (value, record) => (
        <Tooltip placement="topLeft" title={value}>
          <span style={record.isBooked ? {color: 'rgb(255, 141, 133)'} : {color: 'rgba(0,0,0,.85)'}}>{value}</span>
        </Tooltip>
      )
    },
    {
      title: t('job_management.box_setting.group_2'),
      width: 25,
      align: 'center',
      dataIndex: 'group_box_name_2',
      key: 'group_box_name_2',
      fixed: 'left',
      responsive: ['xs', 'md'],
      ellipsis: { showTitle: false },
      render: (value, record) => (
        <Tooltip placement="topLeft" title={value}>
          <span style={record.isBooked ? {color: 'rgb(255, 141, 133)'} : {color: 'rgba(0,0,0,.85)'}}>{value}</span>
        </Tooltip>
      )
    },
    {
      title: t('job_management.box_setting.box_name'),
      width: 60,
      align: 'left',
      dataIndex: 'nickname',
      key: 'boxName',
      fixed: 'left',
      responsive: ['xs', 'md'],
      ellipsis: { showTitle: false },
      render: (value, record) => (
        <Tooltip placement="topLeft" title={value}>
          <span style={record.isBooked ? {color: 'rgb(255, 141, 133)'} : {color: 'rgba(0,0,0,.85)'}}>{value}</span>
        </Tooltip>
      )
    },
    {
      title: t('job_management.box_setting.prefecture'),
      width: 25,
      align: 'center',
      dataIndex: 'area',
      key: 'area',
      fixed: 'left',
      responsive: ['xs', 'md'],
      ellipsis: { showTitle: false },
      render: (value, record) => (
        <Tooltip placement="topLeft" title={value}>
          <span style={record.isBooked ? {color: 'rgb(255, 141, 133)'} : {color: 'rgba(0,0,0,.85)'}}>{value}</span>
        </Tooltip>
      )
    },
    {
      title: t('job_management.box_setting.municipality'),
      width: 60,
      align: 'left',
      dataIndex: 'address',
      key: 'address',
      fixed: 'left',
      responsive: ['xs', 'md'],
      ellipsis: { showTitle: false },
      render: (value, record) => (
        <Tooltip placement="topLeft" title={value}>
          <span style={record.isBooked ? {color: 'rgb(255, 141, 133)'} : {color: 'rgba(0,0,0,.85)'}}>{value}</span>
        </Tooltip>
      )
    }
  ]

  return (
    <Box className="service-detail-container">
      <Stack
        direction="row"
        divider={<Divider />}
        spacing={2}
        alignItems="center"
        mb={4}
      >
        <Typography variant="h6">
          {t('job_management.box_setting.title')}
        </Typography>
        <Breadcrumbs>
          <Link
            color="inherit"
            to={ROUTER_PATH.JOB_MANAGEMENT_SERVICE_LIST}
            className="breadcrumb-text breadcrumb-text-list"
          >
            {t('job_management.box_setting.link_1')}
          </Link>
          <Box>{t('job_management.box_setting.link_2')}</Box>
        </Breadcrumbs>
      </Stack>
      <Stack direction="column" spacing={3}>
        <Typography variant="body1" fontWeight="bold">
          {t('job_management.box_setting.service_information')}
        </Typography>
        <Divider />
        <Stack
          direction={{ lg: 'row', md: 'column', xs: 'column', sm: 'column' }}
          spacing={3}
        >
          <Stack width="50%">
            <Tooltip placement="topLeft" title={boxSettingServiceDetail.name}>
              <TextField
                label={t('job_management.box_setting.service_name')}
                value={boxSettingServiceDetail.name}
                disabled
                InputLabelProps={{ shrink: true }}
                inputProps={{
                  style: {
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  },
                }}
                sx={{ width: '50%' }}
              />
            </Tooltip>
          </Stack>
          <Stack direction="row" spacing={2} width="50%">
            <TextField
              label={t('job_management.box_setting.category')}
              value={boxSettingServiceDetail.training_type
                .toString()
                .split(',')
                .map(item => {
                  if (languageMaster === DEFAULT_LANGUAGE) {
                    return trainingType[item]?.title_jp
                  }
                  return trainingType[item]?.title_en
                })
                .join(', ')}
              disabled
              InputLabelProps={{ shrink: true }}
            />
            <TextField
              label={t('job_management.box_setting.form')}
              value={
                languageMaster === DEFAULT_LANGUAGE
                  ? serviceType[boxSettingServiceDetail.type]?.title_jp
                  : serviceType[boxSettingServiceDetail.type]?.title_en
              }
              disabled
              InputLabelProps={{ shrink: true }}
            />
            <TextField
              label={t('job_management.box_setting.service_time')}
              value={`${boxSettingServiceDetail.time} ${t(
                'job_management.service.title.minutes'
              )}`}
              disabled
              InputLabelProps={{ shrink: true }}
            />
            <TextField
              label={t('job_management.box_setting.group_limit')}
              value={`${boxSettingServiceDetail.group_limit} ${t(
                'job_management.box_setting.people'
              )}`}
              disabled
              InputLabelProps={{ shrink: true }}
            />
          </Stack>
        </Stack>
        <Box component={Paper} maxWidth="100%">
          <Box maxWidth="100%">
            <Grid container m={2}>
              <Grid item lg={2} md={4} mr={3}>
                <FormControl>
                  <InputLabel id="group1" sx={{ width: 400 }} shrink>
                    {t('job_management.box_setting.group1')}
                  </InputLabel>
                  <Select
                    labelId="group1"
                    multiple
                    value={selectedGroup1}
                    onChange={handleChangeGroup1}
                    displayEmpty
                    label
                    renderValue={() =>
                      selectedGroup1.length === 0
                        ? t('job_management.box_setting.value_all')
                        : group1
                            .filter((group: any) =>
                              selectedGroup1.includes(group.id)
                            )
                            .map((item: any) => item.name)
                            .join(', ')
                    }
                  >
                    {group1.map((group: any) => (
                      <MenuItem key={group.name} value={group.id}>
                        <Checkbox checked={selectedGroup1.includes(group.id)} />
                        <ListItemText primary={group.name} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item lg={2} md={4} mr={3} mb={1}>
                <FormControl>
                  <InputLabel id="group2" sx={{ width: 400 }} shrink>
                    {t('job_management.box_setting.group2')}
                  </InputLabel>
                  <Select
                    disabled={selectedGroup1.length === 0}
                    labelId="group2"
                    displayEmpty
                    multiple
                    value={selectedGroup2}
                    onChange={handleChangeGroup2}
                    renderValue={() =>
                      selectedGroup2.length === 0
                        ? t('job_management.box_setting.value_all')
                        : group2
                            .filter((group: any) =>
                              selectedGroup2.includes(group.id)
                            )
                            .map((item: any) => item.name)
                            .join(', ')
                    }
                  >
                    {group2.map((group: any) => (
                      <MenuItem key={group.name} value={group.id}>
                        <Checkbox
                          checked={selectedGroup2?.includes(group.id)}
                        />
                        <ListItemText primary={group.name} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item lg={4} md={4} mr={3} sx={{
                alignSelf: 'center !important'
              }}>
                <LocalizationProvider
                  dateAdapter={AdapterDateFns}
                  locale={DEFAULT_LANGUAGE === languageMaster ? ja : enUS}
                >
                  <DatePicker
                    value={dateFrom}
                    label={t('job_management.box_setting.box_modal.from')}
                    onChange={newValue => {
                      setDateFrom(newValue)
                    }}
                    renderInput={params => (
                      <TextField size="small" sx={{ width: '150px !important', marginRight: '20px' }} {...params} />
                    )}
                    mask={DATE_PICKER_INPUT_YMD}
                  />
                  <DatePicker
                    value={dateTo}
                    label={t('job_management.box_setting.box_modal.to')}
                    onChange={newValue => {
                      setDateTo(newValue)
                    }}
                    renderInput={params => (
                      <TextField size="small" sx={{ width: '150px !important', marginRight: '20px' }} {...params} />
                    )}
                    mask={DATE_PICKER_INPUT_YMD}
                  />
                </LocalizationProvider>
                <Button
                  size="small"
                  variant="contained"
                  color="error"
                  disabled={listChecked.length === 0}
                  onClick={() => {setToggleDialog(true)}}
                  sx={{
                    minHeight: '40px'
                  }}
                >
                  {t('job_management.button.btn_remove_service')}
                </Button>
              </Grid>
              <Grid item lg={3} md={4} sx={{
                alignSelf: 'right !important'
              }}>
                <Stack direction="row" justifyContent="end">
                  <TextField
                    className="search-input"
                    sx={{
                      maxWidth: 320,
                    }}
                    InputProps={{
                      startAdornment: <SearchOutlined sx={{ mr: 1 }} />,
                    }}
                    onKeyDown={(event: any) => {
                      if (event.key === 'Enter') {
                        setQueryParams({
                          ...queryParams,
                          search: event.target.value,
                          page: 1,
                        })
                      }
                    }}
                    placeholder={t(
                      'job_management.box_setting.search_placeholder'
                    )}
                  />
                </Stack>
              </Grid>
            </Grid>
          </Box>
          <Box width="100%">
            <Table
              columns={columns}
              dataSource={dataSource}
              ref={tableRef}
              scroll={{ x: 1500, y: getTableScrollY() }}
              onChange={handleAction}
              pagination={{
                total: meta?.total,
                current: meta?.current_page,
                showSizeChanger: false,
                showTotal: () =>
                  showTotalPaginationTable(
                    meta?.per_page || 0,
                    meta?.current_page || 0,
                    data?.length || 0,
                    meta?.total || 0
                  ),
              }}
            />
          </Box>
        </Box>
      </Stack>
      <ConfirmDialog
        openDialog={toggleDialog}
        setOpenDialog={setToggleDialog}
        onConfirmDialog={confirmSubmit}
        dialogContent={t('job_management.service.service_delete.dialog_content')}
        okText={t('job_management.service.service_delete.ok')}
        cancelText={t('job_management.service.service_delete.cancel')}
      />
      <DeleteServiceResultModal
        isOpen={toggleResultDialog}
        toggleModal={setToggleResultDialog}
        dataSource={bookedServiceList}
      />
    </Box>
  )
}

export default React.memo(BoxServiceDelete)
