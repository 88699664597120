import { Box } from '@mui/material'
import React from 'react'

function TabPanel({
  index,
  value,
  children,
}: {
  index: string
  value: string
  children: React.ReactNode
}) {
  return <div>{value === index && <Box p={5}>{children}</Box>}</div>
}

export default React.memo(TabPanel)
