import en from '@/assets/images/flag_en.png'
import jp from '@/assets/images/flag_jp.png'

export const AUTH_USER_TOKEN = 'auth_user_token'

export const TAB_ID_TOKEN = 'tab_id_token'

export const AUTH_REMEMBER_EXPIRES = 30 // DAYS

export const DEFAULT_LANGUAGE = 'ja'

export const WS_PREFIX = 'fitness'

export const LANGUAGES = [
  {
    lang: 'en',
    title: 'English',
  },
  {
    lang: 'ja',
    title: 'Japanese',
  },
]

export const LANGS = [
  {
    label: 'Japanese',
    value: 'ja',
    icon: jp,
  },
  {
    label: 'English',
    value: 'en',
    icon: en,
  },
]

export const TYPE_MESSAGE = {
  SUCCESS: 'success',
  ERROR: 'error',
}

export enum TYPE_TRAINING {
  GYM = 1,
  YOGA,
  A,
  B,
}

export enum SERVICE_TIME {
  TIME_15 = 15,
  TIME_30 = 30,
  TIME_45 = 45,
  TIME_60 = 60,
}

export enum TRAINING_LIMIT {
  LIMIT_5 = 5,
  LIMIT_10 = 10,
  LIMIT_15 = 15,
  LIMIT_20 = 20,
}

export enum TYPE_SERVICE {
  GROUP = 1,
  PRIVATE,
}

export const LANGUAGE_STORE_KEY = 'by_current_lang'

export const PASSWORD_REGEX =
  /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&]?)([A-Za-z\d@$!%*#?&]?){8,32}$/

export const DATE_STRING = 'YYYY/MM/DD'

export const DATE_STRING_FOR_SCHEDULE = 'YYYY-MM-DD'

export const HOUR_SECOND = 'HH:mm'

export const MINUTE = 'minute'

export const DATE_INPUT_FORMAT = 'yyyy/MM/dd'

export const DATE_PICKER_INPUT_YM = '____/__'

export const DATE_PICKER_INPUT_YMD = '____/__/__'

export const DAYJS_FORMAT = 'YYYY/MM/DD'

export const YEAR_MONTH_FORMAT = 'yyyy/MM'

export const YEAR_MONTH_FORMAT_DAYJS = 'YYYY/MM'

export const FORMAT_PRICE = /\B(?=(\d{3})+(?!\d))/g

export const FORMAT_PHONE = /^(\d{3}-\d{4}-\d{4})$/

export const FORMAT_POST_CODE = /^(\d{3}-\d{4})$/

export const NUMBER_REGEX = /^[0-9]+$/

export const HALF_SIZE_REGEX = /^[0-9a-zA-Z-_]+$/

export const EMAIL_REGEX =
  /^\w+([\\.-]?\w+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/

export const TIME_EXPIRED = 86400000

export const INVALID_DATE = 'Invalid Date'

export const URL_WEBSITE = window.location.origin

export const LOOP_TYPE = {
  NONE: '',
  DAILY: 'daily',
  WEEKLY: 'weekly',
  MONTHLY: 'monthly',
}

export const CALENDAR_EXTRA_HEIGHT = 350
export const CALENDAR_EXTRA_WIDTH = 850
export const DATE = 'DATE'

export const DAY = 'day'
