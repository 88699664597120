import { RouteObject } from 'react-router-dom'
import Camera from '@/pages/JobManagement/Camera'

const CommonRouter: RouteObject = {
  path: '/',
  children: [
    {
        path: '/job-management/training/:id/cameras/:mirrorCode/:userId',
        // eslint-disable-next-line react/react-in-jsx-scope
        element: <Camera />,
    },
  ],
}

export default CommonRouter
