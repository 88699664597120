import { apiGetAppToken } from '@/api/hms'
import { useAppDispatch, useAppSelector } from '@/redux/hooks'
import { SET_LOADING, SET_MESSAGE } from '@/redux/reducers/app.slice'
import { handleError } from '@/utils'
import { HMSNoiseSuppressionPlugin } from '@100mslive/hms-noise-suppression'
import {
  selectLocalPeer,
  selectLocalVideoTrackID,
  selectPeers,
  selectRoom,
  selectRoomState,
  useHMSActions,
  useHMSStore,
} from '@100mslive/react-sdk'
import { useEffect, useState } from 'react'

export default function useHMSRoom(mirrorCode: string) {
  const hmsActions = useHMSActions()
  // hms state
  const roomState = useHMSStore(selectRoomState)
  const currentRoom = useHMSStore(selectRoom)
  const peers = useHMSStore(selectPeers)
  const localPeer = useHMSStore(selectLocalPeer)
  const localVideoTrackId = useHMSStore(selectLocalVideoTrackID)
  // local state

  const [roomInfo, setRoomInfo] = useState<any>(null)
  const [appToken, setAppToken] = useState<string>('')

  const { currentUser } = useAppSelector(state => state.auth)
  const dispatch = useAppDispatch()
  console.log(`Get token join room`, appToken)
  const durationInMs = 80
  const noiseSuppressionPlugin = new HMSNoiseSuppressionPlugin(durationInMs)

  const getAppToken = async () => {
    try {
      const params = {
        room_id: roomInfo.room_id,
        type: roomInfo.type,
        id: currentUser.id,
        passcode: roomInfo.passcode,
        mirror_code: mirrorCode,
      }
      dispatch(SET_LOADING(true))
      const { data } = await apiGetAppToken(params)
      setAppToken(data.token)
    } catch (error) {
      dispatch(SET_MESSAGE(handleError(error)))
    } finally {
      dispatch(SET_LOADING(false))
    }
  }
  // create new room
  const roomType = 1
  useEffect(() => {
    if (!currentUser.id) return

    if (roomType) {
      setRoomInfo(currentUser.room)
    }
  }, [roomType])
  // Noise suppression
  useEffect(() => {
    noiseSuppressionPlugin.init()
    try {
      noiseSuppressionPlugin.setEnabled(true)
      console.log('tran dinh sang') // true/false
    } catch (error) {
      dispatch(SET_MESSAGE(handleError(error)))
    }
  }, [roomInfo])

  useEffect(() => {
    // get app token

    if (roomInfo) {
      getAppToken()
    }
  }, [roomInfo])

  useEffect(() => {
    if (appToken) {
      const config = {
        userName: `mentor_${currentUser.username}`,
        authToken: appToken,
        settings: {
          isAudioMuted: true,
          isVideoMuted: false,
        },
      }
      hmsActions.join(config)
    }
  }, [appToken])

  useEffect(
    () => () => {
      hmsActions.leave()
    },
    []
  )

  return {
    roomState,
    currentRoom,
    currentUser,
    peers,
    localPeer,
    localVideoTrackId,
  }
}
