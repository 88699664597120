import {
  JobManagementState,
  JobManagementListBookingResponse,
  JobManagementListServiceResponse,
  IBookingDetail,
  IParticipant,
  Service,
  JobManagementParams,
  JobManagementFriendRequestResponse,
  JobManagementRequestParams,
  BoxSettingServiceDetailParamsRequest,
  BoxSettingServiceDetail,
  BoxSettingService,
  Group2,
  Group1,
  BoxSettingServiceDetailParams,
  JobManagementServiceListParams,
} from '@/interfaces'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

const initialState: JobManagementState = {
  listService: [],
  listBooking: [],
  listServiceDetail: {
    id: undefined,
    mentor_id: undefined,
    name: '',
    type: '',
    price: 0,
    group_limit: undefined,
    time: '',
    training_type: '',
    description: '',
    deleted_at: '',
    created_at: '',
    updated_at: '',
    banner: '',
    service_box: [],
    subscription_flg: 0 || null,
    totalUseFromNow: 0,
  },
  listFriendRequest: [],
  metaListService: {
    total: 0,
    per_page: 0,
    current_page: 0,
    last_page: 0,
    first_page: 0,
    first_page_url: '',
    last_page_url: '',
    next_page_url: '',
    previous_page_url: null,
  },
  metaListBooking: {
    total: 0,
    per_page: 0,
    current_page: 0,
    last_page: 0,
    first_page: 0,
    first_page_url: '',
    last_page_url: '',
    next_page_url: '',
    previous_page_url: null,
  },
  metaFriendRequest: {
    total: 0,
    per_page: 0,
    current_page: 0,
    last_page: 0,
    first_page: 0,
    first_page_url: '',
    last_page_url: '',
    next_page_url: '',
    previous_page_url: null,
  },
  totalAmount: 0,

  bookingDetail: {
    id: undefined,
    name: '',
    category: '',
    type: 0,
    duration: 0,
    start_time: '',
    use_status: 0,
    shop_id: 0,
    service_box_id: 0,
    user_id: 0,
    mirror_codes: [],
    room_id: '',
    passcode: '',
  },
  participantList: [],
  serviceDetail: {
    serviceName: '',
    category: '',
    form: '',
    fee: '',
    serviceTime: '',
    group_1: '',
    group_2: '',
    description: '',
    banner: '',
  },
  comboService: [],

  boxSettingServiceDetail: {
    name: '',
    training_type: 0,
    type: 0,
    time: 0,
  },
  group1: [],
  group2: [],
  boxSettingEdit: {
    id: 0,
    nick_name: '',
    group_box_name_1: '',
    group_box_name_2: '',
    time: 0,
    area: '',
    address: '',
    serviceBoxes: [],
    otherServiceBoxes: [],
    boxes: [],
    hours: '',
    box_status: 0,
    packs: [],
    is_dropdown: 0,
    subscription_flg: 0,
    pack_items: [],
    isBooked: false
  },
}

export const jobManagementSlice = createSlice({
  name: 'jobManagement',
  initialState,
  reducers: {
    GET_AMOUNT: () => {},
    SET_AMOUNT: (state, action: PayloadAction<number>) => {
      state.totalAmount = action.payload
    },
    FETCH_LIST_BOOKING: (_, action: PayloadAction<JobManagementParams>) => {},
    SET_LIST_BOOKING: (
      state,
      action: PayloadAction<JobManagementListBookingResponse>
    ) => {
      const { data, meta } = action.payload
      state.listBooking = data.map(item => ({ ...item, key: item.id }))
      state.metaListBooking = meta
    },
    FETCH_LIST_SERVICE: (
      _,
      action: PayloadAction<JobManagementServiceListParams>
    ) => {},
    SET_LIST_SERVICE: (
      state,
      action: PayloadAction<JobManagementListServiceResponse>
    ) => {
      const { data, meta } = action.payload
      state.listService = data.map(item => ({ ...item, key: item.id }))
      state.metaListService = meta
    },

    FETCH_BOOKING_DETAIL: (_, action: PayloadAction<string>) => {},
    RESET_BOOKING_DETAIL: state => {
      state.bookingDetail = {
        id: undefined,
        name: '',
        category: '',
        type: 0,
        duration: 0,
        start_time: '',
        use_status: 0,
        shop_id: 0,
        service_box_id: 0,
        user_id: 0,
        mirror_codes: [],
        room_id: '',
        passcode: '',
      }
    },
    SET_BOOKING_DETAIL: (state, action: PayloadAction<IBookingDetail>) => {
      state.bookingDetail = action.payload
    },

    FETCH_PARTICIPANT_LIST: (_, action: PayloadAction<string>) => {},
    SET_PARTICIPANT_LIST: (state, action: PayloadAction<IParticipant[]>) => {
      state.participantList = action.payload
    },
    FETCH_LIST_SERVICE_DETAIL: (
      _,
      action: PayloadAction<string | undefined>
    ) => {},
    SET_LIST_SERVICE_DETAIL: (state, action: PayloadAction<Service>) => {
      const service = action.payload
      state.listServiceDetail = service
    },
    ADD_SERVICE: (state, action: PayloadAction<Service>) => {},
    UPDATE_SERVICE: (state, action: PayloadAction<Service>) => {},
    FETCH_LIST_FRIEND_REQUEST: (
      _,
      action: PayloadAction<JobManagementParams>
    ) => {},
    SET_LIST_FRIEND_REQUEST: (
      state,
      action: PayloadAction<JobManagementFriendRequestResponse>
    ) => {
      const { data, meta } = action.payload.friend
      const { comboService } = action.payload
      state.listFriendRequest = data.map(item => ({
        ...item,
        key: item.id,
      }))
      state.metaFriendRequest = meta
      state.comboService = comboService
    },
    APPROVE_LIST_FRIEND_REQUEST: (
      _,
      action: PayloadAction<JobManagementRequestParams>
    ) => {},
    REJECT_LIST_FRIEND_REQUEST: (
      _,
      action: PayloadAction<JobManagementRequestParams>
    ) => {},
    FETCH_BOX_SETTING_DETAIL: (
      _,
      action: PayloadAction<BoxSettingServiceDetailParamsRequest>
    ) => {},
    SET_BOX_SETTING_DETAIL: (
      state,
      action: PayloadAction<BoxSettingServiceDetail>
    ) => {
      state.boxSettingServiceDetail = action.payload
    },
    FETCH_GROUP_1: () => {},
    SET_GROUP_1: (state, action: PayloadAction<Group1>) => {
      state.group1 = action.payload.groupBox1
    },
    FETCH_GROUP_2: (state, action: PayloadAction<number[]>) => {},
    SET_GROUP_2: (state, action: PayloadAction<Group2>) => {
      state.group2 = action.payload.groupBox2
    },
    FETCH_BOX_SETTING_EDIT: (
      _,
      action: PayloadAction<BoxSettingServiceDetailParamsRequest>
    ) => {},
    SET_BOX_SETTING_EDIT: (state, action: PayloadAction<BoxSettingService>) => {
      state.boxSettingEdit = action.payload
      state.boxSettingEdit.serviceBoxes = action.payload.serviceBoxes.map(
        (time: any) => ({
          ...time,
          time_id: time.id,
        })
      )
    },
    SET_BOX_EDIT: (
      state,
      action: PayloadAction<{
        box_id: number
        params: BoxSettingServiceDetailParams
      }>
    ) => {},
    RESET_BOX_SETTING_DETAIL: state => {
      state.boxSettingServiceDetail = {
        name: '',
        training_type: 0,
        type: 0,
        time: 0,
      }
    },
    EDIT_SERVICE_TIME: (state, action: PayloadAction<any>) => {
      state.boxSettingEdit.serviceBoxes = action.payload
    },
    ADD_SERVICE_TIME: (state, action: PayloadAction<any>) => {
      state.boxSettingEdit.serviceBoxes.push(action.payload)
    },
    HANDLE_SERVICE_TIME: (state, action: PayloadAction<any>) => {
      const index = state.boxSettingEdit.serviceBoxes.findIndex(
        item => item.id === action.payload.id
      )
      if (index > -1) {
        state.boxSettingEdit.serviceBoxes[index].date_time =
          action.payload.date_time
      }
    },
  },
})

// Actions
export const {
  GET_AMOUNT,
  SET_AMOUNT,
  FETCH_LIST_BOOKING,
  SET_LIST_BOOKING,
  FETCH_LIST_SERVICE,
  SET_LIST_SERVICE,
  FETCH_BOOKING_DETAIL,
  RESET_BOOKING_DETAIL,
  SET_BOOKING_DETAIL,
  FETCH_PARTICIPANT_LIST,
  SET_PARTICIPANT_LIST,
  FETCH_LIST_SERVICE_DETAIL,
  SET_LIST_SERVICE_DETAIL,
  FETCH_LIST_FRIEND_REQUEST,
  SET_LIST_FRIEND_REQUEST,
  APPROVE_LIST_FRIEND_REQUEST,
  REJECT_LIST_FRIEND_REQUEST,
  ADD_SERVICE,
  UPDATE_SERVICE,
  SET_BOX_SETTING_DETAIL,
  FETCH_BOX_SETTING_DETAIL,
  FETCH_GROUP_1,
  SET_GROUP_1,
  FETCH_GROUP_2,
  SET_GROUP_2,
  FETCH_BOX_SETTING_EDIT,
  SET_BOX_SETTING_EDIT,
  SET_BOX_EDIT,
  RESET_BOX_SETTING_DETAIL,
  EDIT_SERVICE_TIME,
  ADD_SERVICE_TIME,
  HANDLE_SERVICE_TIME,
} = jobManagementSlice.actions

// Reducer
const jobManagementReducer = jobManagementSlice.reducer

export default jobManagementReducer
