import React from 'react'
import {
  Grid,
  Box,
  Stack,
  Typography,
  Modal,
  IconButton,
  Divider,
} from '@mui/material'
import { Close } from '@mui/icons-material'
import { useTranslation } from 'react-i18next'
import email from '@/assets/images/email-success.png'

interface ModalProps {
  isOpen: any
  setIsOpen(open: any): void
}

function SendEmail({ isOpen, setIsOpen }: ModalProps) {
  const { t } = useTranslation()
  const handleToggleModal = () => setIsOpen({ ...isOpen, sendEmail: false })

  return (
    <Modal open={isOpen.sendEmail} onClose={handleToggleModal}>
      <Box className="modal-content" sx={{ maxWidth: '600px' }}>
        <Grid container textAlign="center">
          <Grid item xs>
            <Typography className="title" textAlign="center">
              {t('auth.send_email.title')}
            </Typography>
          </Grid>
          <Grid item>
            <IconButton onClick={handleToggleModal} className="close-button">
              <Close />
            </IconButton>
          </Grid>
        </Grid>
        <Divider />
        <Stack
          direction="column"
          justifyContent="center"
          alignItems="center"
          spacing={2}
        >
          <Box
            className="logo-image"
            component="img"
            src={email}
            alt=""
            my={2}
          />
          <Typography variant="body1">
            {t('auth.send_email.content')}
          </Typography>
        </Stack>
      </Box>
    </Modal>
  )
}

export default React.memo(SendEmail)
